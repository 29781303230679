import { FetchSnapshotParams, SaveSnapshotParams, Snapshot } from '../types';
import { request } from '../../../../helpers/request';
import { REACT_APP_API_GW_ROUTE } from '../../../../constants/api';

export const API_SNAPSHOT_SERVICE_URL = `${REACT_APP_API_GW_ROUTE}/snapshot-manual`;

export const API_SNAPSHOT_URL = `${API_SNAPSHOT_SERVICE_URL}/snapshots`;

export async function saveSnapshot(params: SaveSnapshotParams): Promise<Snapshot> {
  const formData = new FormData();
  formData.append('layer_name', params.layerName);
  formData.append('region_id', params.regionId.toString());
  formData.append('name', params.name);
  formData.append('thumbnail', params.thumbnail);

  if (params.description) {
    formData.append('description', params.description);
  }

  const res = await request.post<Snapshot>(API_SNAPSHOT_URL, formData);

  return res.data;
}

export async function fetchSnapshots(params: FetchSnapshotParams): Promise<Snapshot[]> {
  const url = new URL(API_SNAPSHOT_URL);
  url.searchParams.set('layer', params.layer);
  url.searchParams.set('region_id', params.regionId.toString());

  const res = await request.get<Snapshot[]>(url.toString());

  return res.data;
}
