import { MapCoords } from './types';

export const DEFAULT_MAX_ZOOM = 16;
export const DEFAULT_MIN_ZOOM = 1;
export const DEFAULT_ZOOM = 11;

export const DEFAULT_MAX_3D_PITCH = 60;
export const DEFAULT_PITCH = 0;

export const GLOBAL_USER_INITIAL_COORDS: MapCoords = {
  lng: 34.843311,
  lat: 32.166313,
};
