import { AppType } from '../types';
import { layersToAppType, layers } from '../../../../configs/layers';
import { selectUser } from '../../user/store/store';
import { LayerName } from '../../user/types';
import { useAppSelector } from '../../app/hooks';
import { selectSelectedAppType } from '../store/store';

interface ReturnValue {
  allowedAppTypes: AppType[];
  allowedLayers: LayerName[];
}

export const useAllowedLayers = (): ReturnValue => {
  const user = useAppSelector(selectUser);
  const selectedAppType = useAppSelector(selectSelectedAppType);

  const result: ReturnValue = {
    allowedAppTypes: [],
    allowedLayers: [],
  };

  if (!user) {
    return result;
  }

  // Here are gotten allowed for user app types
  Object.entries(layersToAppType).forEach(([appType, layerNames]) => {
    let isIntersected = false;

    for (const layer of layerNames) {
      const layerConfig = layers[layer];

      if (!layerConfig) return;

      if (layerConfig.subLayers) {
        for (const sublayer of layerConfig.subLayers) {
          if (user.layers.includes(sublayer.layerName as LayerName)) {
            isIntersected = true;
            break;
          }
        }
      }

      if (user.layers.includes(layerConfig.layerName as LayerName)) {
        isIntersected = true;
      }

      if (isIntersected) {
        result.allowedAppTypes.push(appType as AppType);
        break;
      }
    }
  });

  // Here are gotten allowed for user layers for the app type
  if (selectedAppType) {
    layersToAppType[selectedAppType].forEach((layer) => {
      const layerConfig = layers[layer];

      if (!layerConfig) return;

      if (layerConfig.subLayers) {
        for (const sublayer of layerConfig.subLayers) {
          if (user.layers.includes(sublayer.layerName as LayerName)) {
            result.allowedLayers.push(layer);
            break;
          }
        }
      }

      if (user.layers.includes(layerConfig.layerName as LayerName)) {
        result.allowedLayers.push(layer);
      }
    });
  }

  return result;
};
