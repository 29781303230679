import { DashboardMode, DataType } from '../classes/AbstractData';

// TODO: Add new dashboard config for data types here
export const dataSymbology = {
  [DataType.LATEST]: {
    mode: DashboardMode.MANY,
    dashboardTitle: 'Latest grip breakdown',
    progressBarTitles: {
      high: 'High',
      medium: 'Medium',
      low: 'Low',
    },
    colors: {
      high: '#00bf9e',
      medium: '#ffba54',
      low: '#eb3754',
    },
  },
  [DataType.BASELINE]: {
    mode: DashboardMode.MANY,
    dashboardTitle: 'Baseline grip breakdown',
    progressBarTitles: {
      high: 'High',
      medium: 'Medium',
      low: 'Low',
    },
    colors: {
      high: '#00bf9e',
      medium: '#ffba54',
      low: '#eb3754',
    },
  },
  [DataType.GROUND_TRUTH]: {
    mode: DashboardMode.SINGLE,
    dashboardTitle: 'Ground-truth coverage',
    progressBarTitles: {
      high: '',
      medium: '',
      low: '',
    },
    colors: {
      high: '#86c6d6',
      medium: '#86c6d6',
      low: '#86c6d6',
    },
  },
  [DataType.PAVEMENT]: {
    mode: DashboardMode.MANY,
    dashboardTitle: 'Pavement rating PASER',
    progressBarTitles: {
      high: 'Good',
      medium: 'Fair',
      low: 'Poor',
    },
    colors: {
      high: '#00bf9e',
      medium: '#ffba54',
      low: '#eb3754',
    },
  },
  [DataType.POTHOLE]: {
    mode: DashboardMode.MANY,
    dashboardTitle: 'Pothole severity breakdown',
    progressBarTitles: {
      high: 'High',
      medium: 'Medium',
      low: 'Low',
    },
    colors: {
      high: '#eb3754',
      medium: '#ffba54',
      low: '#00bf9e',
    },
  },
  [DataType.COVERAGE]: {
    mode: '',
    dashboardTitle: '',
    progressBarTitles: {
      high: '',
      medium: '',
      low: '',
    },
    colors: {
      high: '',
      medium: '',
      low: '',
    },
  },
  [DataType.SLIPPERINESS]: {
    mode: '',
    dashboardTitle: '',
    progressBarTitles: {
      high: '',
      medium: '',
      low: '',
    },
    colors: {
      high: '',
      medium: '',
      low: '',
    },
  },
  [DataType.SURFACE_EVENTS]: {
    mode: '',
    dashboardTitle: '',
    progressBarTitles: {
      high: '',
      medium: '',
      low: '',
    },
    colors: {
      high: '',
      medium: '',
      low: '',
    },
  },
  [DataType.LATEST_SIMPLIFIED]: {
    mode: '',
    dashboardTitle: '',
    progressBarTitles: {
      high: '',
      medium: '',
      low: '',
    },
    colors: {
      high: '',
      medium: '',
      low: '',
    },
  },
  [DataType.SLIPPERINESS_SIMPLIFIED]: {
    mode: '',
    dashboardTitle: '',
    progressBarTitles: {
      high: '',
      medium: '',
      low: '',
    },
    colors: {
      high: '',
      medium: '',
      low: '',
    },
  },
  [DataType.BASELINE_SIMPLIFIED]: {
    mode: '',
    dashboardTitle: '',
    progressBarTitles: {
      high: '',
      medium: '',
      low: '',
    },
    colors: {
      high: '',
      medium: '',
      low: '',
    },
  },
  [DataType.GROUND_TRUTH_SIMPLIFIED]: {
    mode: '',
    dashboardTitle: '',
    progressBarTitles: {
      high: '',
      medium: '',
      low: '',
    },
    colors: {
      high: '',
      medium: '',
      low: '',
    },
  },
  [DataType.PAVEMENT_SIMPLIFIED]: {
    mode: '',
    dashboardTitle: '',
    progressBarTitles: {
      high: '',
      medium: '',
      low: '',
    },
    colors: {
      high: '',
      medium: '',
      low: '',
    },
  },
  [DataType.POTHOLE_SIMPLIFIED]: {
    mode: '',
    dashboardTitle: '',
    progressBarTitles: {
      high: '',
      medium: '',
      low: '',
    },
    colors: {
      high: '',
      medium: '',
      low: '',
    },
  },
  [DataType.SURFACE_EVENTS_SIMPLIFIED]: {
    mode: '',
    dashboardTitle: '',
    progressBarTitles: {
      high: '',
      medium: '',
      low: '',
    },
    colors: {
      high: '',
      medium: '',
      low: '',
    },
  },
};

export enum FunctionalRoadClassNames {
  ARTERIAL = 'Arterial roads',
  COLLECTORS = 'Collector roads',
  LOCAL = 'Local roads',
}

export enum RoadQualityNames {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}
