import { useDispatch, useSelector } from 'react-redux';
import { selectedSnapshotIdSelector, setSelectedSnapshotId, snapshotsSelector } from './store/store';
import * as SnapshotThunks from './store/thunks';
import { setSelectedReportId } from '../selection/store/store';
import { FetchSnapshotParams, SaveSnapshotParams, Snapshot } from './types';

interface ReturnValue {
  snapshots: Snapshot[];
  snapshotsInProgress: Snapshot[];
  selectedSnapshotId: string | null;
  selectedSnapshot: Snapshot | undefined;
  selectSnapshot: (snapshotId: string | null) => void;
  saveSnapshot: (params: SaveSnapshotParams) => void;
  fetchSnapshots: (params: FetchSnapshotParams) => void;
}

export const useSnapshot = (): ReturnValue => {
  const snapshots = useSelector(snapshotsSelector);
  const selectedSnapshotId = useSelector(selectedSnapshotIdSelector);

  const snapshotsInProgress = snapshots.filter(
    (snapshot) => snapshot.tiles_status === 'in_progress' || snapshot.datafile_status === 'in_progress',
  );

  const selectedSnapshot = snapshots.find((snapshot) => snapshot.report_id === selectedSnapshotId);

  const dispatch = useDispatch();

  const selectSnapshot = (snapshotId: string | null): void => {
    dispatch(setSelectedSnapshotId(snapshotId));
    dispatch(setSelectedReportId(snapshotId));
  };

  const saveSnapshot = (params: SaveSnapshotParams): void => {
    dispatch(SnapshotThunks.saveSnapshot(params));
  };

  const fetchSnapshots = (params: FetchSnapshotParams): void => {
    dispatch(SnapshotThunks.fetchSnapshots(params));
  };

  return {
    snapshots,
    snapshotsInProgress,
    selectedSnapshotId,
    selectedSnapshot,
    selectSnapshot,
    saveSnapshot,
    fetchSnapshots,
  };
};
