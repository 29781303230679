import { createAsyncThunk } from '@reduxjs/toolkit';
import * as UserApi from '../api/api';
import { LayerName, SaveCustomPolygonData, User } from '../types';
import { Units } from '../../map/types';

export const fetchUser = createAsyncThunk('user/fetchUser', async (): Promise<User> => {
  const userPart = await UserApi.fetchUser();
  const accountPart = await UserApi.fetchAccount();

  return {
    userId: userPart.id,
    accountId: userPart.account_id,
    name: userPart.name,
    title: accountPart.title,
    username: userPart.username,
    logo: accountPart.logo,
    units: accountPart.units as Units,
    regions: accountPart.regions,
    custom_regions: userPart.custom_regions,
    layers: userPart.layers as LayerName[],
    streams: userPart.streams,
    isGlobal: accountPart.is_global,
  };
});

export const fetchPolygons = createAsyncThunk('user/fetchPolygons', async (polygonIds: number[]) => {
  const fetches = polygonIds.map((id) => UserApi.fetchPolygonById(id));

  return await Promise.all(fetches);
});

export const saveCustomPolygon = createAsyncThunk('user/saveCustomPolygon', async (data: SaveCustomPolygonData) => {
  return await UserApi.saveCustomPolygon(data);
});

export const fetchCustomPolygons = createAsyncThunk('user/fetchCustomPolygons', async () => {
  return await UserApi.fetchCustomPolygons();
});

export const deleteCustomPolygonById = createAsyncThunk('user/deleteCustomPolygonById', async (id: number) => {
  await UserApi.deleteCustomPolygonById(id);
  return id;
});
