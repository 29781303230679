import React from 'react';
import { Area } from './Area';
import { UserPolygon } from '../../../../user/types';

interface Props {
  polygons: UserPolygon[];
  selectedPolygonId: number | null;
  togglePolygonId: (id: number) => void;
}

export const SnapshotAreas: React.FC<Props> = (props) => {
  const { polygons, selectedPolygonId, togglePolygonId } = props;

  return (
    <div className="sidebar-areas">
      <div className="sidebar-area-container">
        {polygons.map((polygon) => (
          <Area
            key={polygon.id}
            title={polygon.title}
            isSelected={polygon.id === selectedPolygonId}
            onClick={togglePolygonId.bind(this, polygon.id)}
          />
        ))}
      </div>
    </div>
  );
};
