import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip, TooltipSide, StatusBar } from 'shared';
import cn from 'classnames';
import { AppTypeToggle } from '../../../selection/components/AppTypeToggle';
import IconButton from '../../../../components/IconButton';
import ReportSelector from '../../../selection/components/ReportSelector';
import { isErrorSelector, isLoadingSelector, setIsDataReload, setIsError } from '../../../layers/store/store';
import tactile from '../../../../../assets/img/tactile.png';
import { SnapshotMenu } from '../../../selection/components/SnapshotMenu';
import './_header.scss';
import UserDropdown from '../../../user/components/UserDropdown';
import AccountLogo from '../../../user/components/AccountLogo';
import { useAppSelector } from '../../../app/hooks';
import { selectSelectedAppType, selectSelectedLayer } from '../../../selection/store/store';
import PolygonSelector from '../../../selection/components/PolygonSelector';
import ExportButton from '../../../selection/components/ExportButton';

const headerList = [
  {
    name: 'Map',
    icon: 'map',
    active: true,
    disabled: false,
  },
  {
    name: 'Reports',
    icon: 'excel',
    active: false,
    disabled: true,
  },
  {
    name: 'Planning',
    icon: 'graph',
    active: false,
    disabled: true,
  },
  {
    name: 'Settings',
    icon: 'gear',
    active: false,
    disabled: true,
  },
];

const Header: React.FC = () => {
  const [menuItems] = useState(headerList);

  const selectedAppType = useAppSelector(selectSelectedAppType);
  const selectedLayer = useAppSelector(selectSelectedLayer);
  const isError = useAppSelector(isErrorSelector);
  const isLoading = useAppSelector(isLoadingSelector);

  const dispatch = useDispatch();

  const cancelError = useCallback(() => {
    dispatch(setIsError(false));
  }, []);

  const reloadDataHandler = (): void => {
    dispatch(setIsDataReload(true));
    setTimeout(() => {
      dispatch(setIsDataReload(false));
    }, 500);
  };

  return (
    <>
      <header className="header">
        <div className="d-flex">
          <img src={tactile} alt="tactile" className="header-logo-tactile" />
        </div>
        <ul className="header-list full-height ">
          {menuItems.map((item) => {
            return (
              <li
                className={cn('header-list-item full-height', { active: item.active, disabled: item.disabled })}
                key={item.name}
              >
                <i className={`header-list-icon icon icon-${item.active ? `${item.icon} isLight` : item.icon}`} />
                <span className="header-list-text">{item.name}</span>
              </li>
            );
          })}
        </ul>
        <div className="d-flex header-center">
          <PolygonSelector />
          <ReportSelector />
          <AppTypeToggle />
          {/*<Toggler id="toggler" name="toggler" disabled={true} title="Show list view" checked={false} />*/}
        </div>
        <div className="d-flex">
          <div className="p-rel">
            <SnapshotMenu />
          </div>
          <Tooltip text="Help" side={TooltipSide.BOTTOM}>
            <IconButton
              buttonClassList="header-btn"
              iconClassList="header-btn-icon icon icon-question"
              disabled={true}
            />
          </Tooltip>
          <ExportButton />
          <Tooltip text="Notifications" side={TooltipSide.BOTTOM}>
            <IconButton buttonClassList="header-btn" iconClassList="header-btn-icon icon icon-bell" disabled={true} />
          </Tooltip>
          <UserDropdown />
        </div>
        <div className="d-flex">
          <AccountLogo />
        </div>
      </header>
      <StatusBar
        isError={isError}
        isLoading={isLoading}
        onTryAgain={reloadDataHandler}
        onClickCancel={cancelError}
        progressSpeed={1}
        dependencies={[selectedAppType, selectedLayer]}
      />
    </>
  );
};

export default Header;
