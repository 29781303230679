import React, { useEffect } from 'react';
import { Tooltip, TooltipSide } from 'shared';
import cn from 'classnames';
import { AppType } from '../../types';
import { useAllowedLayers } from '../../hooks/useAllowedLayers';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectSelectedAppType, setSelectedAppType } from '../../store/store';
import './styles.scss';

interface AppTypeOption {
  id: string;
  value: AppType;
  tooltipText: string;
  iconClass: string;
  dataTestId?: string;
}

const appTypeOptions: AppTypeOption[] = [
  {
    id: 'grips',
    value: AppType.GRIP,
    tooltipText: 'Grip',
    iconClass: 'icon-toggler-grip',
  },
  {
    id: 'rating',
    value: AppType.PAVEMENT,
    tooltipText: 'Pavement Rating',
    iconClass: 'icon-toggler-pavement',
    dataTestId: 'app-toggle-pavement',
  },
  {
    id: 'pothole',
    value: AppType.POTHOLE,
    tooltipText: 'Pothole',
    iconClass: 'icon-toggler-pothole',
  },
  {
    id: 'surface-events',
    value: AppType.SURFACE_EVENTS,
    tooltipText: 'Surface Events',
    iconClass: 'icon-toggler-surface-events',
  },
];

export const AppTypeToggle: React.FC = () => {
  const selectedAppType = useAppSelector(selectSelectedAppType);

  const { allowedAppTypes } = useAllowedLayers();

  const dispatch = useAppDispatch();

  // Auto select first app type on start up
  useEffect(() => {
    if (allowedAppTypes.length && !selectedAppType) {
      dispatch(setSelectedAppType(allowedAppTypes[0]));
    }
  }, [selectedAppType, allowedAppTypes]);

  const selectAppType = (appType: AppType): void => {
    dispatch(setSelectedAppType(appType));
  };

  return allowedAppTypes.length ? (
    <div className="stilized">
      {/* If you'll remove tooltip, wrap button on span with class .stilized-btn */}
      {appTypeOptions.map(
        (option) =>
          allowedAppTypes.includes(option.value) && (
            <Tooltip key={option.id} text={option.tooltipText} side={TooltipSide.BOTTOM} className="stilized-btn">
              <button
                className={cn('stilized-toggler', { active: selectedAppType === option.value })}
                onClick={selectAppType.bind(null, option.value)}
                data-testid={option.dataTestId}
              >
                <i className={`stilized-icon icon ${option.iconClass}`} />
              </button>
            </Tooltip>
          ),
      )}
    </div>
  ) : null;
};
