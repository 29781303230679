import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Snapshot } from '../types';
import { fetchSnapshots, saveSnapshot } from './thunks';
import { RootState } from '../../app/store';
import { setSelectedSubLayer, setSelectedLayer } from '../../selection/store/store';

interface SnapshotState {
  isLoading: boolean;
  isError: boolean;
  snapshots: Snapshot[];
  selectedSnapshotId: string | null;
}

export const initialState: SnapshotState = {
  isLoading: false,
  isError: false,
  snapshots: [],
  selectedSnapshotId: null,
};

export const snapshotSlice = createSlice({
  name: 'snapshot',
  initialState,
  reducers: {
    setSelectedSnapshotId: (state, action: PayloadAction<string | null>) => {
      state.selectedSnapshotId = action.payload;
    },
    resetStore: () => {
      return initialState;
    },
  },
  extraReducers: {
    [fetchSnapshots.pending.type]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [fetchSnapshots.fulfilled.type]: (state, action: PayloadAction<Snapshot[]>) => {
      state.isLoading = false;
      state.isError = false;
      state.snapshots = action.payload.reverse();
    },
    [fetchSnapshots.rejected.type]: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    [saveSnapshot.pending.type]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [saveSnapshot.fulfilled.type]: (state, action: PayloadAction<Snapshot>) => {
      state.snapshots = [action.payload, ...state.snapshots];
      state.isLoading = false;
      state.isError = false;
    },
    [saveSnapshot.rejected.type]: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    [setSelectedLayer.type]: (state) => {
      state.snapshots = [];
      state.selectedSnapshotId = null;
    },
    [setSelectedSubLayer.type]: (state) => {
      state.snapshots = [];
      state.selectedSnapshotId = null;
    },
  },
});

export const { setSelectedSnapshotId, resetStore } = snapshotSlice.actions;

export function isLoadingSelector(state: RootState): boolean {
  return state.snapshot.isLoading;
}

export function isErrorSelector(state: RootState): boolean {
  return state.snapshot.isError;
}

export function snapshotsSelector(state: RootState): Snapshot[] {
  return state.snapshot.snapshots;
}

export function selectedSnapshotIdSelector(state: RootState): string | null {
  return state.snapshot.selectedSnapshotId;
}

export default snapshotSlice.reducer;
