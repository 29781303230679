import React, { useEffect } from 'react';
import cn from 'classnames';
import { SnapshotItem } from './Snapshot';
import { LayerConfig, layers } from '../../../../../configs/layers';
import { useSnapshot } from '../../useSnapshot';
import { useAppSelector } from '../../../app/hooks';
import { selectSelectedLayer, selectSelectedSubLayer } from '../../../selection/store/store';
import { selectPolygons } from '../../../user/store/store';
import { LayerName } from '../../../user/types';
import './styles.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const layerOptions = [
  {
    title: 'Grip',
    value: 'latest',
    icon: 'icon-wheel',
  },
  {
    title: 'Slipperiness',
    value: 'slipperiness',
    icon: 'icon-slippery',
  },
];

export const SnapshotLoad: React.FC<Props> = ({ isOpen, onClose }: Props) => {
  const { snapshots, selectSnapshot, fetchSnapshots } = useSnapshot();

  const selectedLayer = useAppSelector(selectSelectedLayer);
  const selectedSubLayer = useAppSelector(selectSelectedSubLayer);
  const polygons = useAppSelector(selectPolygons);

  const getSelectedLayerConfig = (selectedLayer: LayerName, selectedSubLayer: string | null): LayerConfig => {
    let layerConfig = layers[selectedLayer] as LayerConfig;

    if (selectedSubLayer && layerConfig.subLayers) {
      layerConfig = layerConfig.subLayers.find((subLayer) => subLayer.layerName === selectedSubLayer) as LayerConfig;
    }

    return layerConfig;
  };

  useEffect(() => {
    if (!selectedLayer || !polygons.length) {
      return;
    }

    const layerConfig = getSelectedLayerConfig(selectedLayer, selectedSubLayer);

    if (!layerConfig.hasSnapshots) {
      return;
    }

    fetchSnapshots({
      layer: layerConfig.layerName,
      regionId: polygons[0].id,
    });
  }, [selectedLayer, selectedSubLayer, polygons]);

  const onSelectSnapshot = (snapshotId: string) => {
    selectSnapshot(snapshotId);
    onClose();
  };

  return (
    <>
      {isOpen && (
        <div className="sidebar">
          <button className="sidebar-close" onClick={onClose}>
            <i className="sidebar-icon icon icon-cancel" />
          </button>
          <div className="sidebar-container">
            <div className="sidebar-header">
              <h2 className="sidebar-title">Load Snapshot</h2>
            </div>
            <ul className="sidebar-tab-list">
              {layerOptions.map((option) => (
                <li
                  className={cn('sidebar-tab', {
                    active: selectedLayer === option.value || selectedSubLayer === option.value,
                  })}
                  key={option.value}
                >
                  <i className="sidebar-tab-icon icon icon-wheel isLight" />
                  {option.title}
                </li>
              ))}
            </ul>
          </div>
          <div className="sidebar-cards">
            {snapshots.map((snapshot) => (
              <SnapshotItem
                key={snapshot.report_id}
                snapshot={snapshot}
                onSelect={onSelectSnapshot.bind(this, snapshot.report_id)}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};
