import React from 'react';
import defaultLogo from '../../../../../assets/img/tactile.png';
import { useAppSelector } from '../../../app/hooks';
import { selectUser } from '../../store/store';

const AccountLogo: React.FC = () => {
  const user = useAppSelector(selectUser);

  const logoUrl = user?.logo || defaultLogo;

  return <img src={logoUrl} alt="logo" className="header-logo-img" data-testid="account-logo" />;
};

export default AccountLogo;
