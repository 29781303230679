import React from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { DashboardState } from '../index';
import { DashboardMode } from '../../../../../../classes/AbstractData';
import { FormattedUnits } from '../../../../../components/FormattedUnits';

interface Props {
  barsState: DashboardState;
}

const PieGraphs: React.FC<Props> = (props: Props) => {
  const { barsState } = props;

  return (
    <div className="dashboard-progress" data-testid="progress-bars">
      <div className="dashboard-progress-circle">
        <CircularProgressbar
          value={barsState.high.value}
          text={`${barsState.high.value}%`}
          className="progressbar"
          strokeWidth={12}
          styles={buildStyles({
            textColor: '#ffffff',
            pathColor: barsState.highColor,
            trailColor: '#181818',
            strokeLinecap: 'butt',
          })}
        />
        <span className="dashboard-line-size">
          <FormattedUnits value={barsState.high.length} showUnit round />
        </span>
        <span className="dashboard-progress-annotation">{barsState.high.title}</span>
      </div>
      {barsState.mode === DashboardMode.MANY && (
        <>
          <div className="dashboard-progress-circle">
            <CircularProgressbar
              value={barsState.medium.value}
              text={`${barsState.medium.value}%`}
              className="progressbar"
              strokeWidth={12}
              styles={buildStyles({
                textColor: '#ffffff',
                pathColor: barsState.mediumColor,
                trailColor: '#181818',
                strokeLinecap: 'butt',
              })}
            />
            <span className="dashboard-line-size">
              <FormattedUnits value={barsState.medium.length} showUnit round />
            </span>
            <span className="dashboard-progress-annotation">{barsState.medium.title}</span>
          </div>
          <div className="dashboard-progress-circle">
            <CircularProgressbar
              value={barsState.low.value}
              text={`${barsState.low.value}%`}
              className="progressbar"
              strokeWidth={12}
              styles={buildStyles({
                textColor: '#ffffff',
                pathColor: barsState.lowColor,
                trailColor: '#181818',
                strokeLinecap: 'butt',
              })}
            />
            <span className="dashboard-line-size">
              <FormattedUnits value={barsState.low.length} showUnit round />
            </span>
            <span className="dashboard-progress-annotation">{barsState.low.title}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default PieGraphs;
