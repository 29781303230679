import React, { MouseEvent } from 'react';
import { ElementEditMenu } from 'shared';
import { UserPolygon } from '../../../../user/types';

interface Props {
  polygon: UserPolygon;
  selectedPolygonIds: number[];
  isExpanded?: boolean;
  onClickSelect: (polygonId: number) => void;
  onClickExpand: (id: number) => void;
  disabled?: boolean;
  isDeletable?: boolean;
  onClickDelete?: (id: number) => void;
}

export const PolygonListSection: React.FC<Props> = (props: Props) => {
  const {
    polygon,
    selectedPolygonIds,
    isExpanded = false,
    onClickSelect,
    onClickExpand,
    disabled,
    isDeletable = false,
    onClickDelete,
  } = props;

  const deletePolygonHandler = (e: MouseEvent) => {
    e.stopPropagation();

    if (onClickDelete) {
      onClickDelete(polygon.id);
    }
  };

  const isPolygonChildrenSelected = (polygon: UserPolygon) => {
    return !!polygon.children && !!polygon.children.filter((polygon) => selectedPolygonIds.includes(polygon.id)).length;
  };

  return (
    <>
      {polygon.children?.length ? (
        <>
          <div className="d-flex a-center">
            <button
              className={`dropdown-list-btn ${isExpanded ? 'isActive' : ''}`}
              onClick={onClickExpand.bind(this, polygon.id)}
              {...(disabled ? { disabled: false } : '')}
            >
              +
            </button>
            <span
              className="dropdown-list-title"
              onClick={() => polygon.title !== 'Custom' && onClickSelect(polygon.id)}
            >
              <input
                type="checkbox"
                id="carmel"
                className={`dropdown-select-input hidden ${
                  !selectedPolygonIds.includes(polygon.id) && isPolygonChildrenSelected(polygon) && 'indeterminate'
                }`}
                {...(disabled ? { disabled: true } : '')}
                checked={selectedPolygonIds.includes(polygon.id)}
                onChange={() => polygon.title !== 'Custom' && onClickSelect(polygon.id)}
              />
              <span className="dropdown-select-title">{polygon.title}</span>
              {disabled && <button className="dropdown-select-btn">Click here for more information</button>}
            </span>
          </div>
          <ul className="dropdown-list" style={!isExpanded ? { display: 'none' } : { display: 'block' }}>
            {polygon.children.map((childrenPolygon) => (
              <PolygonListSection
                key={childrenPolygon.id}
                polygon={childrenPolygon}
                selectedPolygonIds={
                  selectedPolygonIds.includes(polygon.id)
                    ? polygon.children.map((polygon) => polygon.id)
                    : selectedPolygonIds
                }
                isExpanded={false}
                onClickSelect={onClickSelect}
                onClickExpand={onClickExpand}
                isDeletable={isDeletable}
                onClickDelete={onClickDelete}
              />
            ))}
          </ul>
        </>
      ) : (
        <li className="dropdown-list-item" onClick={onClickSelect.bind(this, polygon.id)}>
          <input
            type="checkbox"
            id="carmel"
            className="dropdown-select-input hidden"
            onChange={onClickSelect.bind(this, polygon.id)}
            checked={selectedPolygonIds.includes(polygon.id)}
          />
          <span className="dropdown-select-title">
            <span className="dropdown-select-text">{polygon.title}</span>
            {isDeletable && polygon.id ? <ElementEditMenu onClickRemove={deletePolygonHandler} /> : ''}
          </span>
        </li>
      )}
    </>
  );
};
