import React from 'react';
import { getRoundedFloat, hashString } from '../../../../../../../helpers/common';
import { getCoordinatesFromGripDataGeometry } from '../../../../../map/helpers';

interface Props {
  geometry: string;
  values: number[];
  isSelected: boolean;
  onClickSelect: () => void;
  onClickLocate: () => void;
}

export const TableRow: React.FC<Props> = (props: Props) => {
  const { geometry, values, isSelected, onClickLocate, onClickSelect } = props;

  const hashId = (geometry: string) => {
    const coords = getCoordinatesFromGripDataGeometry(geometry);

    return Number.parseInt(hashString(`${coords[0][0]}${coords[1][1]}`));
  };

  return (
    /** For row selection add isSelected class **/
    <tr className={`list-tr ${isSelected ? 'isSelected' : ''}`}>
      <td className="list-td">
        <input type="checkbox" className="list-table-input" checked={isSelected} onChange={onClickSelect} />
        <span className="list-table-checkbox" />
      </td>
      <td className="list-td" data-testid="id">
        {hashId(geometry)}
      </td>
      {values.map((value, index) => (
        /**
         * If value is too high wrap it with <div className="list-severity high">{value}</div>
         */
        <td key={index} data-testid="value" className="list-td">
          {values && getRoundedFloat(value, 2)}
        </td>
      ))}
      <td className="list-td" data-testid="locate-icon" onClick={onClickLocate}>
        <i className="list-td-icon icon icon-target isLight" />
      </td>
    </tr>
  );
};
