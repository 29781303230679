import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectUser } from '../store/store';
import { fetchCustomPolygons, fetchPolygons } from '../store/thunks';

export const useUser = (): void => {
  const user = useAppSelector(selectUser);

  const dispatch = useAppDispatch();

  const fetchPolygonPrototypes = (polygonIds: number[]) => {
    dispatch(fetchPolygons(polygonIds));
  };

  const fetchCustomPolygonPrototypes = () => {
    dispatch(fetchCustomPolygons());
  };

  useEffect(() => {
    if (user && user.regions.length) {
      fetchPolygonPrototypes(user.regions);
      fetchCustomPolygonPrototypes();
    }
  }, [user]);
};
