import { LayerConfig } from './index';
import { getCoordinatesFromLineGeometry, getRoundedFloat, makeUrl } from '../../helpers/common';
import { API_POLYGON_DATA_URL } from '../../constants/api';
import { Data } from '../../app/modules/layers/types';
import {
  ARTERIAL_ROAD_CLASS_VALUE,
  COLLECTOR_ROAD_CLASS_VALUE,
  LOCAL_ROAD_CLASS_VALUE,
  POTHOLE_DATA_HIGH_VALUE,
  POTHOLE_DATA_LOW_VALUE,
  TREATMENT_PRIORITY_HIGH_VALUE,
  TREATMENT_PRIORITY_LOW_VALUE,
} from '../../constants/dataParms';
import { FilterType } from '../../app/modules/filter/types';
import { filterByPriority, filterByRoadClass, filterBySeverity } from '../../app/modules/filter/filters';

const CLUSTER_MAX_ZOOM = 14;
const CLUSTER_RADIUS = 20;
const CLUSTER_ICON_SIZE_STEP = 0.005;
const PIN_DEFAULT_SIZE = 0.5;
const PIN_MAX_SIZE = 1.1;

export const pothole: LayerConfig = {
  layerName: 'pothole',
  isLiveLayer: false,
  mapSource: {
    type: 'report',
    source: () => ({
      type: 'geojson',
      cluster: true,
      clusterMaxZoom: CLUSTER_MAX_ZOOM,
      clusterRadius: CLUSTER_RADIUS,
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    }),
    mapper: (data: Data[]) => ({
      type: 'FeatureCollection',
      features: data.map((item) => {
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: getCoordinatesFromLineGeometry(item.data.geometry)[0],
          },
          properties: {
            title: 'Pothole',
            error: item.data.error,
            functional_road_class: item.data.functional_road_class,
            geometry: item.data.geometry,
            priority: item.data.priority,
            quality: item.data.quality,
            severity: item.data.severity,
            timestamp: item.data.timestamp,
            coords: getCoordinatesFromLineGeometry(item.data.geometry),
          },
        };
      }),
    }),
  },
  mapLayers: [
    {
      id: 'pothole-clustered',
      type: 'symbol',
      source: 'pothole',
      filter: ['has', 'point_count'],
      layout: {
        visibility: 'visible',
        'text-field': '{point_count_abbreviated}',
        'icon-size': [
          'case',
          ['<', ['+', PIN_DEFAULT_SIZE, ['*', ['get', 'point_count'], CLUSTER_ICON_SIZE_STEP]], PIN_MAX_SIZE],
          ['+', PIN_DEFAULT_SIZE, ['*', ['get', 'point_count'], CLUSTER_ICON_SIZE_STEP]],
          PIN_MAX_SIZE,
        ],
        'text-anchor': 'center',
        'icon-allow-overlap': true,
        'icon-image': 'pinpoint_orange',
      },
    },
    {
      id: 'pothole-not-clustered',
      type: 'symbol',
      source: 'pothole',
      filter: ['!', ['has', 'point_count']],
      layout: {
        visibility: 'visible',
        'text-field': '1',
        'icon-size': PIN_DEFAULT_SIZE,
        'text-anchor': 'center',
        'icon-allow-overlap': true,
        'icon-image': [
          'case',
          ['<', ['get', 'severity'], POTHOLE_DATA_LOW_VALUE],
          'pinpoint_green',
          ['>=', ['get', 'severity'], POTHOLE_DATA_HIGH_VALUE],
          'pinpoint_red',
          ['>=', ['get', 'severity'], POTHOLE_DATA_LOW_VALUE],
          'pinpoint_yellow',
          'pinpoint_orange',
        ],
      },
    },
  ],
  statisticSource: {
    type: 'report',
    url: (regionId: string) => makeUrl(API_POLYGON_DATA_URL, { layer: 'pothole', regionId }),
  },
  symbology: {
    title: 'Pothole severity',
    iconClass: 'icon-wheel',
    tooltip: {
      icon: 'icon-tooltip-pothole',
      heading: 'Pothole',
      fieldsCreator: (data) => {
        return [
          { title: 'Severity[1-10]', value: data.severity.toString() },
          { title: 'Priority[1-10]', value: data.priority.toString() },
          { title: 'Quality[%]', value: getRoundedFloat(Number(data.quality), 2).toString() },
          { title: 'Road class', value: data.functional_road_class.toString() },
          {
            title: 'Segment start',
            value: `${getRoundedFloat(Number(data.coords[0][1]), 5)}°N,${getRoundedFloat(
              Number(data.coords[0][0]),
              5,
            )}°E`,
          },
          {
            title: 'Segment end',
            value: `${getRoundedFloat(Number(data.coords[data.coords.length - 1][1]), 5)}°N,${getRoundedFloat(
              Number(data.coords[data.coords.length - 1][0]),
              5,
            )}°E`,
          },
        ];
      },
    },
  },
  filters: {
    functionalRoadClassFilter: {
      title: 'Functional road class',
      filterName: 'functionalRoadClassFilter',
      filterableValue: 'functional_road_class',
      filterType: FilterType.BUTTONS,
      defaultState: {
        arterial: true,
        collector: true,
        local: true,
      },
      options: [
        {
          title: 'Arterial',
          name: 'arterial',
          values: [0, ARTERIAL_ROAD_CLASS_VALUE + 1],
        },
        {
          title: 'Collector',
          name: 'collector',
          values: [COLLECTOR_ROAD_CLASS_VALUE, COLLECTOR_ROAD_CLASS_VALUE + 1],
        },
        {
          title: 'Local',
          name: 'local',
          values: [LOCAL_ROAD_CLASS_VALUE, LOCAL_ROAD_CLASS_VALUE + 1],
        },
      ],
      dataFilterFunction: filterByRoadClass,
    },
    potholeSeverityFilter: {
      title: 'Pothole severity',
      filterName: 'potholeSeverityFilter',
      filterableValue: 'severity',
      filterType: FilterType.BUTTONS,
      defaultState: {
        low: true,
        medium: true,
        high: true,
      },
      options: [
        {
          title: 'High',
          name: 'high',
          values: [POTHOLE_DATA_HIGH_VALUE, 999],
        },
        {
          title: 'Medium',
          name: 'medium',
          values: [POTHOLE_DATA_LOW_VALUE, POTHOLE_DATA_HIGH_VALUE],
        },
        {
          title: 'Low',
          name: 'low',
          values: [0, POTHOLE_DATA_LOW_VALUE],
        },
      ],
      dataFilterFunction: filterBySeverity,
    },
    treatmentPriorityFilter: {
      title: 'Treatment priority',
      filterName: 'treatmentPriorityFilter',
      filterableValue: 'priority',
      filterType: FilterType.BUTTONS,
      defaultState: {
        high: true,
        medium: true,
        low: true,
      },
      options: [
        {
          title: 'High',
          name: 'high',
          values: [TREATMENT_PRIORITY_HIGH_VALUE, 999],
        },
        {
          title: 'Medium',
          name: 'medium',
          values: [TREATMENT_PRIORITY_LOW_VALUE, TREATMENT_PRIORITY_HIGH_VALUE],
        },
        {
          title: 'Low',
          name: 'low',
          values: [0, TREATMENT_PRIORITY_LOW_VALUE],
        },
      ],
      dataFilterFunction: filterByPriority,
    },
    geometryFilter: {
      title: '',
      filterName: 'geometryFilter',
      filterableValue: 'geometry',
      filterType: FilterType.GEOMETRY,
      defaultState: {
        geometryFilter: true,
      },
      isHidden: true,
    },
  },
};
