import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Polygon, Position } from '@turf/turf';
import { LoggingService, Map as MapComponent } from 'shared';
import { parse } from 'wkt';
import { ILocalStorageService } from '../../../../services/LocalStorageService';
import { container } from '../../../../../configs/inversify';
import { MapViewPort } from '../../types';
import { MapControls } from '../MapControls';
import SavePolygonModal from '../SavePolygonModal';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectIsGlobalUser, selectPolygons } from '../../../user/store/store';
import { DEFAULT_MAX_3D_PITCH, DEFAULT_MAX_ZOOM, DEFAULT_MIN_ZOOM, DEFAULT_PITCH, DEFAULT_ZOOM } from '../../constants';
import { getCenterOfPolygon, getCustomPolygonWKTString } from '../../helpers';
import pinpointOrange from '../../../../../assets/img/pin_orange.png';
import pinpointGreen from '../../../../../assets/img/pin_green.png';
import pinpointYellow from '../../../../../assets/img/pin_yellow.png';
import pinpointRed from '../../../../../assets/img/pin_red.png';
import { setIsLoading } from '../../../layers/store/store';
import './style.scss';
import UserPolygonsLayer from '../../../layers/components/UserPolygonsLayer';
import UserLayers from '../../../layers/components/UserLayers';
import { setBbox, setViewport, viewportSelector } from '../../store/store';

mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_KEY || '';

export const MAP_THEME = 'mapbox://styles/benz-tactile/ck70azee434jh1iodufvdvakx?optimize=true';
export const GLOBAL_USER_INITIAL_VIEWPORT: MapViewPort = {
  zoom: DEFAULT_ZOOM,
  pitch: DEFAULT_PITCH,
  lng: 34.843311,
  lat: 32.166313,
};

const PINPOINTS = [
  [pinpointOrange, 'pinpoint_orange'],
  [pinpointGreen, 'pinpoint_green'],
  [pinpointYellow, 'pinpoint_yellow'],
  [pinpointRed, 'pinpoint_red'],
];

const localStorageService: ILocalStorageService = container.get('LocalStorageService');

interface Props {
  onLoad(map: mapboxgl.Map): void;
}

const Map: React.FC<Props> = ({ onLoad }: Props) => {
  const [mapInstance, setMapInstance] = useState<mapboxgl.Map | null>(null);
  const [customPolygonCoords, setCustomPolygonCoords] = useState<Position[][] | null>(null);
  const [isCustomPolygonModalOpen, setIsCustomPolygonModalOpen] = useState<boolean>(false);

  const drawingControlRef = useRef<MapboxDraw | null>(null);

  const isGlobalUser = useAppSelector(selectIsGlobalUser);
  const polygons = useAppSelector(selectPolygons);
  const viewport = useAppSelector(viewportSelector);

  const dispatch = useAppDispatch();

  const canDrawCustomPolygons = !isGlobalUser;

  useEffect(() => {
    if (!mapInstance || !viewport) return;

    mapInstance.setCenter({ lng: viewport.lng, lat: viewport.lat });
  }, [viewport, mapInstance]);

  useEffect(() => {
    if (isGlobalUser) {
      dispatch(setViewport(GLOBAL_USER_INITIAL_VIEWPORT));
      return;
    }

    if (polygons.length) {
      const parsedGeometry = parse(polygons[0].polygon);
      const center = getCenterOfPolygon(parsedGeometry.coordinates[0]);

      dispatch(
        setViewport({
          zoom: DEFAULT_ZOOM,
          lng: center[0],
          lat: center[1],
          pitch: 0,
        }),
      );
    }
  }, [isGlobalUser, polygons]);

  useEffect(() => {
    if (!mapInstance) return;

    document.addEventListener('keydown', processKeyDownEvent);
    document.addEventListener('keyup', processKeyUpEvent);

    mapInstance.on('move', (e) => {
      const bbox = e.target.getBounds();
      dispatch(setBbox(bbox.toArray()));
    });

    mapInstance.on('data', (e) => {
      if (e.tile) {
        dispatch(setIsLoading(true));

        setTimeout(() => {
          dispatch(setIsLoading(false));
        }, 500);
      }
    });

    return () => {
      document.removeEventListener('keydown', processKeyDownEvent);
      document.removeEventListener('keyup', processKeyUpEvent);
    };
  }, [mapInstance]);

  const toggleCustomPolygonModalOpen = (): void => {
    setIsCustomPolygonModalOpen((prev) => !prev);
  };

  const processKeyDownEvent = (e: KeyboardEvent): void => {
    if (e.key === 'Control') {
      toggleCustomPolygonDrawing();
    }

    if (e.key === 'Enter' && drawingControlRef.current) {
      const customPolygon = drawingControlRef.current.getAll().features[0].geometry as Polygon;
      setCustomPolygonCoords(customPolygon.coordinates);
      toggleCustomPolygonModalOpen();
    }
  };

  const processKeyUpEvent = (e: KeyboardEvent): void => {
    if (e.key === 'Control') {
      toggleCustomPolygonDrawing();
    }
  };

  const onMapLoad = (map: mapboxgl.Map): void => {
    loadImagesForMap(map);
    setMapInstance(map);
    onLoad(map);
  };

  const toggleCustomPolygonDrawing = (): void => {
    if (!mapInstance) return;
    if (!canDrawCustomPolygons) return;

    if (drawingControlRef.current) {
      removePolygonDrawing();
      return;
    }

    const draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
      },
      defaultMode: 'draw_polygon',
    });

    drawingControlRef.current = draw;
    mapInstance.addControl(draw);
    mapInstance.on('draw.create', updateCustomPolygon);
    mapInstance.on('draw.delete', updateCustomPolygon);
    mapInstance.on('draw.update', updateCustomPolygon);
  };

  const updateCustomPolygon = (): void => {
    if (!drawingControlRef.current) return;

    const geometry = drawingControlRef.current.getAll().features[0].geometry as Polygon;
    const polygonString = getCustomPolygonWKTString(geometry.coordinates);
    LoggingService.log('[DEBUG]: Selected polygon: ', polygonString);
  };

  const removePolygonDrawing = (): void => {
    if (!mapInstance) return;

    if (drawingControlRef.current) {
      mapInstance.off('draw.create', updateCustomPolygon);
      mapInstance.off('draw.delete', updateCustomPolygon);
      mapInstance.off('draw.update', updateCustomPolygon);
      mapInstance.removeControl(drawingControlRef.current);
      drawingControlRef.current = null;
    }
  };

  const finishCustomPolygonCreating = (): void => {
    removePolygonDrawing();
    setIsCustomPolygonModalOpen(false);
  };

  const onClickZoom = (isIncrease: boolean): void => {
    if (!mapInstance) return;
    const currentZoom = mapInstance.getZoom();

    mapInstance.flyTo({
      center: mapInstance.getCenter(),
      zoom: isIncrease ? currentZoom + 1 : currentZoom - 1,
    });
  };

  const flyToUserPolygon = (): void => {
    if (!polygons.length || !mapInstance) return;

    const parsedGeometry = parse(polygons[0].polygon);
    const center = getCenterOfPolygon(parsedGeometry.coordinates[0]);

    mapInstance.flyTo({
      center,
      zoom: DEFAULT_ZOOM,
    });
  };

  return (
    <MapComponent
      accessToken={process.env.REACT_APP_MAP_BOX_KEY || ''}
      authToken={localStorageService.getItem('Authorization') || ''}
      height="100vh"
      width="100vw"
      style={MAP_THEME}
      zoom={viewport.zoom}
      center={{ lat: viewport.lat, lng: viewport.lng }}
      maxZoom={DEFAULT_MAX_ZOOM}
      minZoom={DEFAULT_MIN_ZOOM}
      maxPitch={DEFAULT_MAX_3D_PITCH}
      onMapLoad={onMapLoad}
    >
      <MapControls
        enableCustomPolygon={canDrawCustomPolygons}
        onClickCursor={toggleCustomPolygonDrawing}
        onClickTarget={flyToUserPolygon}
        onZoomIn={onClickZoom.bind(this, true)}
        onZoomOut={onClickZoom.bind(this, false)}
      />
      {isGlobalUser ? null : <UserPolygonsLayer map={mapInstance} />}
      <UserLayers map={mapInstance} />
      {customPolygonCoords && isCustomPolygonModalOpen ? (
        <SavePolygonModal
          polygonCoords={customPolygonCoords}
          onSave={finishCustomPolygonCreating}
          onClose={toggleCustomPolygonModalOpen}
        />
      ) : null}
    </MapComponent>
  );
};

const loadImagesForMap = (map: mapboxgl.Map) => {
  PINPOINTS.forEach(([pinpoint, pinpointName]) => {
    map.loadImage(pinpoint, (error, image) => {
      if (error || !image) throw Error('Error loading images for map');

      map.addImage(pinpointName, image);
    });
  });
};

export default Map;
