import { LayerName } from '../user/types';

export enum AppType {
  GRIP = 'grip',
  PAVEMENT = 'pavement',
  POTHOLE = 'pothole',
  SURFACE_EVENTS = 'surface-events',
}

export interface Report {
  id: string;
  report_name: string;
}

export interface SelectedReports {
  latestReport: Report | null;
  previousReport: Report | null;
  restReports: Report[];
}

export interface PolygonPrototype {
  id: number;
  children: PolygonPrototype[];
  parent_id: number | undefined;
  base_polygon_id?: number;
  isEnabled?: boolean;
  polygon: string;
  title: string;
}

export interface FetchReportsParams {
  layer: LayerName;
  polygonId: number;
}

export interface FetchReportsResponse {
  status: 'SUCCESS' | 'FAIL';
  result: Report[];
}

export type APIFileResponse = {
  file: string;
  file_name: string;
  status: 'SUCCESS' | 'FAIL';
};
