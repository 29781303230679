import React from 'react';

interface Props {
  value: number;
  color: string;
}

export const BarGraph: React.FC<Props> = (props: Props) => {
  const { value, color } = props;

  return (
    <div className={`dashboard-vertical ${value >= 3 ? 'bordered' : ''}`}>
      <span className="dashboard-vertical-bar" style={{ height: value / 3.5, backgroundColor: color }} />
      <span className="dashboard-vertical-text" style={{ color: color }}>
        {value}%
      </span>
    </div>
  );
};
