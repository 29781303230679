import React, { ChangeEvent, useEffect, useState } from 'react';
import { uniq } from 'lodash';
import { PolygonListSection } from './PolygonListSection';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { selectCustomPolygons, selectIsGlobalUser, selectPolygons } from '../../../../user/store/store';
import {
  selectSelectedPolygonIds,
  setSelectedPolygonId,
  toggleOnlyOneSelectedPolygon,
  toggleSelectedPolygon,
} from '../../../store/store';
import { UserPolygon } from '../../../../user/types';

interface Props {
  onClickDeletePolygon: (id: number) => void;
}

export const PolygonsList: React.FC<Props> = (props) => {
  const { onClickDeletePolygon } = props;

  const [searchValue, setSearchValue] = useState<string>('');
  const [expandedPolygonIds, setExpandedPolygonIds] = useState<number[]>([]);

  const polygons = useAppSelector(selectPolygons);
  const customPolygons = useAppSelector(selectCustomPolygons);
  const selectedPolygonIds = useAppSelector(selectSelectedPolygonIds);
  const isGlobalUser = useAppSelector(selectIsGlobalUser);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isGlobalUser) return;
    if (!polygons.length) return;

    dispatch(setSelectedPolygonId(polygons[0].id));
  }, [polygons]);

  const selectAll = () => {
    polygons.forEach((polygon) => {
      dispatch(toggleOnlyOneSelectedPolygon(polygon.id));
    });
  };

  const expandAll = () => {
    const polygonIds: number[] = [];

    polygons.forEach((polygon) => {
      polygonIds.push(polygon.id);
    });

    setExpandedPolygonIds((prev) => (prev.length === polygonIds.length ? [] : polygonIds));
  };

  const expandPolygon = (id: number) => {
    setExpandedPolygonIds((prev) => [...prev, id]);
  };

  const constrictPolygon = (id: number) => {
    setExpandedPolygonIds((prev) => prev.filter((polygonId) => polygonId !== id));
  };

  const toggleExpand = (id: number) => {
    if (expandedPolygonIds.includes(id)) {
      constrictPolygon(id);
    } else {
      expandPolygon(id);
    }
  };

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const title = e.target.value.trim();
    const ids: number[] = [];

    title.length &&
      polygons.forEach((polygon) => {
        polygon.children.forEach((childrenPolygon) => {
          if (
            childrenPolygon.title.toLowerCase().includes(title.toLowerCase()) ||
            polygon.title.toLowerCase().includes(title.toLowerCase())
          ) {
            ids.push(polygon.id);
          }
        });
      });

    title.length &&
      customPolygons.forEach((polygon) => {
        if (polygon.title.toLowerCase().includes(title.toLowerCase())) {
          ids.push(999999);
        }
      });

    setExpandedPolygonIds(uniq(ids));

    setSearchValue(e.target.value);
  };

  const selectPolygon = (polygonId: number) => {
    const allPolygons = [...polygons, ...customPolygons];

    if (!!allPolygons.find((polygon) => polygon.id === polygonId)) {
      dispatch(toggleOnlyOneSelectedPolygon(polygonId));
      return;
    }

    if (isCustomSelected() && !customPolygons.find((polygon) => polygon.id === polygonId)) {
      dispatch(toggleOnlyOneSelectedPolygon(polygonId));
      return;
    }

    if (isParentSelected() && !polygons.find((polygon) => polygon.id === polygonId)) {
      polygons[0].children.forEach((childrenPolygon) => {
        if (childrenPolygon.id !== polygonId) {
          dispatch(toggleSelectedPolygon(childrenPolygon.id));
        }
      });
      dispatch(toggleSelectedPolygon(polygons[0].id));
      return;
    }

    dispatch(toggleSelectedPolygon(polygonId));
  };

  const isCustomSelected = () => {
    let isSelected = false;
    selectedPolygonIds.forEach((selectedId) => {
      isSelected = !!customPolygons.find((polygon) => polygon.id === selectedId);
    });
    return isSelected;
  };

  const isParentSelected = () => {
    let isSelected = false;
    selectedPolygonIds.forEach((selectedId) => {
      isSelected = !!polygons.find((polygon) => polygon.id === selectedId);
    });
    return isSelected;
  };

  const isAllChildrenOrParentSelected = (polygons: UserPolygon[]): boolean => {
    let isSelected = true;

    polygons.forEach((polygon) => {
      if (!selectedPolygonIds.includes(polygon.id)) {
        isSelected = false;
      }
    });

    return isSelected;
  };

  return (
    <div>
      <div className="dropdown-settings-header">
        <label htmlFor="areaSearch" className="dropdown-search">
          <input
            type="text"
            id="areaSearch"
            className="dropdown-search-input"
            placeholder={polygons[0] ? `Search areas in ${polygons[0].title}...` : ''}
            value={searchValue}
            onChange={onSearchChange}
          />
          <i className="dropdown-search-icon icon icon-search isLight" />
        </label>
        <div className="d-flex a-center j-between">
          <label htmlFor="selectAll" className="dropdown-select">
            <input
              type="checkbox"
              id="selectAll"
              className="dropdown-select-input hidden"
              onChange={selectAll}
              checked={isAllChildrenOrParentSelected(polygons)}
            />
            <span className="dropdown-select-title">Select all</span>
          </label>
          <button className="dropdown-notif" onClick={expandAll}>
            +/- Expand/Collapse all
          </button>
        </div>
      </div>
      <div className="dropdown-settings">
        <div className="dropdown-settings-body">
          {polygons.map((polygon) => {
            return (
              <div className={`dropdown-list-container `} key={polygon.id}>
                <PolygonListSection
                  key={polygon.id}
                  polygon={polygon}
                  selectedPolygonIds={selectedPolygonIds}
                  isExpanded={expandedPolygonIds.includes(polygon.id)}
                  onClickSelect={selectPolygon}
                  onClickExpand={toggleExpand}
                />
              </div>
            );
          })}
          {polygons.length && customPolygons.length && (
            <div className="dropdown-list-container">
              <PolygonListSection
                polygon={{
                  id: 999999,
                  title: 'Custom',
                  children: customPolygons as UserPolygon[],
                  parent_id: polygons[0].id,
                  polygon: '',
                }}
                isExpanded={expandedPolygonIds.includes(999999)}
                selectedPolygonIds={selectedPolygonIds}
                isDeletable={true}
                onClickSelect={selectPolygon}
                onClickExpand={toggleExpand}
                onClickDelete={onClickDeletePolygon}
              />
            </div>
          )}
          {/*TODO: is not available now*/}
          {/*<h2 className="dropdown-section-title">Uncoverd areas (2):</h2>*/}
          {/*<DropdownListSection*/}
          {/*  polygon={{ id: 1, parent_id: undefined, polygon: '', children: [] as Polygon[], title: 'Bay area' }}*/}
          {/*  disabled={true}*/}
          {/*  isSelected={false}*/}
          {/*  expandedPolygons={[]}*/}
          {/*  isExpanded*/}
          {/*  toggleExpandPolygon={() => undefined}*/}
          {/*  togglePolygon={() => undefined}*/}
          {/*/>*/}
        </div>
      </div>
    </div>
  );
};
