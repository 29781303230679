import { LayerConfig } from '../index';
import { getTimeStringFromMs, makeUrl } from '../../../helpers/common';
import { API_TILES } from '../../../constants/api';
import { DEFAULT_MAX_ZOOM, DEFAULT_MIN_ZOOM } from '../../../constants/map';

const ONE_WEEK_TIME_MS = 604800000;
const ONE_DAY_TIME_MS = 86400000;

const MAX_ZOOM_SUBSEGMENTS = 17;
const MIN_ZOOM_SUBSEGMENTS = 8;

const MAX_ZOOM_SUBDIVISIONS = 7;
const MIN_ZOOM_SUBDIVISIONS = 1;

export const surfaceEventsSimplified: LayerConfig = {
  layerName: 'surface-events-simplified',
  isLiveLayer: true,
  hasSnapshots: false,
  mapSource: {
    type: 'tiles',
    source: () => ({
      type: 'vector',
      tiles: [makeUrl(API_TILES, { layer: 'latest-surface-events' })],
      maxzoom: DEFAULT_MAX_ZOOM,
      minzoom: DEFAULT_MIN_ZOOM,
    }),
  },
  mapLayers: [
    {
      id: 'surface-events-simplified-subsegments',
      source: 'surface-events-simplified',
      'source-layer': 'latest-surface-events-subsegments',
      type: 'line',
      maxzoom: MAX_ZOOM_SUBSEGMENTS,
      minzoom: MIN_ZOOM_SUBSEGMENTS,
      paint: {
        'line-width': [
          'let',
          'width',
          [
            'case',
            ['<', ['get', 'roadClass'], 4],
            7,
            ['==', ['get', 'roadClass'], 4],
            4,
            ['==', ['get', 'roadClass'], 5],
            1,
            1,
          ],
          [
            'step',
            ['zoom'],
            1,
            DEFAULT_MIN_ZOOM,
            ['case', ['>', ['-', ['var', 'width'], 5], 0], ['-', ['var', 'width'], 5], 1],
            DEFAULT_MAX_ZOOM - 4,
            ['case', ['>', ['-', ['var', 'width'], 3], 0], ['-', ['var', 'width'], 3], 1],
            DEFAULT_MAX_ZOOM - 3,
            ['+', ['var', 'width'], 0],
            DEFAULT_MAX_ZOOM - 1,
            ['+', ['var', 'width'], 4],
          ],
        ],
        'line-color': [
          'let',
          'creationTime',
          ['get', 'lastSampleTimestampUtcMilliseconds'],
          [
            'interpolate',
            ['linear'],
            ['var', 'creationTime'],
            Date.now() - ONE_WEEK_TIME_MS,
            '#eb3754',
            Date.now() - ONE_DAY_TIME_MS,
            '#ffba54',
            Date.now(),
            '#00bf9e',
          ],
        ],
      },
    },
    {
      id: 'surface-events-simplified-subdivisions',
      type: 'fill',
      source: 'surface-events-simplified',
      'source-layer': 'latest-surface-events-subdivisions',
      maxzoom: MAX_ZOOM_SUBDIVISIONS,
      minzoom: MIN_ZOOM_SUBDIVISIONS,
      layout: {
        visibility: 'visible',
      },
      paint: {
        'fill-color': '#ddeaff',
        'fill-outline-color': 'rgba(0,0,0,0)',
        'fill-opacity': 0.5,
      },
    },
  ],
  symbology: {
    title: 'Surface events',
    iconClass: 'icon-wheel',
    tooltip: {
      icon: 'icon-tooltip-pothole',
      heading: 'Surface events',
      fieldsCreator: (data) => {
        const stats = JSON.parse(data.stats) as Record<
          string,
          {
            averageMagnitude: number;
            magnitudeStandardDeviation: number;
            samplesCount: number;
          }
        >;

        const fields: { title: string; value: string }[] = [];

        const statsLength = Object.keys(stats).length;
        Object.entries(stats).forEach(([statKey, statValue]) => {
          if (statKey === 'total' && statsLength === 2) return;

          fields.push({
            title: `sample count (${statKey.toLowerCase()})`,
            value: statValue.samplesCount.toString(),
          });
          fields.push({
            title: `avg. magnitude (${statKey.toLowerCase()})`,
            value: Math.floor(statValue.averageMagnitude).toString(),
          });
          fields.push({
            title: `magnitude std (${statKey.toLowerCase()})`,
            value: Math.floor(statValue.magnitudeStandardDeviation).toString(),
          });
        });

        fields.push({
          title: `age`,
          value: getTimeStringFromMs(Date.now() - Number(data.lastSampleTimestampUtcMilliseconds)),
        });

        fields.push({
          title: `period`,
          value: getTimeStringFromMs(Number(data.dataPeriodUtcMilliseconds)),
        });

        return fields;
      },
    },
  },
};
