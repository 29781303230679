import React from 'react';
import cn from 'classnames';
import { UIFilterOption } from '../../../types';
import './_filter.scss';

interface Props {
  title: string;
  filterName: string;
  filterOptions: UIFilterOption[];
  onClick: (filterName: string, filterOption: string, value: boolean) => void;
}

export const ButtonsFilter: React.FC<Props> = (props: Props) => {
  const { title, filterName, filterOptions, onClick } = props;

  return (
    <div className="filter">
      <p className="filter-title">
        {title}
        {/*<button data-testid="resetButton" title="Clear" onClick={resetFilters} className="filter-clear-btn">*/}
        {/*  <i className="filter-clear-icon icon icon-trash isLight" />*/}
        {/*</button>*/}
      </p>
      <div className="filter-btn-container">
        {filterOptions.map((option) => (
          <button
            key={option.name}
            data-testid="filterButton"
            role="button"
            className={cn('filter-btn btn', { 'btn-selected': option.isSelected, 'btn-primary': !option.isSelected })}
            onClick={onClick.bind(this, filterName, option.name, !option.isSelected)}
          >
            {option.title}
          </button>
        ))}
      </div>
    </div>
  );
};
