// TODO: may be part of config

const { REACT_APP_API_GW_ROUTE, REACT_APP_SOCKET_ROUTE } = process.env;

export { REACT_APP_API_GW_ROUTE, REACT_APP_SOCKET_ROUTE };

const TILE_SERVICE_PREFIX = '/tile';
const POLYGON_DATA_SERVICE_PREFIX = '/polygon-data';

export const WEB_SOCKET_URL = `${REACT_APP_SOCKET_ROUTE}/maps_ws`;

export const API_TILES = `${REACT_APP_API_GW_ROUTE}${TILE_SERVICE_PREFIX}/:layer/{z}/{x}/{y}`;
export const API_POLYGON_DATA_URL = `${REACT_APP_API_GW_ROUTE}${POLYGON_DATA_SERVICE_PREFIX}/:layer/:regionId`;
