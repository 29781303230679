import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Data, FetchReportDataResponse, PaginatedData } from '../types';
import { RootState } from '../../app/store';
import { DataType } from '../../../../classes/AbstractData';
import { fetchData, fetchReportData, fetchReportDataPage, getDataPage } from './thunks';
import { Report } from '../../selection/types';
import { setSelectedAppType, setSelectedLayer, setSelectedReportId } from '../../selection/store/store';

export type DataToPolygonId = Record<number, Data[]>;

interface DataState {
  isLoading: boolean;
  isError: boolean;
  errors: string[];
  // TODO: consider change not to have comparingData
  data: Data[];
  reports: Report[];
  dataToPolygonId: [string, Data][];
  filteredData: Data[];
  comparingData: Data[]; // For ground truth comparing
  paginatedData: Data[];
  filteredPaginatedData: Data[];
  pagesCount: number | null;
  isDataReload: boolean;
}

export const initialState: DataState = {
  isLoading: false,
  isError: false,
  errors: [],
  data: [],
  reports: [],
  dataToPolygonId: [],
  filteredData: [],
  comparingData: [],
  paginatedData: [],
  filteredPaginatedData: [],
  pagesCount: null,
  isDataReload: false,
};

export const dataSlice = createSlice({
  name: 'layers',
  initialState,
  reducers: {
    setIsError: (state, action: PayloadAction<boolean>) => {
      state.isError = action.payload;
    },
    setIsDataReload: (state, action: PayloadAction<boolean>) => {
      state.isDataReload = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setErrors: (state, action: PayloadAction<string[]>) => {
      state.errors = action.payload;
    },
    setData: (state, action: PayloadAction<Data[]>) => {
      state.data = action.payload;
    },
    setFilteredData: (state, action: PayloadAction<Data[]>) => {
      state.filteredData = action.payload;
    },
    addToFilteredData: (state, action: PayloadAction<Data[]>) => {
      state.filteredData = [...state.filteredData, ...action.payload];
    },
    clearPolygonData: (state) => {
      state.data = [];
      state.filteredData = [];
    },
    filterPaginatedData: (state) => {
      state.filteredPaginatedData = state.paginatedData.filter((dataItem) =>
        state.filteredData.find((filteredItem) => filteredItem.id === dataItem.id),
      );
    },
    clearPaginatedData: (state) => {
      state.paginatedData = [];
      state.filteredPaginatedData = [];
      state.pagesCount = null;
    },
    resetStore: () => {
      return initialState;
    },
  },
  extraReducers: {
    [fetchData.pending.type]: (state) => {
      state.isError = false;
      state.isLoading = true;
    },
    [fetchData.fulfilled.type]: (
      state,
      action: PayloadAction<{ dataType: DataType; data: Data[]; comparingData: Data[] }>,
    ) => {
      state.isError = false;
      state.isLoading = false;

      const { dataType } = action.payload;

      if (dataType === DataType.LATEST) {
        state.comparingData = action.payload.data;
      }

      if (dataType === DataType.GROUND_TRUTH) {
        state.comparingData = action.payload.comparingData;
      }

      state.data = action.payload.data;
    },
    [fetchData.rejected.type]: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    [fetchReportData.pending.type]: (state) => {
      state.isError = false;
      state.isLoading = true;
      state.data = [];
      state.filteredData = [];
      state.paginatedData = [];
    },
    [fetchReportData.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.isError = false;
    },
    [fetchReportData.rejected.type]: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    [fetchReportDataPage.fulfilled.type]: (state, action: PayloadAction<FetchReportDataResponse>) => {
      state.isError = false;
      state.isLoading = false;
      state.data = [...state.data, ...action.payload.data];
    },
    [getDataPage.pending.type]: (state) => {
      state.isError = false;
      state.isLoading = true;
    },
    [getDataPage.fulfilled.type]: (state, action: PayloadAction<PaginatedData>) => {
      state.isError = false;
      state.isLoading = false;

      const { data, total_pages } = action.payload;

      const paginatedData = [...state.paginatedData, ...data];
      state.paginatedData = paginatedData;

      state.filteredPaginatedData = paginatedData;

      state.pagesCount = total_pages;
    },
    [getDataPage.rejected.type]: (state) => {
      state.isError = true;
      state.isLoading = false;
    },
    [setSelectedAppType.type]: (state) => {
      state.reports = [];
      state.data = [];
      state.filteredData = [];
      state.paginatedData = [];
      state.filteredPaginatedData = [];
    },
    [setSelectedLayer.type]: (state) => {
      state.reports = [];
      state.data = [];
      state.filteredData = [];
      state.paginatedData = [];
      state.filteredPaginatedData = [];
    },
    [setSelectedReportId.type]: (state) => {
      state.data = [];
      state.filteredData = [];
      state.paginatedData = [];
      state.filteredPaginatedData = [];
    },
  },
});

export const {
  setIsLoading,
  setIsError,
  setIsDataReload,
  setErrors,
  setData,
  setFilteredData,
  clearPolygonData,
  addToFilteredData,
  filterPaginatedData,
  clearPaginatedData,
  resetStore,
} = dataSlice.actions;

export function isLoadingSelector(state: RootState): boolean {
  return state.data.isLoading;
}

export function isErrorSelector(state: RootState): boolean {
  return state.data.isError;
}

export function isDataReloadSelector(state: RootState): boolean {
  return state.data.isDataReload;
}

export function errorsSelector(state: RootState): string[] {
  return state.data.errors;
}

export function dataSelector(state: RootState): Data[] {
  return state.data.data;
}

export function reportsSelector(state: RootState): Report[] {
  return state.data.reports;
}

export function filteredDataSelector(state: RootState): Data[] {
  return state.data.filteredData;
}

export function comparingDataSelector(state: RootState): Data[] {
  return state.data.comparingData;
}

export function paginatedDataSelector(state: RootState): Data[] {
  return state.data.paginatedData;
}

export function filteredPaginatedDataSelector(state: RootState): Data[] {
  return state.data.filteredPaginatedData;
}

export function pagesCountSelector(state: RootState): number | null {
  return state.data.pagesCount;
}

export default dataSlice.reducer;
