import { Data, FetchReportDataResponse, PaginatedData } from '../types';
import { REACT_APP_API_GW_ROUTE } from '../../../../constants/api';
import { request } from '../../../../helpers/request';
import { DataType } from '../../../../classes/AbstractData';
import { DATA_SEGMENTS_PER_PAGE } from '../../../../constants/dataParms';
import { Sorting } from '../../selection/store/store';
import { dataValueToSortValue } from '../../statistic/dataValueToSortValue';

export const API_POLYGON_DATA_SERVICE_PREFIX_URL = `${REACT_APP_API_GW_ROUTE}/polygon-data`;
const API_REPORTS_SERVICE_PREFIX_URL = `${REACT_APP_API_GW_ROUTE}/reports`;

export async function fetchDataByPolygonId(polygonId: number, layerName: DataType): Promise<Data[]> {
  const res = await request.get<Data[]>(`${API_POLYGON_DATA_SERVICE_PREFIX_URL}/${layerName}/${polygonId}`);
  return res.data;
}

export async function fetchReportData(
  layerName: DataType,
  polygonId: number,
  reportId: string,
  start = 1,
  limit = DATA_SEGMENTS_PER_PAGE,
  next = '',
): Promise<FetchReportDataResponse> {
  let url = '';

  if (next) {
    url = next;
  } else {
    url = `${API_REPORTS_SERVICE_PREFIX_URL}/${layerName}/${polygonId}?report_id=${reportId}&start=${start}&limit=${limit}`;
  }

  const res = await request.get<FetchReportDataResponse>(url);

  if (res.data.status !== 'SUCCESS') {
    throw Error('Error while fetching report data');
  }

  return res.data;
}

export async function fetchDataPage(
  polygonId: number,
  page: number,
  mainSort: Sorting,
  secSort: Sorting,
  layerName: string,
  reportId?: string,
): Promise<PaginatedData> {
  let url = `${API_POLYGON_DATA_SERVICE_PREFIX_URL}/${layerName}/${polygonId}?paginate=true&page=${page}&primary_sort=${
    dataValueToSortValue[mainSort.title as string]
  }&primary_desc=${!mainSort.isASC}&secondary_sort=${
    dataValueToSortValue[secSort.title as string]
  }&secondary_desc=${!secSort.isASC}`;

  if (reportId) {
    url = `${url}&report_id=${reportId}`;
  }

  const res = await request.get<PaginatedData>(url);

  return res.data;
}
