export const DEFAULT_MIN_ZOOM = 1;
export const DEFAULT_MAX_ZOOM = 16;
export const DEFAULT_ZOOM = 11;
export const DEFAULT_PITCH = 0;
export const DEFAULT_WW_MAX_ZOOM = 18;
export const DEFAULT_WW_MIN_ZOOM = 1;
export const DEFAULT_3D_PITCH = 45;
export const DEFAULT_3D_MAX_ZOOM = 25;
export const SEARCH_RESULTS_LIMIT = 10;
export const DEFAULT_BEARING = 0;
export const POLYGON_SELECTED_BORDER_COLOR = '#1E5A8C';
export const POLYGON_UNSELECTED_BORDER_COLOR = '#ffffff';
export const POLYGON_FILL_COLOR = '#ffffff';
