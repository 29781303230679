import React, { useEffect, useState } from 'react';
import { Tooltip, TooltipSide } from 'shared';
import cn from 'classnames';
import IconButton from '../../../../components/IconButton';
import { SnapshotLoad } from '../../../snapshot/components/SnapshotLoad';
import { SnapshotSave } from '../../../snapshot/components/SnapshotSave';
import { useSnapshot } from '../../../snapshot/useSnapshot';
import { layers } from '../../../../../configs/layers';
import { useAppSelector } from '../../../app/hooks';
import { selectSelectedLayer, selectSelectedSubLayer } from '../../store/store';
import { selectIsGlobalUser } from '../../../user/store/store';

export const SnapshotMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSaveSnapshotOpen, setIsSaveSnapshotOpen] = useState(false);
  const [isLoadSnapshotOpen, setIsLoadSnapshotOpen] = useState(false);

  const { snapshots, snapshotsInProgress } = useSnapshot();

  const selectedLayer = useAppSelector(selectSelectedLayer);
  const selectedSubLayer = useAppSelector(selectSelectedSubLayer);

  useEffect(() => {
    setIsOpen(false);
  }, [selectedLayer, selectedSubLayer]);

  const toggleOpen = () => setIsOpen(!isOpen);

  const isGlobalUser = useAppSelector(selectIsGlobalUser);

  const canSaveSnapshots = !isGlobalUser && snapshotsInProgress.length < 1;

  const toggleSaveSnapshotOpen = () => {
    if (!canSaveSnapshots) {
      return;
    }
    if (snapshotsInProgress.length) {
      return;
    }

    setIsSaveSnapshotOpen(!isSaveSnapshotOpen);
    setIsOpen(false);
  };

  const toggleLoadSnapshotOpen = () => {
    if (!snapshots.length) {
      return;
    }

    setIsLoadSnapshotOpen(!isLoadSnapshotOpen);
    setIsOpen(false);
  };

  let layerConfig = selectedLayer ? layers[selectedLayer] : undefined;
  if (layerConfig && layerConfig.subLayers && selectedSubLayer) {
    layerConfig = layerConfig.subLayers.find((subLayer) => subLayer.layerName === selectedSubLayer);
  }

  return (
    <>
      <Tooltip text="Snapshot" side={TooltipSide.BOTTOM}>
        <IconButton
          buttonClassList="header-btn"
          iconClassList="header-btn-icon icon icon-snapshot"
          onClickEvent={toggleOpen}
          disabled={!layerConfig || !layerConfig.hasSnapshots}
        />
      </Tooltip>
      <ul className={cn('snapshot-dropdown', { isActive: isOpen })} style={{ zIndex: 999 }}>
        <li
          className={cn(`${SNAPSHOT_DROPDOWN_ITEM_CLASS}`, { isDisabled: !canSaveSnapshots })}
          onClick={toggleSaveSnapshotOpen}
        >
          <i className="snapshot-dropdown-icon icon icon-save" />
          {SAVE_SNAPSHOT_TEXT}
          <i className="snapshot-dropdown-arrow icon icon-arrow" />
        </li>
        <li
          className={cn(`${SNAPSHOT_DROPDOWN_ITEM_CLASS}`, { isDisabled: snapshots.length === 0 })}
          onClick={toggleLoadSnapshotOpen}
        >
          <i className="snapshot-dropdown-icon icon icon-load" />
          Load Snapshot ({snapshots.length})
          <i className="snapshot-dropdown-arrow icon icon-arrow" />
        </li>
      </ul>
      <SnapshotLoad isOpen={isLoadSnapshotOpen} onClose={toggleLoadSnapshotOpen} />
      <SnapshotSave isOpen={isSaveSnapshotOpen} onClose={toggleSaveSnapshotOpen} />
    </>
  );
};

export const SNAPSHOT_DROPDOWN_ITEM_CLASS = 'snapshot-dropdown-item';
export const SAVE_SNAPSHOT_TEXT = 'Save Snapshot';

export default React.memo(SnapshotMenu);
