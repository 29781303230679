import React from 'react';
import { StatusIndicator } from 'shared';
import { useSnapshot } from '../../useSnapshot';

const SnapshotStatusIndicator: React.FC = () => {
  const { snapshotsInProgress, selectedSnapshot, selectSnapshot } = useSnapshot();

  return (
    <>
      {snapshotsInProgress.length > 0 && <StatusIndicator title="There is snapshot in progress of saving" />}
      {selectedSnapshot && (
        <StatusIndicator
          title={`Viewing snapshot ${selectedSnapshot.name}`}
          subTitle="return to live map >"
          onClickSubTitle={selectSnapshot.bind(this, null)}
        />
      )}
    </>
  );
};

export default React.memo(SnapshotStatusIndicator);
