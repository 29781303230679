import React, { useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { parse } from 'wkt';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectPolygons } from '../../../user/store/store';
import {
  selectSelectedPolygonIds,
  toggleOnlyOneSelectedPolygon,
  toggleSelectedPolygon,
} from '../../../selection/store/store';
import {
  POLYGON_FILL_COLOR,
  POLYGON_SELECTED_BORDER_COLOR,
  POLYGON_UNSELECTED_BORDER_COLOR,
} from '../../../../../constants/map';
import { getCenterOfPolygon } from '../../../../../helpers/common';
import { DEFAULT_ZOOM } from '../../../map/constants';

interface Props {
  map: mapboxgl.Map | null;
}

const SOURCE_NAME = 'polygons';
const LAYER_BORDERS_NAME = 'polygons-border';
const LAYER_FILL_NAME = 'polygons-fill';

const UserPolygonsLayer: React.FC<Props> = ({ map }: Props) => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const userPolygons = useAppSelector(selectPolygons);
  const selectedPolygonIds = useAppSelector(selectSelectedPolygonIds);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!map) return;

    createSourceAndLayers(map);
    setIsInitialized(true);

    map.on('dblclick', LAYER_FILL_NAME, onDbClickPolygon);
    map.on('click', LAYER_FILL_NAME, onAltClickPolygon);
  }, [map]);

  useEffect(() => {
    if (!map || !isInitialized) return;

    const polygons: MapPolygonItem[] = [];

    userPolygons.forEach((polygon) => {
      const isParentPolygonSelected = selectedPolygonIds.includes(polygon.id);
      let isCustomPolygonSelected = false;

      polygons.push({
        id: polygon.id,
        geometry: parse(polygon.polygon).coordinates[0],
        isSelected: isParentPolygonSelected,
        isParentPolygon: true,
      });

      if (!polygon.custom) return;

      polygon.custom.forEach((customPolygon) => {
        const isPolygonSelected = selectedPolygonIds.includes(customPolygon.id);

        if (!isPolygonSelected) return;

        isCustomPolygonSelected = true;

        polygons.push({
          id: customPolygon.id,
          geometry: parse(customPolygon.polygon).coordinates[0],
          isSelected: isPolygonSelected,
          isParentPolygon: false,
        });
      });

      if (!polygon.children || isCustomPolygonSelected) return;

      polygon.children.forEach((childrenPolygon) => {
        const isPolygonSelected = selectedPolygonIds.includes(childrenPolygon.id) || isParentPolygonSelected;

        polygons.push({
          id: childrenPolygon.id,
          geometry: parse(childrenPolygon.polygon).coordinates[0],
          isSelected: isPolygonSelected,
          isParentPolygon: false,
        });
      });
    });

    setLayerData(map, polygons);
  }, [userPolygons, selectedPolygonIds, isInitialized]);

  const onDbClickPolygon = (e: mapboxgl.MapLayerMouseEvent): void => {
    if (!e.features || !e.features[0] || !e.features[0].properties) return;

    const polygonProperties = e.features[0].properties;
    const parsedPolygonCoordinates = JSON.parse(polygonProperties.coordinates);
    const polygonId = polygonProperties.id;

    dispatch(toggleOnlyOneSelectedPolygon(polygonId));

    const center = getCenterOfPolygon(parsedPolygonCoordinates);

    e.target.flyTo({
      center,
      zoom: DEFAULT_ZOOM,
    });
  };

  const onAltClickPolygon = (e: mapboxgl.MapLayerMouseEvent): void => {
    if (!e.originalEvent.altKey || !e.features || !e.features[0] || !e.features[0].properties) return;

    const polygonProperties = e.features[0].properties;
    const polygonId = polygonProperties.id;
    const isParentPolygon = polygonProperties.isParentPolygon;

    if (isParentPolygon) {
      dispatch(toggleOnlyOneSelectedPolygon(polygonId));
      return;
    }

    dispatch(toggleSelectedPolygon(polygonId));
  };

  return null;
};

function createSourceAndLayers(map: mapboxgl.Map): void {
  map.addSource(SOURCE_NAME, {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  });

  map.addLayer({
    id: LAYER_BORDERS_NAME,
    type: 'line',
    source: SOURCE_NAME,
    paint: {
      'line-color': [
        'string',
        [
          'case',
          ['all', ['==', ['get', 'isSelected'], true]],
          POLYGON_SELECTED_BORDER_COLOR,
          ['all', ['==', ['get', 'isSelected'], false]],
          POLYGON_UNSELECTED_BORDER_COLOR,
          'black',
        ],
      ],
      'line-width': ['get', 'lineWidth'],
      'line-opacity': 1,
    },
  });

  map.addLayer({
    id: LAYER_FILL_NAME,
    type: 'fill',
    source: SOURCE_NAME,
    paint: {
      'fill-opacity': ['get', 'fillOpacity'],
      'fill-color': POLYGON_FILL_COLOR,
    },
  });
}

function setLayerData(map: mapboxgl.Map, polygons: MapPolygonItem[]): void {
  const source = map.getSource(SOURCE_NAME) as mapboxgl.GeoJSONSource;

  source.setData({
    type: 'FeatureCollection',
    features: polygons.map((polygon) => ({
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [polygon.geometry],
      },
      properties: {
        id: polygon.id,
        isSelected: polygon.isSelected,
        lineWidth: polygon.isSelected ? 2 : 0.5,
        fillOpacity: polygon.isSelected ? 0.07 : 0,
        coordinates: polygon.geometry,
        isParentPolygon: polygon.isParentPolygon,
      },
    })),
  });
}

interface MapPolygonItem {
  id: number;
  geometry: number[][];
  isSelected: boolean;
  isParentPolygon: boolean;
}

export default UserPolygonsLayer;
