import { AuthResponse, LoginCredentials } from '../types';
import { REACT_APP_API_GW_ROUTE } from '../../../../constants/api';
import { ResponseCodes } from '../../../../interfaces';
import { UserCredentialsError } from '../../user/errors';
import { request } from '../../../../helpers/request';

export const API_AUTH_SERVICE_URL = `${REACT_APP_API_GW_ROUTE}/auth`;

export const API_LOGIN_URL = `${API_AUTH_SERVICE_URL}/login`;
export const API_LOGOUT_URL = `${API_AUTH_SERVICE_URL}/logout`;

export async function login(credentials: LoginCredentials): Promise<AuthResponse> {
  try {
    const res = await request.post<AuthResponse>(API_LOGIN_URL, credentials);
    return res.data;
  } catch (error: any) {
    if (error.response.status === ResponseCodes.NOT_FOUND || error.response.status === ResponseCodes.UNAUTHORIZED) {
      throw new UserCredentialsError('Invalid user credentials');
    }

    throw new Error('Unable to login');
  }
}

export async function logout(): Promise<void> {
  await request.post(API_LOGOUT_URL);
}
