import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import mapboxgl, { GeoJSONSource, VectorSourceImpl } from 'mapbox-gl';
import { MapTooltip, socketClient } from 'shared';
import * as turf from '@turf/turf';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  selectSelectedLayer,
  selectSelectedPolygonIds,
  selectSelectedSubLayer,
  selectSelectedReportId,
  selectedSegmentIdsSelector,
} from '../../../selection/store/store';
import { FilterConfig, LayerConfig, layers } from '../../../../../configs/layers';
import { selectCustomPolygons, selectPolygons, selectUser } from '../../../user/store/store';
import { selectedFiltersSelector } from '../../../filter/store/store';
import { convertFiltersForMapbox } from '../../../../../helpers/common';
import { SelectedFilters } from '../../../filter/types';
import { CustomPolygon, LayerName, UserPolygon } from '../../../user/types';
import { filteredDataSelector, isDataReloadSelector, setIsDataReload } from '../../store/store';
import { Data } from '../../types';
import { getCoordinatesFromPolygonGeometry } from '../../../map/helpers';
import { IDataSubscriptionService } from '../../services/DataSubscriptionService';
import { container } from '../../../../../configs/inversify';
import { SocketDataUpdateMessage } from '../../types';
import { bboxSelector } from '../../../map/store/store';
import { WEB_SOCKET_URL } from '../../../../../constants/api';
import { ILocalStorageService } from '../../../../services/LocalStorageService';
import { useDataLoad } from '../../hooks/useDataLoad';
import { selectedSnapshotIdSelector } from '../../../snapshot/store/store';

type GeometryFilter = ['within', { type: 'Polygon'; coordinates: [number[][]] }][];

interface Props {
  map: mapboxgl.Map | null;
}

const localStorageService: ILocalStorageService = container.get('LocalStorageService');
const dataSubscriptionService: IDataSubscriptionService = container.get('DataSubscriptionService');

const UserLayers: React.FC<Props> = ({ map }: Props) => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const selectedLayer = useAppSelector(selectSelectedLayer);
  const selectedSubLayer = useAppSelector(selectSelectedSubLayer);
  const selectedReport = useAppSelector(selectSelectedReportId);
  const selectedSnapshotId = useAppSelector(selectedSnapshotIdSelector);
  const user = useAppSelector(selectUser);
  const selectedFilters = useAppSelector(selectedFiltersSelector);
  const selectedSegmentIds = useAppSelector(selectedSegmentIdsSelector);
  const polygons = useAppSelector(selectPolygons);
  const customPolygons = useAppSelector(selectCustomPolygons);
  const selectedPolygonIds = useAppSelector(selectSelectedPolygonIds);
  const filteredData = useAppSelector(filteredDataSelector);
  const bbox = useAppSelector(bboxSelector);
  const isDataReload = useAppSelector(isDataReloadSelector);

  const dispatch = useAppDispatch();

  const popupRef = useRef<mapboxgl.Popup>(
    new mapboxgl.Popup({
      closeButton: false,
    }),
  );

  useDataLoad();

  useEffect(() => {
    const token = localStorageService.getItem<string>('Authorization');

    if (token) {
      socketClient.connect(WEB_SOCKET_URL, token);
    }

    return () => socketClient.disconnect();
  }, []);

  useEffect(() => {
    if (!map) return;

    setIsInitialized(false);

    removeAllLayers(map);
    popupRef.current.remove();

    if (!selectedLayer || !user) return;

    let layerConfig = layers[selectedLayer];

    if (!layerConfig) return;

    if (selectedSubLayer && layerConfig.subLayers) {
      layerConfig = layerConfig.subLayers.find(
        (subLayerConfig) => subLayerConfig.layerName === selectedSubLayer,
      ) as LayerConfig;
    }

    showLayer(map, layerConfig, selectedReport, user.regions[0], popupRef.current);

    setIsInitialized(true);
  }, [map, selectedLayer, selectedSubLayer, selectedReport, user]);

  useEffect(() => {
    if (!map || !selectedLayer) return;

    setJsonDataToLayer(map, selectedLayer, selectedSubLayer, filteredData);
  }, [map, selectedLayer, selectedSubLayer, filteredData]);

  useEffect(() => {
    if (!map || !selectedLayer || !isInitialized) {
      return;
    }

    applyLayerFilters(
      map,
      selectedLayer,
      selectedSubLayer,
      selectedFilters,
      selectedSegmentIds,
      polygons,
      customPolygons,
      selectedPolygonIds,
    );
  }, [
    map,
    selectedLayer,
    selectedSubLayer,
    selectedFilters,
    selectedSegmentIds,
    polygons,
    customPolygons,
    selectedPolygonIds,
    isInitialized,
    selectedSnapshotId,
  ]);

  useEffect(() => {
    if (!selectedLayer || !polygons.length) {
      return;
    }

    const polygonId = polygons[0].id;
    let layerConfig = layers[selectedLayer];

    if (!layerConfig) return;

    if (selectedSubLayer && layerConfig.subLayers) {
      layerConfig = layerConfig.subLayers.find(
        (sublayerConfig) => sublayerConfig.layerName === selectedSubLayer,
      ) as LayerConfig;
    }

    if (layerConfig.isLiveLayer) {
      dataSubscriptionService.subscribe(polygonId, layerConfig.layerName as LayerName, (msg) => {
        onDataUpdate(msg);
      });
    } else {
      dataSubscriptionService.unsubscribe(polygonId, layerConfig.layerName as LayerName);
    }

    return () => {
      if (layerConfig) {
        dataSubscriptionService.unsubscribe(polygonId, layerConfig.layerName as LayerName);
      }
    };
  }, [selectedLayer, selectedSubLayer, polygons.length]);

  useEffect(() => {
    if (!map || !selectedLayer || !isDataReload) return;

    let layerConfig = layers[selectedLayer];

    if (!layerConfig) return;

    if (selectedSubLayer && layerConfig.subLayers) {
      layerConfig = layerConfig.subLayers.find(
        (subLayerConfig) => subLayerConfig.layerName === selectedSubLayer,
      ) as LayerConfig;
    }

    const source = map.getSource(layerConfig.layerName) as VectorSourceImpl;
    if (source && source.tiles) {
      source.setTiles(source.tiles);
    }
  }, [isDataReload]);

  const onDataUpdate = (msg: SocketDataUpdateMessage) => {
    if (!bbox) return;

    const updatedAreaCoords = getCoordinatesFromPolygonGeometry(msg.data.updated_area);
    const updatedPolygon = turf.polygon([[...updatedAreaCoords, updatedAreaCoords[0]]]);
    const userBboxPolygon = turf.polygon([[...bbox, bbox[0]]]);
    const intersectedPolygon = turf.intersect(updatedPolygon, userBboxPolygon);

    if (intersectedPolygon) {
      reloadData();
    }
  };

  const reloadData = (): void => {
    dispatch(setIsDataReload(true));
    setTimeout(() => {
      dispatch(setIsDataReload(false));
    }, 500);
  };

  return null;
};

function removeAllLayers(map: mapboxgl.Map): void {
  Object.keys(layers).forEach((layerName) => {
    const layerConfig = layers[layerName as LayerName];

    if (!layerConfig) return;

    if (layerConfig.subLayers) {
      layerConfig.subLayers.forEach((subLayer) => {
        removeMapLayerAndSourceByLayerName(map, subLayer);
      });
    }

    removeMapLayerAndSourceByLayerName(map, layerConfig);
  });
}

function removeMapLayerAndSourceByLayerName(map: mapboxgl.Map, layerConfig: LayerConfig): void {
  if (layerConfig.mapLayers) {
    layerConfig.mapLayers.forEach((layer) => {
      if (map.getLayer(layer.id)) {
        map.removeLayer(layer.id);
      }
    });
  }

  if (map.getSource(layerConfig.layerName)) {
    map.removeSource(layerConfig.layerName);
  }
}

const showLayer = (
  map: mapboxgl.Map,
  layerConfig: LayerConfig,
  selectedReportId: string | null,
  polygonId: number,
  popup: mapboxgl.Popup,
): void => {
  if (!layerConfig.mapSource) {
    return;
  }

  if (layerConfig.mapSource.type === 'tiles') {
    map.addSource(
      layerConfig.layerName,
      layerConfig.mapSource.source(
        selectedReportId ? { report_id: selectedReportId, region_id: polygonId } : undefined,
      ),
    );
  }

  if (layerConfig.mapSource.type === 'json' || layerConfig.mapSource.type === 'report') {
    map.addSource(layerConfig.layerName, layerConfig.mapSource.source());
  }

  if (layerConfig.mapLayers) {
    layerConfig.mapLayers.forEach((layer) => {
      map.addLayer(layer);

      if (layerConfig.symbology.tooltip) {
        map.on('mousedown', layer.id, () => {
          popup.remove();
        });

        map.on('click', layer.id, (e) => {
          if (!e.features || !layerConfig.symbology.tooltip) {
            return;
          }

          const feature = e.features[0];
          if (!feature.properties) return;

          if (feature.properties.coords) {
            feature.properties.coords = JSON.parse(feature.properties.coords);
          }

          if ('cluster' in feature.properties) {
            return;
          }

          const icon = layerConfig.symbology.tooltip.icon;
          const heading = layerConfig.symbology.tooltip.heading;
          const fields = layerConfig.symbology.tooltip.fieldsCreator(feature.properties);

          const popupNode = document.createElement('div');
          ReactDOM.render(
            React.createElement(MapTooltip, {
              icon,
              heading,
              fields,
            }),
            popupNode,
          );
          popup.setLngLat(e.lngLat).setDOMContent(popupNode).addTo(map);
        });
      }
    });
  }
};

function applyLayerFilters(
  mapInstance: mapboxgl.Map,
  selectedLayer: LayerName,
  selectedSubLayer: string | null,
  selectedFilters: SelectedFilters,
  selectedSegmentIds: string[],
  polygons: UserPolygon[],
  customPolygons: CustomPolygon[],
  selectedPolygonIds: number[],
): void {
  const layerConfig = layers[selectedLayer];

  if (!layerConfig) return;

  let filtersFromLayers = layerConfig.filters;

  if (layerConfig.subLayers && selectedSubLayer) {
    const subLayerConfig = layerConfig.subLayers.find((subLayer) => subLayer.layerName === selectedSubLayer);

    if (subLayerConfig) {
      filtersFromLayers = { ...filtersFromLayers, ...subLayerConfig.filters };
    }
  }

  if (layerConfig.mapLayers && filtersFromLayers) {
    layerConfig.mapLayers.forEach((layer) => {
      if (filtersFromLayers !== undefined) {
        applyFiltersForLayer(
          layer.id,
          filtersFromLayers,
          mapInstance,
          selectedFilters,
          selectedSegmentIds,
          polygons,
          customPolygons,
          selectedPolygonIds,
        );
      }
    });
  }

  if (layerConfig.subLayers && selectedSubLayer) {
    const subLayerConfig = layerConfig.subLayers.find((subLayer) => subLayer.layerName === selectedSubLayer);

    if (subLayerConfig && subLayerConfig.mapLayers) {
      subLayerConfig.mapLayers.forEach((layer) => {
        if (filtersFromLayers !== undefined) {
          applyFiltersForLayer(
            layer.id,
            filtersFromLayers,
            mapInstance,
            selectedFilters,
            selectedSegmentIds,
            polygons,
            customPolygons,
            selectedPolygonIds,
          );
        }
      });
    }
  }
}

function applyFiltersForLayer(
  layerId: string,
  filters: Record<string, FilterConfig>,
  mapInstance: mapboxgl.Map,
  selectedFilters: SelectedFilters,
  selectedSegmentIds: string[],
  polygons: UserPolygon[],
  customPolygons: CustomPolygon[],
  selectedPolygonIds: number[],
): void {
  const appropriateFilters = filterUnappropriatedFilters(filters, layerId);

  const segmentsFilter = ['in', ['get', 'subSegmentId'], ['literal', selectedSegmentIds]];
  const mapboxFilters = [
    'all',
    !selectedSegmentIds.length ? convertFiltersForMapbox(selectedFilters, appropriateFilters) : segmentsFilter,
  ];

  if (appropriateFilters.geometryFilter) {
    const geometryFilters: GeometryFilter = [];

    polygons.forEach((polygon) => {
      if (selectedPolygonIds.includes(polygon.id)) {
        const geometry = getCoordinatesFromPolygonGeometry(polygon.polygon);

        geometryFilters.push(['within', { type: 'Polygon', coordinates: [geometry] }]);
      }

      polygon.children.forEach((childrenPolygon) => {
        if (selectedPolygonIds.includes(childrenPolygon.id)) {
          const geometry = getCoordinatesFromPolygonGeometry(childrenPolygon.polygon);

          geometryFilters.push(['within', { type: 'Polygon', coordinates: [geometry] }]);
        }
      });
    });

    customPolygons.forEach((polygon) => {
      if (selectedPolygonIds.includes(polygon.id)) {
        const geometry = getCoordinatesFromPolygonGeometry(polygon.polygon);

        geometryFilters.push(['within', { type: 'Polygon', coordinates: [geometry] }]);
      }
    });

    mapboxFilters.push(['any', ...geometryFilters]);
  }

  mapInstance.setFilter(layerId, mapboxFilters);
}

function filterUnappropriatedFilters(
  filters: Record<string, FilterConfig>,
  layerId: string,
): Record<string, FilterConfig> {
  const res: Record<string, FilterConfig> = {};

  Object.entries(filters).forEach(([filterName, filterOptions]) => {
    if (layerId === 'current-grip-segment-buckets' && filterName === 'gripFilter') {
      res[filterName] = {
        ...filterOptions,
        filterableValue: 'mueBucketFrom',
      };
      return;
    }

    if (
      layerId === 'current-grip-subtiles' &&
      (filterName === 'gripFilter' || filterName === 'functionalRoadClassFilter' || filterName === 'qualifierFilter')
    ) {
      return;
    }

    res[filterName] = filterOptions;
  });

  return res;
}

function setJsonDataToLayer(
  map: mapboxgl.Map,
  selectedLayer: LayerName,
  selectedSubLayer: string | null,
  filteredData: Data[],
): void {
  let layerConfig = layers[selectedLayer];

  if (!layerConfig) return;

  if (layerConfig.subLayers) {
    const subLayerConfig = layerConfig.subLayers.find((subLayer) => subLayer.layerName === selectedSubLayer);

    if (subLayerConfig) {
      layerConfig = subLayerConfig;
    }
  }

  const mapSource = map.getSource(layerConfig.layerName) as GeoJSONSource;

  if (mapSource && layerConfig.mapSource && layerConfig.mapSource.mapper) {
    mapSource.setData(layerConfig.mapSource.mapper(filteredData));
  }
}

export default UserLayers;
