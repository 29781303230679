import { AnyLayer, AnySourceData } from 'mapbox-gl';
import { AppType } from '../../app/modules/selection/types';
import { grip } from './grip';
import { coverage } from './coverage';
import { slipperiness } from './slipperiness';
import { pavement } from './pavement';
import { pothole } from './pothole';
import { FilterFunction, FilterType } from '../../app/modules/filter/types';
import { Data } from '../../app/modules/layers/types';
import { gripSimplified } from './simplifiedLayers/grip';
import { slipperinessSimplified } from './simplifiedLayers/slipperiness';
import { surfaceEvents } from './surfaceEvents';
import { surfaceEventsSimplified } from './simplifiedLayers/surfaceEvents';
import { LayerName } from '../../app/modules/user/types';

export type UrlProvider = (...params: string[]) => string;
export type SourceProvider = (...params: any[]) => AnySourceData;
export type TooltipFieldsCreator = (data: Record<string, string>) => {
  title: string;
  value: string;
}[];

export interface LayerConfig {
  layerName: string;
  isLiveLayer: boolean;
  hasSnapshots?: boolean;
  mapSource?: {
    type: 'tiles' | 'json' | 'report';
    source: SourceProvider;
    mapper?: (data: Data[]) => any;
  };
  mapLayers?: AnyLayer[];
  statisticSource?: {
    type: 'url' | 'json' | 'report';
    url: UrlProvider;
    dependingLayer?: string;
  };
  symbology: {
    title: string;
    iconClass: string;
    saveSnapshotLayerName?: string;
    tooltip?: {
      icon: string;
      heading: string;
      fieldsCreator: TooltipFieldsCreator;
    };
  };
  filters?: Record<string, FilterConfig>;
  subLayers?: LayerConfig[];
}

export interface Filter {
  title: string;
  filterName: string;
  filterableValue: string;
  isHidden?: boolean;
  dataFilterFunction?: FilterFunction;
  mapFilter?: [];
}

export type FilterConfig = ButtonsFilter | RangeFilter | GeometryFilter;

export interface ButtonsFilter extends Filter {
  filterType: FilterType.BUTTONS;
  options: FilterOptions[];
  defaultState: Record<string, boolean>;
}

export interface RangeFilter extends Filter {
  filterType: FilterType.RANGE;
  minmax: number[];
  defaultState: Record<string, number>;
}

export interface GeometryFilter extends Filter {
  filterType: FilterType.GEOMETRY;
  defaultState: Record<string, boolean>;
}

export interface FilterOptions {
  title: string;
  name: string;
  values: number[] | string[];
}

export const layersToAppType: Record<AppType, LayerName[]> = {
  [AppType.GRIP]: [
    LayerName.GRIP,
    LayerName.GRIP_SIMPLIFIED,
    LayerName.SLIPPERINESS,
    LayerName.COVERAGE,
    LayerName.SLIPPERINESS_SIMPLIFIED,
  ],
  [AppType.PAVEMENT]: [LayerName.PAVEMENT, LayerName.COVERAGE],
  [AppType.POTHOLE]: [LayerName.POTHOLE],
  [AppType.SURFACE_EVENTS]: [LayerName.SURFACE_EVENTS, LayerName.SURFACE_EVENTS_SIMPLIFIED],
};

export const layers: Partial<Record<LayerName, LayerConfig>> = {
  [LayerName.GRIP]: grip,
  [LayerName.COVERAGE]: coverage,
  [LayerName.SLIPPERINESS]: slipperiness,
  [LayerName.PAVEMENT]: pavement,
  [LayerName.POTHOLE]: pothole,
  [LayerName.GRIP_SIMPLIFIED]: gripSimplified,
  [LayerName.SLIPPERINESS_SIMPLIFIED]: slipperinessSimplified,
  [LayerName.SURFACE_EVENTS]: surfaceEvents,
  [LayerName.SURFACE_EVENTS_SIMPLIFIED]: surfaceEventsSimplified,
};
