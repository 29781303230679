import React from 'react';
import { BarGraph } from '../../../../../components/BarGraph';
import { DashboardState } from '../index';
import { DashboardMode } from '../../../../../../classes/AbstractData';
import { FormattedUnits } from '../../../../../components/FormattedUnits';

interface Props {
  barsState: DashboardState;
}

const BarGraphs: React.FC<Props> = (props: Props) => {
  const { barsState } = props;

  return (
    <div className="dashboard-progress dashboard-line">
      {Object.values(barsState.functionalRoadClassDashboardState).map(
        ({ title, state }, index) =>
          title && (
            <div key={`${title}-${index}`}>
              <p className="dashboard-line-size">
                <FormattedUnits value={state.length} showUnit round digitsAfterPoint={1} />
              </p>
              <p className="dashboard-line-title">{title}</p>
              <div data-testid="progress-bars" className="dashboard-progress-bars">
                <BarGraph value={state.high} color={barsState.highColor} />
                {barsState.mode === DashboardMode.MANY && (
                  <>
                    <BarGraph value={state.medium} color={barsState.mediumColor} />
                    <BarGraph value={state.low} color={barsState.lowColor} />
                  </>
                )}
              </div>
            </div>
          ),
      )}
    </div>
  );
};

export default BarGraphs;
