import React from 'react';

interface Props {
  title: string;
  isSelected: boolean;
  onClick: () => void;
}

export const Area: React.FC<Props> = (props) => {
  const { title, isSelected, onClick } = props;

  return (
    <div className="sidebar-area">
      <button className="dropdown-list-btn">+</button>
      <label htmlFor={title} className="dropdown-select">
        <input
          type="checkbox"
          id={title}
          className="dropdown-select-input hidden"
          checked={isSelected}
          onChange={onClick}
        />
        <span className="dropdown-select-title">{title}</span>
      </label>
    </div>
  );
};
