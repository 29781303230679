import React, { useEffect, useState } from 'react';
import { ButtonsFilter } from '../ButtonsFilter';
import { FilterType, SelectedFilters } from '../../../types';
import { RangeFilter } from '../RangeFilter';
import { FilterConfig, layers } from '../../../../../../configs/layers';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { selectSelectedLayer, selectSelectedSubLayer } from '../../../../selection/store/store';
import { selectedFiltersSelector, setSelectedFilters, setFilterOption } from '../../../store/store';
import { useFilter } from '../../../hooks/useFilter';
import { LayerName } from '../../../../user/types';

export const FilterSelector: React.FC = () => {
  const [filters, setFilters] = useState<Record<string, FilterConfig>>({});

  const selectedLayer = useAppSelector(selectSelectedLayer);
  const selectedSubLayer = useAppSelector(selectSelectedSubLayer);
  const selectedFilters = useAppSelector(selectedFiltersSelector);

  useFilter();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedLayer) {
      setLayerFilters(selectedLayer);
    }
  }, [selectedLayer, selectedSubLayer]);

  const setLayerFilters = (selectedLayer: LayerName): void => {
    const layerConfig = layers[selectedLayer];

    if (!layerConfig) return;

    let filters: Record<string, FilterConfig> = { ...layerConfig.filters };

    if (layerConfig.subLayers) {
      const sublayerConfig = layerConfig.subLayers.find(
        (subLayerConfig) => subLayerConfig.layerName === selectedSubLayer,
      );

      if (sublayerConfig) {
        filters = { ...filters, ...sublayerConfig.filters };
      }
    }

    let selections: SelectedFilters = {};

    for (const key in filters) {
      const config = filters[key];

      if (config.defaultState) {
        selections = {
          ...selections,
          [config.filterName]: config.defaultState,
        };
      }
    }

    setFilters(filters);

    dispatch(setSelectedFilters(selections));
  };

  const handleResetFilters = (): void => {
    if (selectedLayer) {
      setLayerFilters(selectedLayer);
    }
  };

  const setFilterOptionValue = (filterName: string, filterOption: string, value: boolean | number): void => {
    dispatch(setFilterOption({ filterName, filterOption, value }));
  };

  return (
    <>
      {/*<div className="controls-header">*/}
      {/*  Recent filters <i className="controls-header-icon icon icon-arrow" />*/}
      {/*</div>*/}

      {Object.entries(filters).map(([filterName, filterConfig]) => {
        if (filterConfig.isHidden) return null;

        if (filterConfig.filterType === FilterType.BUTTONS && 'options' in filterConfig) {
          const filterOptions = filterConfig.options.map((option) => ({
            name: option.name,
            title: option.title,
            isSelected: !!(selectedFilters[filterName] && selectedFilters[filterName][option.name]),
          }));

          return (
            <ButtonsFilter
              key={filterConfig.filterName}
              title={filterConfig.title}
              filterName={filterName}
              filterOptions={filterOptions}
              onClick={setFilterOptionValue}
            />
          );
        } else if (filterConfig.filterType === FilterType.RANGE && 'minmax' in filterConfig) {
          return (
            <RangeFilter
              key={filterConfig.filterName}
              title={filterConfig.title}
              filterName={filterName}
              min={filterConfig.minmax[0]}
              max={filterConfig.minmax[1]}
              currentValue={selectedFilters[filterConfig.filterName][filterConfig.filterName] as number}
            />
          );
        }
      })}

      <div className="controls-footer">
        {/*<button className="controls-footer-btn" disabled>*/}
        {/*  Save preset*/}
        {/*</button>*/}
        <button className="controls-footer-btn" onClick={handleResetFilters}>
          Reset
        </button>
      </div>
    </>
  );
};
