import React from 'react';

interface Props {
  iconClassList?: string;
  buttonClassList?: string;
  disabled?: boolean;
  onClickEvent?: () => void;
  testId?: string;
  id?: string;
  role?: string;
}

const IconButton: React.FC<Props> = (props: Props) => {
  const { disabled = false, iconClassList, onClickEvent, buttonClassList, testId, id, role } = props;

  return (
    <button
      className={buttonClassList}
      disabled={disabled}
      onClick={onClickEvent}
      data-testid={testId}
      id={id}
      role={role}
    >
      <i className={iconClassList} />
    </button>
  );
};

export default IconButton;
