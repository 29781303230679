import { createAsyncThunk } from '@reduxjs/toolkit';
import * as SnapshotApi from '../api/api';
import { FetchSnapshotParams, SaveSnapshotParams } from '../types';

export const saveSnapshot = createAsyncThunk('snapshot/saveSnapshot', async (params: SaveSnapshotParams) => {
  return await SnapshotApi.saveSnapshot(params);
});

export const fetchSnapshots = createAsyncThunk('data/fetchSnapshots', async (params: FetchSnapshotParams) => {
  return await SnapshotApi.fetchSnapshots(params);
});
