import { Position } from '@turf/turf';
import { ALL_KEY } from './layers';

export interface User {
  userId: number;
  accountId: number;
  name: string;
  title: string;
  username: string;
  logo: string | null;
  units: Units;
  regions: number[];
  custom_regions: number[];
  layers: UserLayers;
  streams: string[];
  isGlobal: boolean;
}

export type UserLayers = (LayerName | typeof ALL_KEY)[];

export enum LayerName {
  GRIP = 'grip',
  LATEST = 'current-grip',
  COVERAGE = 'coverage',
  SLIPPERINESS = 'slipperiness',
  GROUND_TRUTH = 'ground-truth',
  PAVEMENT = 'pavement',
  POTHOLE = 'pothole',
  SURFACE_EVENTS = 'surface-events',
  GRIP_SIMPLIFIED = 'grip-simplified',
  LATEST_SIMPLIFIED = 'current-grip-simplified',
  SLIPPERINESS_SIMPLIFIED = 'slipperiness-simplified',
  GROUND_TRUTH_SIMPLIFIED = 'ground-truth-simplified',
  SURFACE_EVENTS_SIMPLIFIED = 'surface-events-simplified',
}

export interface ApiAccountConfigResponse {
  status: 'SUCCESS' | 'FAILED';
  account: ApiAccountConfig;
}

export interface ApiAccountConfig {
  title: string;
  units: string;
  is_global: boolean;
  layers: string[];
  regions: number[];
  streams: string[];
  logo: string | null;
}

export interface ApiUserConfigResponse {
  status: 'SUCCESS' | 'FAILED';
  user: ApiUserConfig;
}

export interface ApiUserConfig {
  id: number;
  account_id: number;
  name: string;
  username: string;
  custom_regions: number[];
  layers: string[];
  streams: string[];
  units: string;
}

export interface UserConfig {
  id: number;
  account_id: number;
  name: string;
  username: string;
  custom_regions: number[];
  layers: UserLayers;
  streams: [];
  units: Units;
}

export interface ApiUserPolygonResponse {
  status: 'SUCCESS' | 'FAILED';
  polygon: ApiUserPolygon;
}

export interface ApiUserCustomPolygonsResponse {
  status: 'SUCCESS' | 'FAILED';
  polygons: CustomPolygon[];
}

export interface ApiUserPolygon {
  id: number;
  children: ApiUserPolygon[];
  custom?: CustomPolygon[];
  parent_id: number | undefined;
  base_polygon_id?: number;
  isEnabled?: boolean;
  polygon: string;
  title: string;
}

export interface UserPolygon {
  id: number;
  children: UserPolygon[];
  custom?: CustomPolygon[];
  parent_id: number | undefined;
  base_polygon_id?: number;
  isEnabled?: boolean;
  polygon: string;
  title: string;
}

export interface CustomPolygon {
  base_polygon_id: number;
  id: number;
  polygon: string;
  title: string;
}

export interface ApiSaveCustomPolygonResponse {
  status: 'SUCCESS' | 'FAILED';
  polygon: CustomPolygon;
}

export interface SaveCustomPolygonData {
  title: string;
  polygon: Position[][];
  basePolygonId: number;
}

export type Units = 'metric' | 'imperial';
