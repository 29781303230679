import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppType, SelectedReports } from '../types';
import { RootState } from '../../app/store';
import { DataItem } from '../../layers/types';
import { Report } from '../types';
import { fetchReports } from './thunks';
import { LayerName } from '../../user/types';

export type SortingTitle = keyof DataItem | 'segment_ID';

export interface Sorting {
  isASC: boolean;
  title: SortingTitle | null;
}

const initMainSorting: Sorting = { title: 'segment_ID', isASC: true };
const initSecondSorting: Sorting = { title: null, isASC: true };

export interface SelectionState {
  isLoading: boolean;
  isError: boolean;
  selectedAppType: AppType | null;
  selectedLayer: LayerName | null;
  selectedSubLayer: string | null;
  selectedReportId: string | null;
  selectedSegmentIds: string[];
  selectedPolygonIds: number[];
  reports: Report[];
  selectedMainSorting: Sorting;
  selectedSecondSorting: Sorting;
}

export const initialState: SelectionState = {
  isLoading: false,
  isError: false,
  selectedAppType: null,
  selectedLayer: null,
  selectedSubLayer: null,
  selectedReportId: null,
  selectedSegmentIds: [],
  selectedPolygonIds: [],
  reports: [],
  selectedMainSorting: { title: 'segment_ID', isASC: true },
  selectedSecondSorting: { title: null, isASC: true },
};

export const selectionSlice = createSlice({
  name: 'selection',
  initialState,
  reducers: {
    setSelectedAppType: (state, action: PayloadAction<AppType>) => {
      state.selectedAppType = action.payload;
      state.selectedLayer = null;
      state.selectedReportId = null;
      state.reports = [];
    },
    setSelectedReportId: (state, action: PayloadAction<string | null>) => {
      state.selectedReportId = action.payload;
    },
    setSelectedLayer: (state, action: PayloadAction<LayerName | null>) => {
      state.selectedLayer = state.selectedLayer === action.payload ? null : action.payload;
      state.selectedSubLayer = null;
      state.selectedReportId = null;
      state.reports = [];
    },
    setSelectedSubLayer: (state, action: PayloadAction<string | null>) => {
      state.selectedSubLayer = action.payload;
    },
    resetLayersState: (state) => {
      state.selectedLayer = null;
      state.selectedAppType = null;
      state.selectedSubLayer = null;
    },
    setSelectedPolygonId: (state, action: PayloadAction<number>) => {
      state.selectedPolygonIds = [action.payload];
    },
    toggleOnlyOneSelectedPolygon: (state, action: PayloadAction<number>) => {
      if (state.selectedPolygonIds.includes(action.payload)) {
        state.selectedPolygonIds = [];
        return;
      }

      state.selectedPolygonIds = [action.payload];
    },
    toggleSelectedPolygon: (state, action: PayloadAction<number>) => {
      if (state.selectedPolygonIds.includes(action.payload)) {
        state.selectedPolygonIds = state.selectedPolygonIds.filter((id) => id !== action.payload);
        return;
      }

      state.selectedPolygonIds = [...state.selectedPolygonIds, action.payload];
    },
    setSelectedMainSorting: (state, action: PayloadAction<Sorting>) => {
      state.selectedMainSorting = action.payload;
    },
    setSelectedSecondSorting: (state, action: PayloadAction<Sorting>) => {
      state.selectedSecondSorting = action.payload;
    },
    resetSorting: (state) => {
      state.selectedMainSorting = initMainSorting;
      state.selectedSecondSorting = initSecondSorting;
    },
    setSelectedSegmentIds: (state, action: PayloadAction<string[]>) => {
      state.selectedSegmentIds = action.payload;
    },
    toggleSegmentId: (state, action: PayloadAction<string>) => {
      const segmentId = action.payload;

      if (state.selectedSegmentIds.includes(segmentId)) {
        state.selectedSegmentIds = state.selectedSegmentIds.filter((id) => id !== segmentId);
      } else {
        state.selectedSegmentIds = [...state.selectedSegmentIds, segmentId];
      }
    },
    resetSelectedSegments: (state) => {
      state.selectedSegmentIds = [];
    },
    resetStore: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchReports.pending.type, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchReports.rejected.type, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(fetchReports.fulfilled.type, (state, action: PayloadAction<Report[]>) => {
        state.isLoading = false;
        state.isError = false;
        state.reports = action.payload;
      });
  },
});

export const {
  setSelectedAppType,
  setSelectedReportId,
  setSelectedLayer,
  setSelectedSubLayer,
  resetLayersState,
  setSelectedPolygonId,
  toggleOnlyOneSelectedPolygon,
  toggleSelectedPolygon,
  setSelectedMainSorting,
  setSelectedSecondSorting,
  resetSorting,
  setSelectedSegmentIds,
  toggleSegmentId,
  resetSelectedSegments,
  resetStore,
} = selectionSlice.actions;

export function selectSelectedAppType(state: RootState): AppType | null {
  return state.selection.selectedAppType;
}

export function selectSelectedLayer(state: RootState): LayerName | null {
  return state.selection.selectedLayer;
}

export function selectSelectedSubLayer(state: RootState): string | null {
  return state.selection.selectedSubLayer;
}

export function selectReports(state: RootState): SelectedReports {
  const [latestReport, previousReport, ...restReports] = state.selection.reports;

  return {
    latestReport,
    previousReport,
    restReports,
  };
}

export function selectSelectedReport(state: RootState): Report | undefined {
  return state.selection.reports.find((report) => report.id === state.selection.selectedReportId);
}

export function selectSelectedReportId(state: RootState): string | null {
  return state.selection.selectedReportId;
}

export function selectSelectedPolygonIds(state: RootState): number[] {
  return state.selection.selectedPolygonIds;
}

export function selectedMainSortingSelector(state: RootState): Sorting {
  return state.selection.selectedMainSorting;
}

export function selectedSecondSortingSelector(state: RootState): Sorting {
  return state.selection.selectedSecondSorting;
}

export function selectedSegmentIdsSelector(state: RootState): string[] {
  return state.selection.selectedSegmentIds;
}

export function selectSegmentIdsSelector(state: RootState): string[] {
  return state.selection.selectedSegmentIds;
}

export default selectionSlice.reducer;
