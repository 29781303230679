import { resetStore as resetAuthStore } from '../auth/store/store';
import { resetStore as resetFilterStore } from '../filter/store/store';
import { resetStore as resetLayerStore } from '../layers/store/store';
import { resetStore as resetSelectionStore } from '../selection/store/store';
import { resetStore as resetSnapshotStore } from '../snapshot/store/store';
import { resetStore as resetUserStore } from '../user/store/store';
import { resetStore as resetMapStore } from '../map/store/store';
import { useAppDispatch } from './hooks';

interface ReturnValue {
  resetStore(): void;
}

export const useResetStore = (): ReturnValue => {
  const dispatch = useAppDispatch();

  const resetStore = (): void => {
    dispatch(resetFilterStore());
    dispatch(resetLayerStore());
    dispatch(resetSelectionStore());
    dispatch(resetSnapshotStore());
    dispatch(resetUserStore());
    dispatch(resetAuthStore());
    dispatch(resetAuthStore());
    dispatch(resetMapStore());
  };

  return {
    resetStore,
  };
};
