import { injectable } from 'inversify';
import 'reflect-metadata';

export interface ILocalStorageService {
  setItem(key: string, value: unknown): void;
  getItem<T>(key: string): T | null;
  removeItem(key: string): void;
}

@injectable()
export class LocalStorageService implements ILocalStorageService {
  getItem<T>(key: string): T | null {
    const item = localStorage.getItem(key);
    return item ? (JSON.parse(item) as T) : null;
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  setItem<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }
}
