import React, { Ref, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { FilterSelector } from './FilterSelector';
import IconButton from '../../../../components/IconButton';

interface Props {
  disabled?: boolean;
}

export const MapFilter: React.FC<Props> = ({ disabled = false }: Props) => {
  const [isActive, setActive] = useState(false);
  const wrapper: Ref<HTMLDivElement> = useRef(null);

  const handleClick = () => {
    setActive(!isActive);
  };

  const handleClickAround = (e: MouseEvent) => {
    if (e.target instanceof Element && !wrapper.current?.contains(e.target)) {
      handleClick();
    }
  };

  useEffect(() => {
    if (isActive) {
      document.addEventListener('click', handleClickAround);

      return () => document.removeEventListener('click', handleClickAround);
    }
  }, [isActive]);

  return (
    <div className="controls-group" ref={wrapper}>
      <IconButton
        buttonClassList={`controls-btn ${isActive && 'active'}`}
        iconClassList="controls-icon icon icon-filter"
        onClickEvent={handleClick}
        testId="button"
        disabled={disabled}
      />
      <div
        data-testid="controls-container"
        className={cn('controls-container', {
          isVisible: isActive,
          isHidden: !isActive,
        })}
      >
        <FilterSelector />
      </div>
    </div>
  );
};
