import { LayerConfig } from '../index';
import { getRoundedFloat, makeUrl } from '../../../helpers/common';
import { API_TILES } from '../../../constants/api';
import { DEFAULT_WW_MAX_ZOOM, DEFAULT_WW_MIN_ZOOM } from '../../../constants/map';
import { FilterType } from '../../../app/modules/filter/types';
import {
  ARTERIAL_ROAD_CLASS_VALUE,
  COLLECTOR_ROAD_CLASS_VALUE,
  LOCAL_ROAD_CLASS_VALUE,
} from '../../../constants/dataParms';
import { filterByRoadClass } from '../../../app/modules/filter/filters';

const PIN_DEFAULT_SIZE = 0.5;

export const slipperinessSimplified: LayerConfig = {
  layerName: 'slipperiness-simplified',
  isLiveLayer: false,
  mapSource: {
    type: 'tiles',
    source: () => ({
      type: 'vector',
      tiles: [makeUrl(API_TILES, { layer: 'slipperiness' })],
      minzoom: DEFAULT_WW_MIN_ZOOM,
    }),
  },
  mapLayers: [
    {
      id: 'slipperiness-simplified',
      type: 'symbol',
      minzoom: DEFAULT_WW_MIN_ZOOM,
      maxzoom: DEFAULT_WW_MAX_ZOOM,
      source: 'slipperiness-simplified',
      'source-layer': 'slipperiness',
      layout: {
        visibility: 'visible',
        'icon-size': PIN_DEFAULT_SIZE,
        'icon-image': 'pinpoint_orange',
      },
    },
  ],
  symbology: {
    title: 'Slippery roads',
    iconClass: 'icon-slippery',
    tooltip: {
      icon: 'icon-slippery',
      heading: 'Slipperiness',
      fieldsCreator: (data) => [
        { title: 'Discovery time:', value: data.dateTime },
        { title: 'Quality[%]:', value: getRoundedFloat(Number(data.quality), 2).toString() },
        { title: 'Road class:', value: data.functional_road_class?.toString() },
        // TODO: uncomment this when will be receive geometry in slipperiness data
        // {
        //   title: 'Segment start',
        //   value: `${getRoundedFloat(Number(data.coords[0][1]), 5)}°N,${getRoundedFloat(
        //     Number(data.coords[0][0]),
        //     5,
        //   )}°E`,
        // },
        // {
        //   title: 'Segment end',
        //   value: `${getRoundedFloat(Number(data.coords[data.coords.length - 1][1]), 5)}°N,${getRoundedFloat(
        //     Number(data.coords[data.coords.length - 1][0]),
        //     5,
        //   )}°E`,
        // },
      ],
    },
  },
  filters: {
    functionalRoadClassFilter: {
      title: 'Functional road class',
      filterName: 'functionalRoadClassFilter',
      filterableValue: 'roadClass',
      filterType: FilterType.BUTTONS,
      defaultState: {
        arterial: true,
        collector: true,
        local: true,
      },
      options: [
        {
          title: 'Arterial',
          name: 'arterial',
          values: [0, ARTERIAL_ROAD_CLASS_VALUE + 1],
        },
        {
          title: 'Collector',
          name: 'collector',
          values: [COLLECTOR_ROAD_CLASS_VALUE, COLLECTOR_ROAD_CLASS_VALUE + 1],
        },
        {
          title: 'Local',
          name: 'local',
          values: [LOCAL_ROAD_CLASS_VALUE, LOCAL_ROAD_CLASS_VALUE + 1],
        },
      ],
      dataFilterFunction: filterByRoadClass,
    },
    slipperinessFilter: {
      title: 'Slippery road discovery age',
      filterName: 'slippery_roads',
      filterableValue: 'lastSampleTimestampMillisecondsUtc',
      filterType: FilterType.RANGE,
      minmax: [0, 168],
      defaultState: {
        slippery_roads: 24,
      },
    },
  },
};
