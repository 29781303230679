import React, { useCallback } from 'react';
import cn from 'classnames';
import { Tooltip, TooltipSide } from 'shared';
import IconButton from '../../../../components/IconButton';
import { useAppSelector } from '../../../app/hooks';
import { selectCustomPolygons, selectIsGlobalUser, selectPolygons, selectUser } from '../../../user/store/store';
import { LayerConfig, layers } from '../../../../../configs/layers';
import {
  selectSelectedAppType,
  selectSelectedLayer,
  selectSelectedPolygonIds,
  selectSelectedReportId,
  selectSelectedSubLayer,
} from '../../store/store';
import { exportDataAsCsv, exportReportAsCsv } from '../../api/api';
import { selectedFiltersSelector } from '../../../filter/store/store';
import { DataType } from '../../../../../classes/AbstractData';
import { filteredDataSelector } from '../../../layers/store/store';

const ExportButton: React.FC = () => {
  const polygons = useAppSelector(selectPolygons);
  const customPolygons = useAppSelector(selectCustomPolygons);
  const user = useAppSelector(selectUser);
  const isGlobalUser = useAppSelector(selectIsGlobalUser);
  const selectedAppType = useAppSelector(selectSelectedAppType);
  const selectedLayer = useAppSelector(selectSelectedLayer);
  const selectedSubLayer = useAppSelector(selectSelectedSubLayer);
  const selectedReportId = useAppSelector(selectSelectedReportId);
  const selectedPolygonIds = useAppSelector(selectSelectedPolygonIds);
  const selectedFilters = useAppSelector(selectedFiltersSelector);

  const filteredData = useAppSelector(filteredDataSelector);

  const onClickSaveData = useCallback(() => {
    if (!selectedAppType || !selectedPolygonIds.length || !selectedLayer || !user) return;
    let layerConfig = layers[selectedLayer] as LayerConfig;

    if (selectedSubLayer && layerConfig.subLayers) {
      layerConfig = layerConfig.subLayers.find((subLayer) => subLayer.layerName === selectedSubLayer) as LayerConfig;
    }

    const isAppTypeReportBased = layerConfig.statisticSource?.type === 'report';

    [...polygons, ...customPolygons].forEach((polygon) => {
      if (!selectedPolygonIds.includes(polygon.id)) return;

      if (isAppTypeReportBased && selectedReportId) {
        exportReportAsCsv(user.title, selectedAppType, polygon.id, polygon.title, selectedReportId, selectedFilters);
      } else if (selectedSubLayer) {
        exportDataAsCsv(
          user.title,
          selectedAppType,
          polygon.id,
          polygon.title,
          selectedSubLayer as DataType,
          selectedFilters,
        );
      }
    });
  }, [polygons, customPolygons, selectedLayer, selectedSubLayer, selectedFilters, selectedReportId, user]);

  return (
    <Tooltip text="Export" side={TooltipSide.BOTTOM}>
      <IconButton
        buttonClassList="header-btn"
        iconClassList={cn('header-btn-icon icon icon-forward', { isLight: filteredData.length })}
        onClickEvent={onClickSaveData}
        disabled={isGlobalUser || !filteredData.length}
        testId="export-btn"
      />
    </Tooltip>
  );
};

export default ExportButton;
