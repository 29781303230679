import { injectable } from 'inversify';
import 'reflect-metadata';
import { socketClient, ISocketClient } from 'shared';
import { LayerName } from '../../user/types';
import { SocketDataUpdateMessage } from '../types';

export interface IDataSubscriptionService {
  subscribe(regionId: number, layer: LayerName, callback: (data: SocketDataUpdateMessage) => void): void;
  unsubscribe(regionId: number, layer: LayerName): void;
}

@injectable()
export class DataSubscriptionService implements IDataSubscriptionService {
  private socketClient: ISocketClient = socketClient;

  subscribe(regionId: number, layer: LayerName, callback: (data: SocketDataUpdateMessage) => void): void {
    const connection = this.socketClient.getConnection();

    if (connection) {
      connection.emit('join', { region_id: regionId, layer });

      connection.on('message', (msg: SocketDataUpdateMessage) => {
        if (!msg.data) return;
        callback(msg);
      });
    }
  }

  unsubscribe(regionId: number, layer: LayerName): void {
    const connection = this.socketClient.getConnection();
    if (connection) {
      connection.emit('leave', { region_id: regionId, layer });
    }
  }
}
