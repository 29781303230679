import { request } from '../../../../helpers/request';
import { REACT_APP_API_GW_ROUTE } from '../../../../constants/api';
import {
  ApiAccountConfig,
  ApiAccountConfigResponse,
  ApiSaveCustomPolygonResponse,
  ApiUserConfig,
  ApiUserConfigResponse,
  ApiUserCustomPolygonsResponse,
  ApiUserPolygon,
  ApiUserPolygonResponse,
  CustomPolygon,
  SaveCustomPolygonData,
} from '../types';
import { getCustomPolygonWKTString } from '../../../../helpers/common';

export const API_USER_CONFIG_SERVICE_URL = `${REACT_APP_API_GW_ROUTE}/user`;
export const API_GEOMETRY_SERVICE_URL = `${REACT_APP_API_GW_ROUTE}/geometry`;

export const API_USER_CONFIG_URL = `${API_USER_CONFIG_SERVICE_URL}/users/`;
export const API_ACCOUNT_CONFIG_URL = `${API_USER_CONFIG_SERVICE_URL}/accounts/`;

export const API_FETCH_POLYGON_URL = `${API_GEOMETRY_SERVICE_URL}/polygons`;
export const API_CUSTOM_POLYGON_URL = `${API_GEOMETRY_SERVICE_URL}/polygons/custom/`;

export async function fetchUser(): Promise<ApiUserConfig> {
  const res = await request.get<ApiUserConfigResponse>(API_USER_CONFIG_URL);

  if (res.data.status !== 'SUCCESS') {
    throw new Error('Unable to fetch user');
  }

  return res.data.user;
}

export async function fetchAccount(): Promise<ApiAccountConfig> {
  const res = await request.get<ApiAccountConfigResponse>(API_ACCOUNT_CONFIG_URL);

  if (res.data.status !== 'SUCCESS') {
    throw new Error('Unable to fetch user');
  }

  return res.data.account;
}

export async function fetchPolygonById(id: number): Promise<ApiUserPolygon> {
  const res = await request.get<ApiUserPolygonResponse>(`${API_FETCH_POLYGON_URL}/?id=${id}`);

  if (res.data.status !== 'SUCCESS') {
    throw new Error('Unable to fetch polygons');
  }

  return res.data.polygon;
}

export async function saveCustomPolygon(data: SaveCustomPolygonData): Promise<CustomPolygon> {
  const res = await request.post<ApiSaveCustomPolygonResponse>(API_CUSTOM_POLYGON_URL, {
    title: data.title,
    polygon: getCustomPolygonWKTString(data.polygon),
    base_polygon_id: data.basePolygonId,
  });

  return res.data.polygon;
}

export async function fetchCustomPolygons(): Promise<CustomPolygon[]> {
  const res = await request.get<ApiUserCustomPolygonsResponse>(API_CUSTOM_POLYGON_URL);

  if (res.data.status !== 'SUCCESS') {
    throw new Error('Unable to fetch polygons');
  }

  return res.data.polygons;
}

export async function deleteCustomPolygonById(id: number): Promise<void> {
  await request.delete(`${API_CUSTOM_POLYGON_URL}?id=${id}`);
}
