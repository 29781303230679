import { GripQuality } from '../../../../classes/AbstractData';
import { FilterFunction } from '../types';
import {
  ARTERIAL_ROAD_CLASS_VALUE,
  COLLECTOR_ROAD_CLASS_VALUE,
  LOCAL_ROAD_CLASS_VALUE,
} from '../../../../constants/dataParms';

export const getGripQuality = (gripValue: number, lowValue: number, highValue: number): GripQuality => {
  if (gripValue < lowValue) {
    return GripQuality.LOW;
  } else if (gripValue >= lowValue && gripValue < highValue) {
    return GripQuality.MEDIUM;
  } else {
    return GripQuality.HIGH;
  }
};

export const filterByGripRating: FilterFunction = (filter, data, lowValue, highValue) => {
  return data.filter((data) => {
    const gripQuality = getGripQuality(data.data.value, lowValue, highValue);

    if (filter[gripQuality]) {
      return true;
    }
  });
};

export const filterByPaser: FilterFunction = (filter, data, lowValue, highValue) => {
  return data.filter((grip) => {
    const gripQuality = getGripQuality(grip.data.value, lowValue, highValue);

    if (filter.poor && gripQuality === GripQuality.LOW) {
      return true;
    } else if (filter.fair && gripQuality === GripQuality.MEDIUM) {
      return true;
    } else if (filter.good && gripQuality === GripQuality.HIGH) {
      return true;
    }
  });
};

export const filterBySeverity: FilterFunction = (filter, data, lowValue, highValue) => {
  return data.filter((grip) => {
    const gripQuality = getGripQuality(grip.data.severity, lowValue, highValue);

    if (filter[gripQuality]) {
      return true;
    }
  });
};

export const filterByPriority: FilterFunction = (filter, data, lowValue, highValue) => {
  return data.filter((grip) => {
    const gripQuality = getGripQuality(grip.data.priority, lowValue, highValue);

    if (filter[gripQuality]) {
      return true;
    }
  });
};

export const filterByRoadClass: FilterFunction = (filter, data) => {
  return data.filter((grip) => {
    if (filter.arterial && grip.data.functional_road_class <= ARTERIAL_ROAD_CLASS_VALUE) {
      return true;
    } else if (filter.collector && grip.data.functional_road_class === COLLECTOR_ROAD_CLASS_VALUE) {
      return true;
    } else if (filter.local && grip.data.functional_road_class >= LOCAL_ROAD_CLASS_VALUE) {
      return true;
    }
  });
};
