import React from 'react';
import { Tooltip, TooltipSide, IconButton } from 'shared';

interface Props {
  enableCustomPolygon: boolean;
  onClickCursor?: () => void;
  onZoomIn?: () => void;
  onZoomOut?: () => void;
  onClickTarget?: () => void;
  onChangeTo3d?: () => void;
}

export const MapControls: React.FC<Props> = (props: Props) => {
  const { onClickCursor, onZoomIn, onZoomOut, onClickTarget, enableCustomPolygon } = props;

  return (
    <div className="controls-btn-container map-controls">
      <Tooltip text="Custom polygon" side={TooltipSide.LEFT}>
        <IconButton
          buttonClassList="controls-btn cursor"
          iconClassList="controls-icon icon icon-cursor"
          onClickEvent={onClickCursor}
          testId="map-cursor"
          disabled={!enableCustomPolygon}
        />
      </Tooltip>
      <Tooltip text="Zoom in/out" side={TooltipSide.LEFT}>
        <div className="controls-solid">
          <IconButton
            buttonClassList="controls-solid-btn"
            iconClassList="controls-icon icon icon-plus"
            onClickEvent={onZoomIn}
            id="map-zoomIn"
            testId="map-zoomIn"
          />
          <IconButton
            buttonClassList="controls-solid-btn"
            iconClassList="controls-icon icon icon-minus"
            onClickEvent={onZoomOut}
            id="map-zoomOut"
            testId="map-zoomOut"
          />
        </div>
      </Tooltip>
      <Tooltip text="Center" side={TooltipSide.LEFT}>
        <IconButton
          buttonClassList="controls-btn"
          iconClassList="controls-icon icon icon-target"
          onClickEvent={onClickTarget}
          id="map-target"
          testId="map-target"
        />
      </Tooltip>
    </div>
  );
};
