import { AppType } from '../app/modules/selection/types';
import { AvailableFilter, FilterFunction, FilterType } from '../app/modules/filter/types';
import {
  ARTERIAL_ROAD_CLASS_VALUE,
  COLLECTOR_ROAD_CLASS_VALUE,
  GRIP_DATA_HIGH_VALUE,
  GRIP_DATA_LOW_VALUE,
  LOCAL_ROAD_CLASS_VALUE,
  PAVEMENT_DATA_HIGH_VALUE,
  PAVEMENT_DATA_LOW_VALUE,
  POTHOLE_DATA_HIGH_VALUE,
  POTHOLE_DATA_LOW_VALUE,
  TREATMENT_PRIORITY_HIGH_VALUE,
  TREATMENT_PRIORITY_LOW_VALUE,
} from './dataParms';
import { GripQuality } from '../classes/AbstractData';

export const getGripQuality = (gripValue: number, lowValue: number, highValue: number): GripQuality => {
  if (gripValue < lowValue) {
    return GripQuality.LOW;
  } else if (gripValue >= lowValue && gripValue < highValue) {
    return GripQuality.MEDIUM;
  } else {
    return GripQuality.HIGH;
  }
};

const filterByGripRating: FilterFunction = (filter, data, lowValue, highValue) => {
  return data.filter((data) => {
    const gripQuality = getGripQuality(data.data.value, lowValue, highValue);

    if (filter[gripQuality]) {
      return true;
    }
  });
};

const filterByPaser: FilterFunction = (filter, data, lowValue, highValue) => {
  return data.filter((grip) => {
    const gripQuality = getGripQuality(grip.data.value, lowValue, highValue);

    if (filter.poor && gripQuality === GripQuality.LOW) {
      return true;
    } else if (filter.fair && gripQuality === GripQuality.MEDIUM) {
      return true;
    } else if (filter.good && gripQuality === GripQuality.HIGH) {
      return true;
    }
  });
};

const filterBySeverity: FilterFunction = (filter, data, lowValue, highValue) => {
  return data.filter((grip) => {
    const gripQuality = getGripQuality(grip.data.severity, lowValue, highValue);

    if (filter[gripQuality]) {
      return true;
    }
  });
};

const filterByPriority: FilterFunction = (filter, data, lowValue, highValue) => {
  return data.filter((grip) => {
    const gripQuality = getGripQuality(grip.data.priority, lowValue, highValue);

    if (filter[gripQuality]) {
      return true;
    }
  });
};

const filterByRoadClass: FilterFunction = (filter, data) => {
  return data.filter((grip) => {
    if (filter.arterial && grip.data.functional_road_class <= ARTERIAL_ROAD_CLASS_VALUE) {
      return true;
    } else if (filter.collector && grip.data.functional_road_class === COLLECTOR_ROAD_CLASS_VALUE) {
      return true;
    } else if (filter.local && grip.data.functional_road_class >= LOCAL_ROAD_CLASS_VALUE) {
      return true;
    }
  });
};

export const filterSelectionsToAppType: AvailableFilter = {
  [AppType.GRIP]: {
    gripFilter: {
      title: 'Grip rating',
      filterType: FilterType.BUTTONS,
      state: {
        high: true,
        medium: true,
        low: true,
      },
      supportedLayers: ['baseline', 'latest', 'ground_truth'],
      filterableValue: 'value',
      filterName: 'grip',
      optionValues: {
        high: [GRIP_DATA_HIGH_VALUE, 999],
        medium: [GRIP_DATA_LOW_VALUE, GRIP_DATA_HIGH_VALUE],
        low: [0, GRIP_DATA_LOW_VALUE],
      },
      function: filterByGripRating,
    },
    functionalRoadClassFilter: {
      title: 'Functional road class',
      filterType: FilterType.BUTTONS,
      state: {
        arterial: true,
        collector: true,
        local: true,
      },
      filterableValue: 'roadClass',
      filterName: 'road_class',
      optionValues: {
        arterial: [0, ARTERIAL_ROAD_CLASS_VALUE + 1],
        collector: [COLLECTOR_ROAD_CLASS_VALUE, COLLECTOR_ROAD_CLASS_VALUE + 1],
        local: [LOCAL_ROAD_CLASS_VALUE, LOCAL_ROAD_CLASS_VALUE + 1],
      },
      function: filterByRoadClass,
    },
    geometryFilter: {
      filterType: FilterType.HIDDEN,
      state: {
        filter: true,
      },
    },
    slipperinessFilter: {
      title: 'Slippery road discovery age',
      extra: true,
      filterType: FilterType.RANGE,
      state: {
        filter: true,
      },
      filterableValue: 'lastSampleTimestampMillisecondsUtc',
      optionValues: {
        minmax: [0, 36],
      },
    },
  },
  [AppType.PAVEMENT]: {
    paserFilter: {
      title: 'PASER rating',
      filterType: FilterType.BUTTONS,
      state: {
        good: true,
        fair: true,
        poor: true,
      },
      supportedLayers: ['pavement'],
      filterableValue: 'value',
      filterName: 'paser',
      optionValues: {
        good: [PAVEMENT_DATA_HIGH_VALUE, 999],
        fair: [PAVEMENT_DATA_LOW_VALUE, PAVEMENT_DATA_HIGH_VALUE],
        poor: [0, PAVEMENT_DATA_LOW_VALUE],
      },
      function: filterByPaser,
    },
    functionalRoadClassFilter: {
      title: 'Functional road class',
      filterType: FilterType.BUTTONS,
      state: {
        arterial: true,
        collector: true,
        local: true,
      },
      supportedLayers: ['pavement'],
      filterableValue: 'roadClass',
      filterName: 'road_class',
      optionValues: {
        arterial: [0, ARTERIAL_ROAD_CLASS_VALUE + 1],
        collector: [COLLECTOR_ROAD_CLASS_VALUE, COLLECTOR_ROAD_CLASS_VALUE + 1],
        local: [LOCAL_ROAD_CLASS_VALUE, LOCAL_ROAD_CLASS_VALUE + 1],
      },
      function: filterByRoadClass,
    },
    geometryFilter: {
      filterType: FilterType.HIDDEN,
      state: {
        filter: true,
      },
    },
  },
  [AppType.POTHOLE]: {
    functionalRoadClassFilter: {
      title: 'Functional road class',
      filterType: FilterType.BUTTONS,
      state: {
        arterial: true,
        collector: true,
        local: true,
      },
      supportedLayers: ['pothole'],
      filterableValue: 'roadClass',
      filterName: 'road_class',
      optionValues: {
        arterial: [0, ARTERIAL_ROAD_CLASS_VALUE + 1],
        collector: [COLLECTOR_ROAD_CLASS_VALUE, COLLECTOR_ROAD_CLASS_VALUE + 1],
        local: [LOCAL_ROAD_CLASS_VALUE, LOCAL_ROAD_CLASS_VALUE + 1],
      },
      function: filterByRoadClass,
    },
    potholeSeverityFilter: {
      title: 'Pothole severity',
      filterType: FilterType.BUTTONS,
      state: {
        low: true,
        medium: true,
        high: true,
      },
      supportedLayers: ['pothole'],
      filterableValue: 'severity',
      filterName: 'pothole_severity',
      optionValues: {
        high: [POTHOLE_DATA_HIGH_VALUE, 999],
        medium: [POTHOLE_DATA_LOW_VALUE, POTHOLE_DATA_HIGH_VALUE],
        low: [0, POTHOLE_DATA_LOW_VALUE],
      },
      function: filterBySeverity,
    },
    treatmentPriorityFilter: {
      title: 'Treatment priority',
      filterType: FilterType.BUTTONS,
      state: {
        low: true,
        medium: true,
        high: true,
      },
      supportedLayers: ['pothole'],
      filterableValue: 'priority',
      filterName: 'treatment_priority',
      optionValues: {
        high: [TREATMENT_PRIORITY_HIGH_VALUE, 999],
        medium: [TREATMENT_PRIORITY_LOW_VALUE, TREATMENT_PRIORITY_HIGH_VALUE],
        low: [0, TREATMENT_PRIORITY_LOW_VALUE],
      },
      function: filterByPriority,
    },
    geometryFilter: {
      filterType: FilterType.HIDDEN,
      state: {
        filter: true,
      },
    },
  },
  [AppType.SURFACE_EVENTS]: {},
};
