import { SelectedFilters } from '../types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

interface FilterState {
  selectedFilters: SelectedFilters;
}

export const initialState: FilterState = {
  selectedFilters: {},
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setSelectedFilters: (state, action: PayloadAction<SelectedFilters>) => {
      state.selectedFilters = action.payload;
    },
    setFilterOption: (
      state,
      action: PayloadAction<{ filterName: string; filterOption: string; value: boolean | number }>,
    ) => {
      if (state.selectedFilters[action.payload.filterName]) {
        state.selectedFilters[action.payload.filterName][action.payload.filterOption] = action.payload.value;
      }
    },
    resetStore: () => {
      return initialState;
    },
  },
});

export const { setSelectedFilters, setFilterOption, resetStore } = filterSlice.actions;

export function selectedFiltersSelector(state: RootState): SelectedFilters {
  return state.filter.selectedFilters;
}

export default filterSlice.reducer;
