import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { LoggingService } from 'shared';
import {
  DEFAULT_3D_MAX_ZOOM,
  DEFAULT_3D_PITCH,
  DEFAULT_MAX_ZOOM,
  DEFAULT_BEARING,
  DEFAULT_PITCH,
  DEFAULT_ZOOM,
} from '../../../constants/map';
import { MapCoords, MapViewPort } from './types';
import { Polygon, Position } from '@turf/turf';
import { getCenterOfPolygon, getCustomPolygonWKTString } from '../../../helpers/common';
import { injectable } from 'inversify';
import 'reflect-metadata';

export interface IMapService {
  setMap(map: mapboxgl.Map): void;
  getMap(): mapboxgl.Map | null | undefined;
  enableMapControls(): void;
  getMapCoordinates(): MapViewPort | null;
  getIsDrawingEnable(): boolean;
  changeZoom(isIncrease: boolean): void;
  changeTo3d(): void;
  hideNonRelevantContainers(element: HTMLElement): void;
  navigate(coords: MapCoords): void;
  startCustomPolygonSelection(): void;
  removePolygonDrawing(): void;
  addPolygonDrawing(): void;
  restoreMapDefaults(coords: number[][]): void;
  getCurrentScale(mapElement: HTMLElement | null): string;
  getCurrentScaleWidth(mapElement: HTMLDivElement | null): number;
  getCoordinates(): MapViewPort | null;
  getBBox(): mapboxgl.LngLatBounds | null;
  getCustomPolygon(): Polygon | undefined;
  showPin(id: string, position: Position, dimTime?: number): void;
}

@injectable()
class MapService implements IMapService {
  private map: mapboxgl.Map | null;
  private drawingControl: MapboxDraw | null;
  private isStyleIn3d = false;
  private isDuringCustomSelection = false;
  private referenceToUpdateCustomPolygon: () => void;
  private pinAnimationID: number;

  public setMap(map: mapboxgl.Map): void {
    this.map = map;
  }

  public getMap(): mapboxgl.Map | null | undefined {
    return this.map;
  }

  public enableMapControls(): void {
    if (!this.map) return;

    this.map.keyboard.enable();
    this.map.keyboard.enableRotation();
    this.map['boxZoom'].enable();
  }

  public startCustomPolygonSelection(): void {
    if (this.isDuringCustomSelection) {
      this.removePolygonDrawing();
    } else {
      this.addPolygonDrawing();
    }
  }

  public addPolygonDrawing(): void {
    if (!this.map || this.drawingControl) return;

    const drawingControl = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
      },
      defaultMode: 'draw_polygon',
    });

    this.drawingControl = drawingControl;
    this.map.addControl(drawingControl);
    this.isDuringCustomSelection = true;

    if (this.referenceToUpdateCustomPolygon) {
      this.map.off('draw.create', this.referenceToUpdateCustomPolygon);
      this.map.off('draw.delete', this.referenceToUpdateCustomPolygon);
      this.map.off('draw.update', this.referenceToUpdateCustomPolygon);
    }

    this.referenceToUpdateCustomPolygon = this.updateCustomPolygon.bind(this);
    this.map.on('draw.create', this.referenceToUpdateCustomPolygon);
    this.map.on('draw.delete', this.referenceToUpdateCustomPolygon);
    this.map.on('draw.update', this.referenceToUpdateCustomPolygon);
  }

  private updateCustomPolygon(): void {
    const geometry = this.drawingControl?.getAll().features[0].geometry as Polygon;
    const polygonString = getCustomPolygonWKTString(geometry?.coordinates);
    LoggingService.log('[DEBUG]: Selected polygon: ', polygonString);
  }

  public removePolygonDrawing(): void {
    if (!this.map || !this.drawingControl) return;
    this.map.removeControl(this.drawingControl);
    this.drawingControl = null;
    this.isDuringCustomSelection = false;
  }

  public getMapCoordinates(): MapViewPort | null {
    if (!this.map) return null;

    const lng = parseFloat(this.map.getCenter().lng.toFixed(4));
    const lat = parseFloat(this.map.getCenter().lat.toFixed(4));
    const zoom = parseFloat(this.map.getZoom().toFixed(2));
    const pitch = parseFloat(this.map.getPitch().toFixed(2));
    return {
      lng,
      lat,
      zoom,
      pitch,
    };
  }

  public getIsDrawingEnable(): boolean {
    return !!this.drawingControl && this.drawingControl.getMode() === 'draw_polygon';
  }

  public changeZoom(isIncrease: boolean): void {
    if (!this.map) return;

    const currentZoom = this.map.getZoom();
    if (!currentZoom) {
      return;
    }

    this.map.flyTo({
      center: this.map.getCenter(),
      zoom: isIncrease ? currentZoom + 1 : currentZoom - 1,
    });
  }

  public changeTo3d(): void {
    if (!this.map) return;
    let newPitch: number;

    if (this.isStyleIn3d) {
      newPitch = DEFAULT_PITCH;
      this.map.setMaxZoom(DEFAULT_MAX_ZOOM);
    } else {
      newPitch = DEFAULT_3D_PITCH;
      this.map.setMaxZoom(DEFAULT_3D_MAX_ZOOM);
    }

    this.map.flyTo({
      center: this.map.getCenter(),
      pitch: newPitch,
    });

    this.isStyleIn3d = !this.isStyleIn3d;
  }

  public hideNonRelevantContainers(mapElement: HTMLElement | null): void {
    if (!mapElement) {
      return;
    }

    const containers = [];
    const scale = mapElement.querySelector('.mapboxgl-ctrl-scale');

    containers.push(mapElement.querySelector('.mapboxgl-ctrl-logo'));
    containers.push(mapElement.querySelector('.mapboxgl-ctrl-attrib-button'));

    scale?.parentElement?.style?.setProperty('visibility', 'hidden');
    containers.forEach((container) => {
      if (container) {
        container.parentElement?.parentElement?.remove();
      }
    });
  }

  public navigate(coords: MapCoords): void {
    if (!this.map) return;
    this.map.flyTo({
      center: coords,
      zoom: DEFAULT_ZOOM,
    });
  }

  public restoreMapDefaults(coords: number[][]): void {
    const [lng, lat] = getCenterOfPolygon(coords);
    this.removePolygonDrawing();
    this.map?.setPitch(DEFAULT_PITCH);
    this.map?.setZoom(DEFAULT_ZOOM);
    this.map?.setBearing(DEFAULT_BEARING);
    this.navigate({ lng, lat });
  }

  public getCurrentScale(mapElement: HTMLDivElement | null): string {
    const value = mapElement?.querySelector('.mapboxgl-ctrl-scale')?.textContent;

    return value ? value : '';
  }

  public getCurrentScaleWidth(mapElement: HTMLDivElement | null): number {
    const value = mapElement?.querySelector('.mapboxgl-ctrl-scale')?.clientWidth;

    return value ? value : 0;
  }

  public getCoordinates(): MapViewPort | null {
    if (!this.map) {
      return null;
    }

    const lng = parseFloat(this.map.getCenter().lng.toFixed(4));
    const lat = parseFloat(this.map.getCenter().lat.toFixed(4));
    const zoom = parseFloat(this.map.getZoom().toFixed(2));
    const pitch = parseFloat(this.map.getPitch().toFixed(2));
    return {
      lng,
      lat,
      zoom,
      pitch,
    };
  }

  public getBBox(): mapboxgl.LngLatBounds | null {
    if (!this.map) {
      return null;
    }

    return this.map.getBounds();
  }

  public getCustomPolygon(): Polygon | undefined {
    return this.drawingControl?.getAll().features[0]?.geometry as Polygon;
  }

  public showPin(id: string, position: Position, dimTime?: number): void {
    if (!this.map) return;

    cancelAnimationFrame(this.pinAnimationID);

    const layer = this.map.getLayer(id);

    if (layer) {
      this.map.removeLayer(id);
      this.map.removeSource(id);
    }

    this.map.addLayer({
      id,
      type: 'symbol',
      source: {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: position,
          },
          properties: {},
        },
      },
      layout: {
        visibility: 'visible',
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
        'icon-image': 'pinpoint_orange',
        'icon-anchor': 'bottom',
        'icon-size': 0.5,
      },
      paint: {
        'icon-opacity': 1,
      },
    });

    if (dimTime) this.dimPin(id, dimTime, Date.now(), this.map);
  }

  private dimPin(id: string, dimTime: number, startTime: number, map: mapboxgl.Map): void {
    const layerExists = map.getLayer(id);

    if (layerExists) {
      const opacity = 1 - (Date.now() - startTime) / (dimTime * 1000);

      if (opacity > 0) {
        this.pinAnimationID = requestAnimationFrame(() => {
          map.setPaintProperty(id, 'icon-opacity', opacity);

          this.dimPin(id, dimTime, startTime, map);
        });
      } else {
        map.removeLayer(id);
        map.removeSource(id);
      }
    }
  }
}

export default MapService;
