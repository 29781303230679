import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { resetAuthError, selectAuthState } from '../store/store';
import { useHistory } from 'react-router-dom';
import { login } from '../store/thunks';
import { MAP_PAGE_PATH } from '../../app/components/Routing';
import { ILocalStorageService } from '../../../services/LocalStorageService';
import { container } from '../../../../configs/inversify';
import { UserAuthState } from '../types';

interface LoginFields {
  username: string;
  password: string;
  saveCredentials: boolean;
}

interface LoginFieldErrors {
  username: string;
  password: string;
}

const initialForm: LoginFields = {
  username: '',
  password: '',
  saveCredentials: false,
};

const initialErrors: LoginFieldErrors = {
  username: '',
  password: '',
};

const LOCAL_STORAGE_LOGIN_FIELDS = 'login-credentials';

const localStorageService: ILocalStorageService = container.get('LocalStorageService');

interface ReturnValue {
  form: LoginFields;
  errors: LoginFieldErrors;
  loginState: UserAuthState;
  onChangeForm(field: string, value: string | boolean): void;
  onSubmit(): void;
  onCancelError(): void;
}

export const useLogin = (): ReturnValue => {
  const [form, setForm] = useState<LoginFields>(initialForm);
  const [errors, setErrors] = useState<LoginFieldErrors>(initialErrors);

  const loginState = useAppSelector(selectAuthState);

  const dispatch = useAppDispatch();

  const history = useHistory();

  useEffect(() => {
    const savedFields = localStorageService.getItem<LoginFields>(LOCAL_STORAGE_LOGIN_FIELDS);

    if (savedFields) {
      setForm({
        ...savedFields,
        saveCredentials: true,
      });
    }
  }, []);

  useEffect(() => {
    if (loginState === 'FAILED_LOGGING_IN') {
      setErrors({
        username: 'Field is not valid',
        password: 'Field is not valid',
      });
    }

    if (loginState === 'LOGGED_IN') {
      history.push(MAP_PAGE_PATH);
    }
  }, [loginState]);

  const onChangeForm = (field: string, value: string | boolean): void => {
    setErrors((prev) => ({
      ...prev,
      [field]: '',
    }));

    setForm((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const onSubmit = (): void => {
    if (!validateFields(form)) {
      setErrors({
        username: 'Field is not valid',
        password: 'Field is not valid',
      });
      return;
    }

    dispatch(
      login({
        username: form.username,
        password: form.password,
        saveCredentials: form.saveCredentials,
      }),
    );
  };

  const validateFields = (form: LoginFields): boolean => {
    const { username, password } = form;
    let isUsernameError = false;
    let isPasswordError = false;

    if (username.trim() === '') {
      isUsernameError = true;
    }

    if (password.trim() === '') {
      isPasswordError = true;
    }

    return !isPasswordError && !isUsernameError;
  };

  const onCancelError = (): void => {
    dispatch(resetAuthError());
  };

  return {
    form,
    errors,
    loginState,
    onChangeForm,
    onSubmit,
    onCancelError,
  };
};
