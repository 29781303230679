import { AbstractData, DataType } from './AbstractData';
import { LatestData } from './LatestData';
import { GroundTruthData } from './GroundTruthData';
import { PavementData } from './PavementData';
import { DATA_FACTORY_INCORRECT_SUBTYPE, DATA_FACTORY_INCORRECT_TYPE } from '../constants/errors';
import { PotholeData } from './PotholeData';
import { Data } from '../app/modules/layers/types';
import { StorableFilters } from '../app/modules/filter/types';
import { LayerName } from '../app/modules/user/types';

export class DataClassFactory {
  static getDataClass(
    data: Data[],
    layer: LayerName,
    subtype: string | null,
    filters: StorableFilters,
  ): AbstractData | never {
    if (layer === LayerName.GRIP) {
      switch (subtype) {
        case DataType.LATEST:
          return new LatestData(data, filters, true);
        case DataType.GROUND_TRUTH:
          return new GroundTruthData(data, filters, true);
        default:
          throw Error(DATA_FACTORY_INCORRECT_SUBTYPE);
      }
    } else if (layer === LayerName.PAVEMENT) {
      return new PavementData(data, filters, true);
    } else if (layer === LayerName.POTHOLE) {
      return new PotholeData(data, filters, true);
    }

    throw Error(DATA_FACTORY_INCORRECT_TYPE);
  }
}
