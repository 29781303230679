export interface MapCoords {
  lat: number;
  lng: number;
}

export interface MapViewPort extends MapCoords {
  zoom: number;
  pitch: number;
}

import { Data } from '../layers/types';

export interface SearchingArea {
  minCoordinates: number[];
  maxCoordinates: number[];
}

export type SortedData = Record<string, Data[]>;

export interface SortedByGripRating extends SortedData {
  high: Data[];
  medium: Data[];
  low: Data[];
}

export interface SortedByFunctionalRoadClass extends SortedData {
  arterial: Data[];
  collector: Data[];
  local: Data[];
}

export interface SortedGrips {
  gripRating: SortedByGripRating;
  functionalRoadClassRating: {
    high: SortedByFunctionalRoadClass;
    medium: SortedByFunctionalRoadClass;
    low: SortedByFunctionalRoadClass;
  };
}

export interface GripRatingOption {
  title: string;
  length: number;
  value: number;
}

export interface GripsRating {
  high: GripRatingOption;
  medium: GripRatingOption;
  low: GripRatingOption;
}

export interface FunctionalRoadClassRating {
  length: number;
  high: number;
  medium: number;
  low: number;
}

export enum RoadType {
  ARTERIAL = 'arterial',
  COLLECTOR = 'collector',
  LOCAL = 'local',
}

export interface DataSeperationOptions<T> {
  high: T;
  medium: T;
  low: T;
}

export enum Units {
  METRIC = 'metric',
  IMPERIAL = 'imperial',
}

export const unitName = {
  [Units.METRIC]: 'km',
  [Units.IMPERIAL]: 'mi',
};
