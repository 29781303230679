import React, { ChangeEvent, useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import cn from 'classnames';
import { SnapshotSelect } from './SnapshotSelect';
import { SnapshotAreas } from './SnapshotAreas';
import { LayerConfig, layers } from '../../../../../configs/layers';
import { useSnapshot } from '../../useSnapshot';
import { useAppSelector } from '../../../app/hooks';
import { selectSelectedLayer, selectSelectedSubLayer } from '../../../selection/store/store';
import { selectPolygons } from '../../../user/store/store';
import { LayerName } from '../../../user/types';
import './styles.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const layerOptions = [
  {
    title: 'Grip',
    value: 'current-grip',
    icon: 'icon-wheel',
  },
  {
    title: 'Slipperiness',
    value: 'slipperiness',
    icon: 'icon-slippery',
  },
];

export const SnapshotSave: React.FC<Props> = ({ isOpen, onClose }: Props) => {
  const [name, setName] = useState<string>('');
  const [layerName, setLayerName] = useState<string | undefined>(undefined);
  const [regionId, setRegionId] = useState<number | undefined>(undefined);
  const [thumbnail, setThumbnail] = useState<Blob | undefined>(undefined);

  const selectedLayer = useAppSelector(selectSelectedLayer);
  const selectedSubLayer = useAppSelector(selectSelectedSubLayer);
  const polygons = useAppSelector(selectPolygons);

  const { saveSnapshot } = useSnapshot();

  useEffect(() => {
    setName(generateDefaultSnapshotName());
    setLayerName(layerOptions[0].value);

    if (isOpen) {
      if (!thumbnail) {
        html2canvas(document.querySelector('#root') as HTMLDivElement).then((canvas) => {
          canvas.toBlob(
            (blob) => {
              if (blob) {
                setThumbnail(blob);
              }
            },
            'image/jpeg',
            1,
          );
        });
      }
    } else {
      setThumbnail(undefined);
    }
  }, [isOpen, selectedLayer]);

  const generateDefaultSnapshotName = (): string => {
    const layerConfig = getLayerConfig();

    if (!layerConfig) {
      return '';
    }

    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const fullMonth = month.toString().length === 1 ? `0${month}` : `${month}`;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const fullMinutes = minutes.toString().length === 1 ? `0${minutes}` : `${minutes}`;

    const dateTime = `${day}${fullMonth}${year}_${hours}${fullMinutes}`;

    return layerConfig.symbology.saveSnapshotLayerName
      ? `${layerConfig.symbology.saveSnapshotLayerName}_${dateTime}`
      : '';
  };

  const getLayerConfig = (): LayerConfig | null => {
    if (!selectedLayer) {
      return null;
    }

    const layerConfig = layers[selectedLayer] as LayerConfig;

    if (selectedSubLayer && layerConfig.subLayers) {
      const subLayerConfig = layerConfig.subLayers.find(
        (subLayerConfig) => subLayerConfig.layerName === selectedSubLayer,
      );

      if (!subLayerConfig) {
        return null;
      }

      return subLayerConfig;
    }

    return layerConfig;
  };

  const togglePolygonId = (id: number) => {
    setRegionId((prev) => {
      return prev === id ? undefined : id;
    });
  };

  const onClickSave = async () => {
    if (name.trim() && regionId && layerName && thumbnail) {
      saveSnapshot({
        layerName,
        regionId,
        name,
        thumbnail,
      });
      onClose();
    }
  };

  const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleChangeLayer = (layerName: string): void => {
    setLayerName(layerName as LayerName);
  };

  return (
    <>
      {isOpen && thumbnail && (
        <div className="sidebar">
          <button className="sidebar-close" onClick={onClose}>
            <i className="sidebar-icon icon icon-cancel" />
          </button>
          <div className="sidebar-container">
            <div className="sidebar-header">
              <h2 className="sidebar-title">Save Snapshot</h2>
              <h3 className="sidebar-subtitle">Select a snapshot from the list below</h3>
            </div>
            <div className="sidebar-form">
              <label htmlFor="snapName" className="sidebar-label">
                Snapshot Name:
              </label>
              <input type="text" id="snapName" className="sidebar-input" value={name} onChange={handleChangeTitle} />
            </div>
            <ul className="sidebar-tab-list">
              {layerOptions.map((option) => (
                <li
                  className={cn('sidebar-tab', { active: option.value === layerName })}
                  onClick={handleChangeLayer.bind(this, option.value)}
                  key={option.value}
                >
                  <i className={`sidebar-tab-icon icon ${option.icon} isLight`} />
                  {option.title}
                </li>
              ))}
            </ul>
          </div>
          <div className="sidebar-cards">
            <span className="sidebar-cards-title">Preview</span>
            <img src={URL.createObjectURL(thumbnail)} alt="Preview" className="sidebar-preview" />
            <span className="sidebar-cards-title">Include</span>
            {/*TODO: uncomment if in future layers selection will be needed*/}
            {/*<SnapshotSelect isSelected={!!selectedLayer} isSelectedAll={false}>*/}
            {/*  <SnapshotLayers layers={[]} selectedLayer={form.layer as unknown as DataType} toggleLayer={toggleLayer} />*/}
            {/*</SnapshotSelect>*/}
            <SnapshotSelect title={`Areas (${polygons.length})`} isSelected={!!regionId} isSelectedAll={false}>
              <SnapshotAreas
                polygons={polygons}
                selectedPolygonId={regionId || null}
                togglePolygonId={togglePolygonId}
              />
            </SnapshotSelect>
          </div>
          <div className="sidebar-footer">
            <button className="sidebar-footer-btn" onClick={onClose}>
              Cancel
            </button>
            <button className="sidebar-footer-btn main" onClick={onClickSave}>
              Save Snapshot
            </button>
          </div>
        </div>
      )}
    </>
  );
};
