import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';
import { LocalStorageService } from '../../services/LocalStorageService';
import UserReducer, { UserState } from '../user/store/store';
import DataReducer from '../layers/store/store';
import SelectionReducer from '../selection/store/store';
import FilterReducer from '../filter/store/store';
import SnapshotReducer from '../snapshot/store/store';
import MapReducer from '../map/store/store';
import AuthReducer, { AuthState } from '../auth/store/store';

// Change store version if you change fields in saved slices
export const STORE_VERSION = 1;
export const LOCAL_STORAGE_STORE_VERSION_FIELD = 'store-version';
export const LOCAL_STORAGE_STATE_FIELD = 'state';

const localStorageService = new LocalStorageService();

export interface SavedState {
  auth: AuthState;
  user: UserState;
}

export const getSavedState = (): SavedState | null => {
  const storeVersion = localStorageService.getItem<number>(LOCAL_STORAGE_STORE_VERSION_FIELD);

  if (!storeVersion || storeVersion !== STORE_VERSION) {
    return null;
  }

  return localStorageService.getItem(LOCAL_STORAGE_STATE_FIELD) || null;
};

const rootReducer = combineReducers({
  auth: AuthReducer,
  user: UserReducer,
  selection: SelectionReducer,
  data: DataReducer,
  filter: FilterReducer,
  snapshot: SnapshotReducer,
  map: MapReducer,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
}

const store = setupStore(getSavedState() || {});

store.subscribe(() => {
  const state = store.getState();

  localStorageService.setItem<SavedState>(LOCAL_STORAGE_STATE_FIELD, {
    auth: state.auth,
    user: state.user,
  });

  localStorageService.setItem(LOCAL_STORAGE_STORE_VERSION_FIELD, STORE_VERSION);
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = typeof store.dispatch;

export default store;
