import React, { useEffect } from 'react';
import { ErrorModal } from 'shared';
import mapboxgl from 'mapbox-gl';
import { GlobalControls } from '../GlobalControls';
import Header from '../Header';
import { IMapService } from '../../MapService';
import { Analytics } from '../../../statistic/components/Analytics';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectUserId } from '../../../auth/store/store';
import { fetchUser } from '../../../user/store/thunks';
import Map from '../Map';
import { errorsSelector, setErrors } from '../../../layers/store/store';
import SnapshotStatusIndicator from '../../../snapshot/components/SnapshotStatusIndicator';
import { useUser } from '../../../user/hooks/useUser';
import { container } from '../../../../../configs/inversify';

const mapService: IMapService = container.get('MapService');

const MapPage: React.FC = () => {
  const userId = useAppSelector(selectUserId);
  const errors = useAppSelector(errorsSelector);

  const dispatch = useAppDispatch();

  useUser();

  useEffect(() => {
    if (userId) {
      dispatch(fetchUser());
    }
  }, [userId]);

  const clearErrors = (): void => {
    dispatch(setErrors([]));
  };

  const onMapLoad = (map: mapboxgl.Map): void => {
    mapService.setMap(map);
  };

  return (
    <div className="map-page">
      <Header />
      <SnapshotStatusIndicator />
      <Analytics mapService={mapService} />
      <div>
        <Map onLoad={onMapLoad} />
      </div>
      <GlobalControls mapService={mapService} />
      <ErrorModal errors={errors} onOkay={clearErrors} />
    </div>
  );
};

export default MapPage;
