import { useRef } from 'react';
import { Data } from '../../layers/types';
import worker from '../geometryFilterWorker';
import { splitArrayOnParts } from '../../../../helpers/common';
import { CustomPick } from '../types';
import { PolygonPrototype } from '../../selection/types';

interface ReturnValue {
  activeWorkers: Worker[];
  runWorkers: (
    data: Data[],
    polygonData: CustomPick<PolygonPrototype, 'id' | 'polygon'>[],
    onMessage: (e: MessageEvent) => void,
  ) => void;
}

interface Props {
  availableThreads: number;
}

export const useWorkers = (props: Props): ReturnValue => {
  const { availableThreads } = props;
  const activeWorkers = useRef<Worker[]>([]);

  const runWorkers = (
    data: Data[],
    polygonData: CustomPick<PolygonPrototype, 'id' | 'polygon'>[],
    onMessage: (e: MessageEvent) => void,
  ): void => {
    if (activeWorkers.current.length) {
      activeWorkers.current.forEach((worker) => {
        worker.terminate();
      });

      activeWorkers.current = [];
    }

    for (let i = 1; i <= availableThreads; i++) {
      activeWorkers.current.push(new Worker(worker));
    }

    activeWorkers.current.forEach((worker) => {
      worker.onmessage = onMessage;
    });

    const dataParts = splitArrayOnParts(data, availableThreads);

    activeWorkers.current.forEach((worker, index) => {
      worker.postMessage(JSON.stringify([dataParts[index], polygonData]));
    });
  };

  return {
    activeWorkers: activeWorkers.current,
    runWorkers,
  };
};
