import { createAsyncThunk } from '@reduxjs/toolkit';
import { DataType } from '../../../../classes/AbstractData';
import { DATA_SEGMENTS_PER_PAGE } from '../../../../constants/dataParms';
import { RootState } from '../../app/store';
import { Data, FetchDataPageParams, FetchReportDataResponse } from '../types';
import * as LayerApi from '../api/api';

export interface FetchDataProps {
  regionId: number;
  dataType: DataType;
}

export const fetchData = createAsyncThunk(
  'data/fetchData',
  async ({ regionId, dataType }: FetchDataProps, thunkApi) => {
    let comparingData: Data[] = [];

    if (dataType === DataType.GROUND_TRUTH) {
      const state = thunkApi.getState() as RootState;

      comparingData = state.data.comparingData;

      if (!comparingData.length) {
        comparingData = await LayerApi.fetchDataByPolygonId(regionId, DataType.LATEST);
      }
    }

    const polygonData = await LayerApi.fetchDataByPolygonId(regionId, dataType);

    return { dataType, data: polygonData, comparingData };
  },
);

export interface FetchReportDataProps {
  polygonId: number;
  layer: DataType;
  reportId: string;
}

export const fetchReportData = createAsyncThunk(
  'data/fetchReportData',
  async ({ polygonId, layer, reportId }: FetchReportDataProps, thunkAPI) => {
    let nextDataExists = true;
    let next = '';

    while (nextDataExists) {
      try {
        const res = await thunkAPI.dispatch(fetchReportDataPage({ polygonId, layer, reportId, next }));

        const { meta } = res.payload as FetchReportDataResponse;

        if (meta) {
          nextDataExists = !!meta.next;
          next = `${meta.next}`;
        } else {
          nextDataExists = false;
          next = '';
        }
      } catch (error) {
        return;
      }
    }
  },
);

interface FetchReportDataPageProps extends Omit<FetchReportDataProps, 'polygonsIdList'> {
  next: string;
  start?: number;
  limit?: number;
}

export const fetchReportDataPage = createAsyncThunk(
  'data/fetchReportDataPage',
  async ({ polygonId, layer, reportId, start = 1, limit = DATA_SEGMENTS_PER_PAGE, next }: FetchReportDataPageProps) => {
    try {
      return await LayerApi.fetchReportData(layer, polygonId, reportId, start, limit, next);
    } catch (error) {
      throw error;
    }
  },
);

export const getDataPage = createAsyncThunk('data/getDataPage', async (params: FetchDataPageParams) => {
  return await LayerApi.fetchDataPage(
    params.polygonId,
    params.page,
    params.mainSort,
    params.secSort,
    params.layerName,
    params.reportId,
  );
});
