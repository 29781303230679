import dayjs from 'dayjs';
import { LayerConfig } from '../index';
import { getRoundedFloat, makeUrl } from '../../../helpers/common';
import { API_TILES } from '../../../constants/api';
import { DEFAULT_MAX_ZOOM, DEFAULT_MIN_ZOOM, DEFAULT_WW_MIN_ZOOM } from '../../../constants/map';
import {
  ARTERIAL_ROAD_CLASS_VALUE,
  COLLECTOR_ROAD_CLASS_VALUE,
  GRIP_DATA_HIGH_VALUE,
  GRIP_DATA_LOW_VALUE,
  LOCAL_ROAD_CLASS_VALUE,
} from '../../../constants/dataParms';
import { FilterType } from '../../../app/modules/filter/types';
import { filterByGripRating, filterByRoadClass } from '../../../app/modules/filter/filters';

const MIN_TILES_ZOOM = 1;
const MAX_TILES_ZOOM = 14;

const MIN_SUBTILES_ZOOM = 1;
const MAX_SUBTILES_ZOOM = 5 + 0.99;

const MIN_SUBSEGMENT_BUCKETS_ZOOM = 6;
const MAX_SUBSEGMENT_BUCKETS_ZOOM = 12 + 0.99;

const MIN_SUBSEGMENTS_ZOOM = 13;
const MAX_SUBSEGMENTS_ZOOM = 16 + 1;

export const currentGripSimplified: LayerConfig = {
  layerName: 'current-grip-simplified',
  isLiveLayer: true,
  hasSnapshots: true,
  mapSource: {
    type: 'tiles',
    source: (params?: Record<string, string>) => ({
      type: 'vector',
      tiles: [makeUrl(API_TILES, { layer: 'current-grip' }, params)],
      minzoom: MIN_TILES_ZOOM,
      maxzoom: MAX_TILES_ZOOM,
    }),
  },
  mapLayers: [
    {
      id: 'current-grip-segments',
      source: 'current-grip-simplified',
      'source-layer': 'subsegments',
      type: 'line',
      minzoom: MIN_SUBSEGMENTS_ZOOM,
      maxzoom: MAX_SUBSEGMENTS_ZOOM,
      paint: {
        'line-width': [
          'let',
          'width',
          [
            'case',
            ['<', ['get', 'roadClass'], 4],
            7,
            ['==', ['get', 'roadClass'], 4],
            4,
            ['==', ['get', 'roadClass'], 5],
            1,
            1,
          ],
          [
            'step',
            ['zoom'],
            1,
            DEFAULT_MIN_ZOOM,
            ['case', ['>', ['-', ['var', 'width'], 5], 0], ['-', ['var', 'width'], 5], 1],
            DEFAULT_MAX_ZOOM - 4,
            ['case', ['>', ['-', ['var', 'width'], 3], 0], ['-', ['var', 'width'], 3], 1],
            DEFAULT_MAX_ZOOM - 3,
            ['+', ['var', 'width'], 0],
            DEFAULT_MAX_ZOOM - 1,
            ['+', ['var', 'width'], 4],
          ],
        ],
        'line-color': [
          'case',
          ['<', ['get', 'mue'], 0.2],
          '#b60a1c',
          ['<', ['get', 'mue'], 0.4],
          '#e03531',
          ['<', ['get', 'mue'], 0.6],
          '#e39802',
          ['<', ['get', 'mue'], 0.8],
          '#51b364',
          ['>=', ['get', 'mue'], 0.8],
          '#309143',
          'white',
        ],
        'line-opacity': 0.4,
      },
    },
    {
      id: 'current-grip-segment-buckets',
      source: 'current-grip-simplified',
      'source-layer': 'subsegmentBuckets',
      type: 'line',
      minzoom: MIN_SUBSEGMENT_BUCKETS_ZOOM,
      maxzoom: MAX_SUBSEGMENT_BUCKETS_ZOOM,
      paint: {
        'line-width': [
          'let',
          'width',
          [
            'case',
            ['<', ['get', 'roadClass'], 4],
            7,
            ['==', ['get', 'roadClass'], 4],
            4,
            ['==', ['get', 'roadClass'], 5],
            1,
            1,
          ],
          [
            'step',
            ['zoom'],
            1,
            DEFAULT_MIN_ZOOM,
            ['case', ['>', ['-', ['var', 'width'], 5], 0], ['-', ['var', 'width'], 5], 1],
            DEFAULT_MAX_ZOOM - 4,
            ['case', ['>', ['-', ['var', 'width'], 3], 0], ['-', ['var', 'width'], 3], 1],
            DEFAULT_MAX_ZOOM - 3,
            ['+', ['var', 'width'], 0],
            DEFAULT_MAX_ZOOM - 1,
            ['+', ['var', 'width'], 4],
          ],
        ],
        'line-color': [
          'case',
          ['<=', ['get', 'mueBucketTo'], 0.2],
          '#b60a1c',
          ['<=', ['get', 'mueBucketTo'], 0.4],
          '#e03531',
          ['<=', ['get', 'mueBucketTo'], 0.6],
          '#e39802',
          ['<=', ['get', 'mueBucketTo'], 0.8],
          '#51b364',
          ['>=', ['get', 'mueBucketTo'], 0.8],
          '#309143',
          '#fff',
        ],
        'line-opacity': 0.4,
      },
    },
    {
      id: 'current-grip-subtiles',
      type: 'fill',
      minzoom: MIN_SUBTILES_ZOOM,
      maxzoom: MAX_SUBTILES_ZOOM,
      source: 'current-grip-simplified',
      'source-layer': 'subtiles',
      layout: {
        visibility: 'visible',
      },
      paint: {
        'fill-color': [
          'case',
          ['==', ['get', 'hasSubSegmentsWithGoodQualifier'], true],
          '#7495A6',
          ['==', ['get', 'hasSubSegmentsWithFairQualifier'], true],
          '#C7CDD1',
          ['==', ['get', 'hasSubSegmentsWithPoorQualifier'], true],
          '#CC734D',
          '#7495A6',
        ],
        'fill-outline-color': 'rgba(0,0,0,0)',
        'fill-opacity': 0.4,
      },
    },
  ],
  symbology: {
    title: 'Current Grip',
    iconClass: 'icon-wheel',
    tooltip: {
      icon: 'icon-tooltip-grip',
      heading: 'Latest Grip',
      fieldsCreator: (data) => {
        if ('mue' in data) {
          return [
            { title: 'Mue', value: getRoundedFloat(Number(data.mue), 2).toString() },
            { title: 'Mue error', value: getRoundedFloat(Number(data.errorMue), 2).toString() },
            { title: 'Quality [%]', value: getRoundedFloat(Number(data.quality), 2).toString() },
            { title: 'Road class', value: data.roadClass.toString() },
            { title: 'Qualifier', value: data.qualifier },
            { title: 'Source', value: data.source.toString() },
            { title: 'Sample timestamp', value: dayjs(data.sampleTimestamp).format('DD-MM-YYYY hh:mm') },
          ];
        }

        if ('mueBucketFrom' in data && 'mueBucketTo' in data) {
          return [
            { title: 'Mue', value: getTextForMue(data.mueBucketFrom, data.mueBucketTo) },
            { title: 'Mue error', value: getRoundedFloat(Number(data.errorMue), 2).toString() },
            { title: 'Quality [%]', value: getRoundedFloat(Number(data.quality), 2).toString() },
            { title: 'Road class', value: data.roadClass.toString() },
            { title: 'Qualifier', value: data.qualifier },
            { title: 'Source', value: data.source.toString() },
            { title: 'Sample timestamp', value: dayjs(data.sampleTimestamp).format('DD-MM-YYYY hh:mm') },
          ];
        }

        if (
          'hasSubSegmentsWithPoorQualifier' in data &&
          'hasSubSegmentsWithFairQualifier' in data &&
          'hasSubSegmentsWithGoodQualifier' in data
        ) {
          return [
            {
              title: 'Has subsegment with good qualifier',
              value: data.hasSubSegmentsWithGoodQualifier,
            },
            {
              title: 'Has subsegment with fair qualifier',
              value: data.hasSubSegmentsWithFairQualifier,
            },
            {
              title: 'Has subsegment with poor qualifier',
              value: data.hasSubSegmentsWithPoorQualifier,
            },
          ];
        }

        return [];
      },
    },
  },
  filters: {
    gripFilter: {
      title: 'Grip rating',
      filterName: 'gripFilter',
      filterableValue: 'mue',
      filterType: FilterType.BUTTONS,
      defaultState: {
        high: true,
        medium: true,
        low: true,
      },
      options: [
        {
          title: 'High',
          name: 'high',
          values: [GRIP_DATA_HIGH_VALUE, 999],
        },
        {
          title: 'Medium',
          name: 'medium',
          values: [GRIP_DATA_LOW_VALUE, GRIP_DATA_HIGH_VALUE],
        },
        {
          title: 'Low',
          name: 'low',
          values: [0, GRIP_DATA_LOW_VALUE],
        },
      ],
      dataFilterFunction: filterByGripRating,
    },
    functionalRoadClassFilter: {
      title: 'Functional road class',
      filterName: 'functionalRoadClassFilter',
      filterableValue: 'roadClass',
      filterType: FilterType.BUTTONS,
      defaultState: {
        arterial: true,
        collector: true,
        local: true,
      },
      options: [
        {
          title: 'Arterial',
          name: 'arterial',
          values: [0, ARTERIAL_ROAD_CLASS_VALUE + 1],
        },
        {
          title: 'Collector',
          name: 'collector',
          values: [COLLECTOR_ROAD_CLASS_VALUE, COLLECTOR_ROAD_CLASS_VALUE + 1],
        },
        {
          title: 'Local',
          name: 'local',
          values: [LOCAL_ROAD_CLASS_VALUE, LOCAL_ROAD_CLASS_VALUE + 1],
        },
      ],
      dataFilterFunction: filterByRoadClass,
    },
    qualifierFilter: {
      title: 'Qualifier',
      filterName: 'qualifierFilter',
      filterableValue: 'qualifier',
      filterType: FilterType.BUTTONS,
      defaultState: {
        good: true,
        fair: true,
        poor: true,
      },
      options: [
        {
          title: 'Good',
          name: 'good',
          values: ['GOOD'],
        },
        {
          title: 'Fair',
          name: 'fair',
          values: ['FAIR'],
        },
        {
          title: 'Poor',
          name: 'poor',
          values: ['POOR'],
        },
      ],
    },
  },
};

export const groundTruthSimplified: LayerConfig = {
  layerName: 'ground-truth-simplified',
  isLiveLayer: false,
  mapSource: {
    type: 'tiles',
    source: () => ({
      type: 'vector',
      tiles: [makeUrl(API_TILES, { layer: 'ground-truth' })],
      minzoom: DEFAULT_WW_MIN_ZOOM,
    }),
  },
  mapLayers: [
    {
      id: 'ground-truth-simplified',
      source: 'ground-truth-simplified',
      'source-layer': 'ground-truth',
      type: 'line',
      paint: {
        'line-width': [
          'let',
          'width',
          [
            'case',
            ['<', ['get', 'functional_road_class'], 4],
            7,
            ['==', ['get', 'functional_road_class'], 4],
            4,
            ['==', ['get', 'functional_road_class'], 5],
            1,
            1,
          ],
          [
            'step',
            ['zoom'],
            1,
            DEFAULT_MIN_ZOOM,
            ['case', ['>', ['-', ['var', 'width'], 5], 0], ['-', ['var', 'width'], 5], 1],
            DEFAULT_MAX_ZOOM - 4,
            ['case', ['>', ['-', ['var', 'width'], 3], 0], ['-', ['var', 'width'], 3], 1],
            DEFAULT_MAX_ZOOM - 3,
            ['+', ['var', 'width'], 0],
            DEFAULT_MAX_ZOOM - 1,
            ['+', ['var', 'width'], 4],
          ],
        ],
        'line-color': [
          'string',
          [
            'let',
            'value',
            ['get', 'value'],
            [
              'case',
              ['all', ['>=', ['var', 'value'], 0], ['<', ['var', 'value'], GRIP_DATA_LOW_VALUE]],
              '#86c6d6',
              ['all', ['>=', ['var', 'value'], GRIP_DATA_LOW_VALUE], ['<', ['var', 'value'], GRIP_DATA_HIGH_VALUE]],
              '#86c6d6',
              ['all', ['>=', ['var', 'value'], GRIP_DATA_HIGH_VALUE]],
              '#86c6d6',
              'blue',
            ],
          ],
        ],
      },
    },
  ],
  symbology: {
    title: 'Ground Truth',
    iconClass: 'icon-wheel',
    tooltip: {
      icon: 'icon-tooltip-grip',
      heading: 'Ground-truth Grip',
      fieldsCreator: (data) => [
        {
          title: 'Mu',
          value: `${getRoundedFloat(Number(data.value) - Number(data.error), 1)}-${getRoundedFloat(
            Number(data.value) + Number(data.error),
            1,
          )}`,
        },
        { title: 'Quality [%]', value: getRoundedFloat(Number(data.quality), 2).toString() },
        { title: 'Road class', value: data.roadClass.toString() },
      ],
    },
  },
  filters: {
    gripFilter: {
      title: 'Grip rating',
      filterName: 'gripFilter',
      filterableValue: 'value',
      filterType: FilterType.BUTTONS,
      defaultState: {
        high: true,
        medium: true,
        low: true,
      },
      options: [
        {
          title: 'High',
          name: 'high',
          values: [GRIP_DATA_HIGH_VALUE, 999],
        },
        {
          title: 'Medium',
          name: 'medium',
          values: [GRIP_DATA_LOW_VALUE, GRIP_DATA_HIGH_VALUE],
        },
        {
          title: 'Low',
          name: 'low',
          values: [0, GRIP_DATA_LOW_VALUE],
        },
      ],
      dataFilterFunction: filterByGripRating,
    },
    functionalRoadClassFilter: {
      title: 'Functional road class',
      filterName: 'functionalRoadClassFilter',
      filterableValue: 'functional_road_class',
      filterType: FilterType.BUTTONS,
      defaultState: {
        arterial: true,
        collector: true,
        local: true,
      },
      options: [
        {
          title: 'Arterial',
          name: 'arterial',
          values: [0, ARTERIAL_ROAD_CLASS_VALUE + 1],
        },
        {
          title: 'Collector',
          name: 'collector',
          values: [COLLECTOR_ROAD_CLASS_VALUE, COLLECTOR_ROAD_CLASS_VALUE + 1],
        },
        {
          title: 'Local',
          name: 'local',
          values: [LOCAL_ROAD_CLASS_VALUE, LOCAL_ROAD_CLASS_VALUE + 1],
        },
      ],
      dataFilterFunction: filterByRoadClass,
    },
  },
};

const getTextForMue = (mueFrom: string, mueTo: string): string => {
  if (Number(mueFrom) === Number.NEGATIVE_INFINITY) {
    return '< 0.2';
  }

  if (Number(mueTo) === Number.POSITIVE_INFINITY) {
    return '>= 0.8';
  }

  return `${getRoundedFloat(Number(mueFrom), 2)}-${getRoundedFloat(Number(mueTo), 2)}`;
};

export const gripSimplified: LayerConfig = {
  layerName: 'grip-simplified',
  isLiveLayer: false,
  symbology: {
    title: 'Grip',
    iconClass: 'icon-wheel',
  },
  subLayers: [currentGripSimplified, groundTruthSimplified],
};
