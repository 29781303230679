import React, { useCallback, useState } from 'react';
import AnalyticsDashboard from '../AnalyticsDashboard';
import ListView from '../ListView';
import { IMapService } from '../../../map/MapService';

interface Props {
  mapService: IMapService;
}

export const Analytics: React.FC<Props> = (props: Props) => {
  const { mapService } = props;
  const [isDashboardOpen, setDashboardOpen] = useState<boolean>(true);
  const [isListViewOpen, setListViewOpen] = useState<boolean>(false);

  const toggleDashboard = useCallback(() => {
    if (isListViewOpen && !isDashboardOpen) {
      setListViewOpen(false);
    }

    setDashboardOpen(!isDashboardOpen);
  }, [isDashboardOpen, isListViewOpen]);

  const toggleListView = useCallback(() => {
    if (isDashboardOpen && !isListViewOpen) {
      setDashboardOpen(false);
    }

    setListViewOpen(!isListViewOpen);
  }, [isListViewOpen, isDashboardOpen]);

  return (
    <>
      <AnalyticsDashboard isOpen={isDashboardOpen} toggleIsOpen={toggleDashboard} />
      <ListView isOpen={isListViewOpen} toggleIsOpen={toggleListView} mapService={mapService} />
    </>
  );
};
