import React from 'react';
import { Snapshot } from '../../../types';

interface Props {
  snapshot: Snapshot;
  onSelect: () => void;
}

export const SnapshotItem: React.FC<Props> = (props) => {
  const { snapshot, onSelect } = props;

  const isSnapshotInProgressOfSaving =
    snapshot.tiles_status === 'in_progress' || snapshot.datafile_status === 'in_progress';

  return (
    <div className="sidebar-card" onClick={onSelect}>
      <div className="sidebar-card-container">
        <img src={snapshot.thumbnail} alt="map" className="sidebar-card-img" />
      </div>
      <div className="sidebar-card-info">
        <span className="sidebar-card-title">{snapshot.name}</span>
        <span className="sidebar-card-subtitle">Saved: {snapshot.report_id}</span>
        <span className="sidebar-card-subtitle">Status: {isSnapshotInProgressOfSaving ? 'in progress' : 'saved'}</span>
        {/*<span className="sidebar-card-subtitle">Detroit, 2 Areas</span>*/}
      </div>
      {/*TODO: uncomment if you need delete snapshot*/}
      {/*<div className="sidebar-card-controls">*/}
      {/*  <button className="sidebar-card-btn">*/}
      {/*    <i className="sidebar-card-icon icon icon-delete" />*/}
      {/*  </button>*/}
      {/*</div>*/}
    </div>
  );
};
