import React, { FormEvent, useRef, useState } from 'react';
import { Position } from '@turf/turf';
import { POLYGON_VALIDATION_TITLE_TO_LONG } from '../../../../../constants/errors';
import './_polygon-modal.scss';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectUser } from '../../../user/store/store';
import { saveCustomPolygon } from '../../../user/store/thunks';

interface Props {
  polygonCoords: Position[][];
  onSave: () => void;
  onClose: () => void;
}

const SavePolygonModal: React.FC<Props> = (props) => {
  const { polygonCoords, onSave, onClose } = props;

  const [errors, setErrors] = useState<string[]>([]);
  const titleRef = useRef<HTMLInputElement>(null);

  const user = useAppSelector(selectUser);

  const dispatch = useAppDispatch();

  const validate = (): void => {
    const title = titleRef.current?.value.trim();
    setErrors([]);

    if (title && title.trim().length > 50) {
      setErrors([POLYGON_VALIDATION_TITLE_TO_LONG]);
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    dispatch(
      saveCustomPolygon({
        title: titleRef.current?.value as string,
        polygon: polygonCoords,
        basePolygonId: user?.regions[0] as number,
      }),
    );

    onSave();
  };

  return (
    <div className="polygon">
      <form onSubmit={handleSubmit}>
        <span className="polygon-title">Save polygon</span>
        <label title="Polygon name" className="polygon-label">
          {/* TODO: add class .isIncorrect when error occurred */}
          <input ref={titleRef} onChange={validate} type="text" name="polygonName" className="polygon-input" required />
          {errors.length ? <span className="polygon-input file">{errors[0]}</span> : ''}
        </label>
        <div className="polygon-btn-container">
          <button className="polygon-btn fill" disabled={!!errors.length || !titleRef.current?.value} type="submit">
            Save
          </button>
          <button className="polygon-btn" onClick={onClose}>
            Close
          </button>
        </div>
      </form>
    </div>
  );
};

export default SavePolygonModal;
