import { createAsyncThunk } from '@reduxjs/toolkit';
import { AuthResponse, LoginCredentialsWithSaveCredentials } from '../types';
import * as AuthApi from '../api/api';
import { ILocalStorageService } from '../../../services/LocalStorageService';
import { container } from '../../../../configs/inversify';

const LOCAL_STORAGE_LOGIN_FIELDS = 'login-credentials';
const LOCAL_STORAGE_AUTHORIZATION_TOKEN_FIELD = 'Authorization';
const LOCAL_STORAGE_REFRESH_TOKEN_FIELD = 'refresh-token';

const localStorageService: ILocalStorageService = container.get('LocalStorageService');

export const login = createAsyncThunk(
  'auth/login',
  async (credentials: LoginCredentialsWithSaveCredentials): Promise<AuthResponse> => {
    const res = await AuthApi.login({
      username: credentials.username,
      password: credentials.password,
    });

    if (credentials.saveCredentials) {
      localStorageService.setItem(LOCAL_STORAGE_LOGIN_FIELDS, {
        username: credentials.username,
        password: credentials.password,
      });
    }

    localStorageService.setItem(LOCAL_STORAGE_AUTHORIZATION_TOKEN_FIELD, res.access_token);
    localStorageService.setItem(LOCAL_STORAGE_REFRESH_TOKEN_FIELD, res.refresh_token);

    return res;
  },
);

export const logout = createAsyncThunk('auth/logout', async (): Promise<void> => {
  // TODO: here you will be place a logout api call from service if it is implemented by BE
  return;
});
