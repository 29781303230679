import React from 'react';
import { Dropdown, DropdownItem } from 'shared';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectUser } from '../../store/store';
import { logout } from '../../../auth/store/thunks';
import { useResetStore } from '../../../app/useResetStore';

const UserDropdown: React.FC = () => {
  const user = useAppSelector(selectUser);

  const { resetStore } = useResetStore();

  const dispatch = useAppDispatch();

  const onLogout = () => {
    resetStore();
    dispatch(logout());
  };

  return (
    <Dropdown name={user?.name || ''} icon="header-user-icon icon-user isLight" className="header-user">
      <DropdownItem onClick={onLogout}>
        Log out <i className="header-logout icon icon-logout isLight" />
      </DropdownItem>
    </Dropdown>
  );
};

export default UserDropdown;
