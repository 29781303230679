import React, { UIEvent, MouseEvent, useEffect, useState } from 'react';
import { ListMode } from '../index';
import { Data, DataItem } from '../../../../layers/types';
import { getRoundedFloat, transformSnakeCaseToSentence } from '../../../../../../helpers/common';
import { AppType } from '../../../../selection/types';
import {
  resetSelectedSegments,
  selectedMainSortingSelector,
  selectedSecondSortingSelector,
  selectedSegmentIdsSelector,
  setSelectedMainSorting,
  setSelectedSecondSorting,
  setSelectedSegmentIds,
  SortingTitle,
  toggleSegmentId,
} from '../../../../selection/store/store';
import { TableRow } from './TableRow';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { filteredPaginatedDataSelector } from '../../../../layers/store/store';

interface Props {
  appType: AppType;
  listMode: ListMode;
  list: Data[];
  loadMore: () => void;
  onClickLocate: (geometry: string) => void;
}

const propTitlesByAppType: Record<AppType, SortingTitle[]> = {
  [AppType.GRIP]: ['functional_road_class', 'value', 'quality', 'error'],
  [AppType.PAVEMENT]: ['functional_road_class', 'value', 'quality'],
  [AppType.POTHOLE]: ['functional_road_class', 'severity'],
  [AppType.SURFACE_EVENTS]: [],
};

const valueTitleByAppType: Record<AppType, string> = {
  [AppType.GRIP]: 'Mu',
  [AppType.PAVEMENT]: 'Pavement rating',
  [AppType.POTHOLE]: '',
  [AppType.SURFACE_EVENTS]: '',
};

export const ListTable: React.FC<Props> = (props: Props) => {
  const { appType, list, loadMore, onClickLocate } = props;
  const [titles, setTitles] = useState<SortingTitle[]>([]);
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(true);

  const filteredPaginatedData = useAppSelector(filteredPaginatedDataSelector);
  const selectedMainSorting = useAppSelector(selectedMainSortingSelector);
  const selectedSecondSorting = useAppSelector(selectedSecondSortingSelector);
  const selectedSegmentIds = useAppSelector(selectedSegmentIdsSelector);

  const dispatch = useAppDispatch();

  const wrapTitleIfSelected = (title: SortingTitle) => {
    const transformedTitle = title === 'value' ? valueTitleByAppType[appType] : transformSnakeCaseToSentence(title);

    if (title === selectedMainSorting.title) {
      return (
        <div className="list-th-sort">
          {transformedTitle}
          <i className={`list-th-icon icon-sort icon isMain ${!selectedMainSorting.isASC && 'isUp'}`} />
        </div>
      );
    } else if (title === selectedSecondSorting.title) {
      return (
        <div className="list-th-sort">
          {transformedTitle}
          <i className={`list-th-icon icon-sort icon ${!selectedSecondSorting.isASC && 'isUp'}`} />
        </div>
      );
    }

    return transformedTitle;
  };

  const handleClick = (title: SortingTitle, event: MouseEvent) => {
    event.stopPropagation();

    if (event.ctrlKey) {
      dispatch(setSelectedSecondSorting({ title, isASC: !selectedSecondSorting.isASC }));
    } else {
      dispatch(setSelectedMainSorting({ title, isASC: !selectedMainSorting.isASC }));
    }
  };

  const onScrollTable = ({ currentTarget }: UIEvent<HTMLDivElement>) => {
    const { scrollHeight, scrollTop, clientHeight } = currentTarget;

    if (scrollHeight - scrollTop === clientHeight) loadMore();
  };

  const handleSelectAll = () => {
    if (isSelectedAll) {
      dispatch(resetSelectedSegments());
    } else {
      dispatch(setSelectedSegmentIds(filteredPaginatedData.map(({ id }) => id)));
    }
  };

  const toggleSelectedSegmentId = (id: string): void => {
    dispatch(toggleSegmentId(id));
  };

  useEffect(() => {
    setIsSelectedAll(list.length === selectedSegmentIds.length);
  }, [list, selectedSegmentIds]);

  useEffect(() => {
    setTitles(['segment_ID', ...propTitlesByAppType[appType]]);
  }, [appType]);

  return (
    <div className="list-table-container" data-testid="table-container" onScroll={onScrollTable}>
      <table className="list-table">
        <thead className="list-thead">
          <tr className="list-tr" data-testid="titles">
            <th className="list-th">
              <input type="checkbox" className="list-table-input" checked={isSelectedAll} onChange={handleSelectAll} />
              <span className="list-table-checkbox" />
            </th>
            {/**
             If sorted by this value wrap value with <div className="list-th-sort"> and add in wrap <i className="list-th-icon icon-sort icon isMain" />
             */}
            {titles.map((title) => {
              if (title === 'segment_ID') {
                return (
                  <th key={title} className="list-th">
                    {transformSnakeCaseToSentence(title)}
                  </th>
                );
              } else {
                return (
                  <th key={title} className="list-th" onClick={handleClick.bind(this, title)}>
                    {wrapTitleIfSelected(title)}
                  </th>
                );
              }
            })}
            <th className="list-th" />
          </tr>
        </thead>
        <tbody className="list-tbdoy">
          {list.map(({ id, data }) => (
            <TableRow
              key={id}
              isSelected={selectedSegmentIds.includes(id)}
              geometry={data.geometry}
              values={propTitlesByAppType[appType].map((title) => {
                if (title === 'quality') {
                  return getRoundedFloat(data[title as Exclude<keyof DataItem, 'geometry'>], 2);
                }

                return data[title as Exclude<keyof DataItem, 'geometry'>];
              })}
              onClickSelect={toggleSelectedSegmentId.bind(this, id)}
              onClickLocate={onClickLocate.bind(this, data.geometry)}
            />
          ))}
          <tr style={{ height: 50 }} />
        </tbody>
      </table>
    </div>
  );
};
