import { LayerConfig } from './index';
import { getTimeStringFromMs, makeUrl } from '../../helpers/common';
import { API_TILES } from '../../constants/api';
import { DEFAULT_MAX_ZOOM, DEFAULT_MIN_ZOOM, DEFAULT_WW_MIN_ZOOM } from '../../constants/map';
import { FilterType } from '../../app/modules/filter/types';

const ONE_WEEK_TIME_MS = 604800000;
const ONE_DAY_TIME_MS = 86400000;

const MAX_ZOOM_SUBSEGMENTS = 17;
const MIN_ZOOM_SUBSEGMENTS = 8;

const MAX_ZOOM_SUBDIVISIONS = 7;
const MIN_ZOOM_SUBDIVISIONS = 1;

export const surfaceEvents: LayerConfig = {
  layerName: 'surface-events',
  isLiveLayer: true,
  hasSnapshots: false,
  mapSource: {
    type: 'tiles',
    source: () => ({
      type: 'vector',
      tiles: [makeUrl(API_TILES, { layer: 'latest-surface-events' })],
      minzoom: DEFAULT_WW_MIN_ZOOM,
    }),
  },
  mapLayers: [
    {
      id: 'surface-events-simplified-subsegments',
      source: 'surface-events',
      'source-layer': 'latest-surface-events-subsegments',
      type: 'line',
      maxzoom: MAX_ZOOM_SUBSEGMENTS,
      minzoom: MIN_ZOOM_SUBSEGMENTS,
      paint: {
        'line-width': [
          'let',
          'width',
          [
            'case',
            ['<', ['get', 'roadClass'], 4],
            7,
            ['==', ['get', 'roadClass'], 4],
            4,
            ['==', ['get', 'roadClass'], 5],
            1,
            1,
          ],
          [
            'step',
            ['zoom'],
            1,
            DEFAULT_MIN_ZOOM,
            ['case', ['>', ['-', ['var', 'width'], 5], 0], ['-', ['var', 'width'], 5], 1],
            DEFAULT_MAX_ZOOM - 4,
            ['case', ['>', ['-', ['var', 'width'], 3], 0], ['-', ['var', 'width'], 3], 1],
            DEFAULT_MAX_ZOOM - 3,
            ['+', ['var', 'width'], 0],
            DEFAULT_MAX_ZOOM - 1,
            ['+', ['var', 'width'], 4],
          ],
        ],
        'line-color': [
          'let',
          'creationTime',
          ['get', 'lastSampleTimestampUtcMilliseconds'],
          [
            'interpolate',
            ['linear'],
            ['var', 'creationTime'],
            Date.now() - ONE_WEEK_TIME_MS,
            '#eb3754',
            Date.now() - ONE_DAY_TIME_MS,
            '#ffba54',
            Date.now(),
            '#00bf9e',
          ],
        ],
      },
    },
    {
      id: 'surface-events-simplified-subdivisions',
      type: 'fill',
      source: 'surface-events',
      'source-layer': 'latest-surface-events-subdivisions',
      maxzoom: MAX_ZOOM_SUBDIVISIONS,
      minzoom: MIN_ZOOM_SUBDIVISIONS,
      layout: {
        visibility: 'visible',
      },
      paint: {
        'fill-color': '#ddeaff',
        'fill-outline-color': 'rgba(0,0,0,0)',
        'fill-opacity': 0.5,
      },
    },
  ],
  symbology: {
    title: 'Surface events',
    iconClass: 'icon-wheel',
    tooltip: {
      icon: 'icon-tooltip-pothole',
      heading: 'Surface events',
      fieldsCreator: (data) => {
        // {
        //   "subSegmentId": "here:23611429|here:cm:segment:135176163|4",
        //     "roadClass": 2,
        //     "lastSampleTimestampUtcMilliseconds": 1668767620000,
        //     "dataPeriodUtcMilliseconds": 10000,
        //     "timeDistributionUtcMilliseconds": 5000,
        //     "stats.total.samplesCount": 2,
        //     "stats.total.averageMagnitude": 224,
        //     "stats.total.magnitudeStandardDeviation": 0,
        //     "stats.UNKNOWN.samplesCount": 2,
        //     "stats.UNKNOWN.averageMagnitude": 224,
        //     "stats.UNKNOWN.magnitudeStandardDeviation": 0
        // }

        console.log(data['stats.total.samplesCount']);

        // const stats = JSON.parse(data.stats) as Record<
        //   string,
        //   {
        //     averageMagnitude: number;
        //     magnitudeStandardDeviation: number;
        //     samplesCount: number;
        //   }
        // >;

        const fields: { title: string; value: string }[] = [];

        fields.push({
          title: `sample count (unknown)`,
          value: data['stats.UNKNOWN.samplesCount'].toString(),
        });
        fields.push({
          title: `avg. magnitude (unknown)`,
          value: Math.floor(Number(data['stats.UNKNOWN.averageMagnitude'])).toString(),
        });
        fields.push({
          title: `magnitude std (unknown)`,
          value: Math.floor(Number(data['stats.UNKNOWN.magnitudeStandardDeviation'])).toString(),
        });

        // const statsLength = Object.keys(stats).length;
        // Object.entries(stats).forEach(([statKey, statValue]) => {
        //   if (statKey === 'total' && statsLength === 2) return;
        //
        //   fields.push({
        //     title: `sample count (${statKey.toLowerCase()})`,
        //     value: statValue.samplesCount.toString(),
        //   });
        //   fields.push({
        //     title: `avg. magnitude (${statKey.toLowerCase()})`,
        //     value: Math.floor(statValue.averageMagnitude).toString(),
        //   });
        //   fields.push({
        //     title: `magnitude std (${statKey.toLowerCase()})`,
        //     value: Math.floor(statValue.magnitudeStandardDeviation).toString(),
        //   });
        // });

        fields.push({
          title: `age`,
          value: getTimeStringFromMs(Date.now() - Number(data.lastSampleTimestampUtcMilliseconds)),
        });

        fields.push({
          title: `period`,
          value: getTimeStringFromMs(Number(data.dataPeriodUtcMilliseconds)),
        });

        return fields;
      },
    },
  },
  filters: {
    geometryFilter: {
      title: '',
      filterName: 'geometryFilter',
      filterableValue: 'geometry',
      filterType: FilterType.GEOMETRY,
      defaultState: {
        geometryFilter: true,
      },
      isHidden: true,
    },
  },
};
