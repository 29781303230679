import React, { useEffect, useState } from 'react';
import { ListTable } from './ListTable';
import { IMapService } from '../../../map/MapService';
import { getCenterOfPolygon } from '../../../../../helpers/common';
import { Tooltip, TooltipSide } from 'shared';
import { AppType } from '../../../selection/types';
import { layers } from '../../../../../configs/layers';
import { FetchDataPageParams } from '../../../layers/types';
import './_list-view.scss';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  resetSelectedSegments,
  resetSorting,
  selectSelectedAppType,
  selectSelectedLayer,
  selectSelectedReportId,
  selectSelectedSubLayer,
} from '../../../selection/store/store';
import {
  selectedMainSortingSelector,
  selectedSecondSortingSelector,
  selectedSegmentIdsSelector,
} from '../../../selection/store/store';
import { selectCustomPolygons, selectIsGlobalUser, selectPolygons } from '../../../user/store/store';
import { getDataPage } from '../../../layers/store/thunks';
import {
  filteredDataSelector,
  filteredPaginatedDataSelector,
  filterPaginatedData,
  pagesCountSelector,
} from '../../../layers/store/store';
import { getCoordinatesFromGripDataGeometry } from '../../../map/helpers';

interface Props {
  isOpen: boolean;
  toggleIsOpen: () => void;
  mapService: IMapService;
}

export enum ListMode {
  GRIP = 'grip',
  ALERTS = 'alerts',
}

const ListView: React.FC<Props> = (props: Props) => {
  const { isOpen, toggleIsOpen, mapService } = props;
  const [polygonsChildList, setPolygonChildList] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isDataUpdated, setIsDataUpdated] = useState<boolean>(true);

  const selectedAppType = useAppSelector(selectSelectedAppType);
  const selectedLayer = useAppSelector(selectSelectedLayer);
  const selectedSubLayer = useAppSelector(selectSelectedSubLayer);
  const selectedMainSorting = useAppSelector(selectedMainSortingSelector);
  const selectedSecondSorting = useAppSelector(selectedSecondSortingSelector);
  const selectedReportId = useAppSelector(selectSelectedReportId);
  const selectedSegmentIds = useAppSelector(selectedSegmentIdsSelector);
  const polygons = useAppSelector(selectPolygons);
  const pagesCount = useAppSelector(pagesCountSelector);
  const customPolygons = useAppSelector(selectCustomPolygons);
  const filteredData = useAppSelector(filteredDataSelector);
  const filteredPaginatedData = useAppSelector(filteredPaginatedDataSelector);
  const isGlobalUser = useAppSelector(selectIsGlobalUser);

  const dispatch = useAppDispatch();

  const loadData = (page: number): void => {
    if (!polygons.length || !selectedLayer || !selectedAppType) return;

    const targetLayer = selectedSubLayer || selectedLayer;

    let params: FetchDataPageParams = {
      polygonId: polygons[0].id,
      page,
      layerName: targetLayer,
      mainSort: selectedMainSorting,
      secSort: selectedSecondSorting,
    };

    if (selectedReportId) {
      params = {
        ...params,
        reportId: selectedReportId,
      };
    }

    dispatch(getDataPage(params));
  };

  const loadActualData = () => {
    dispatch(filterPaginatedData());
  };

  const loadMore = () => {
    if (pagesCount && currentPage + 1 <= pagesCount && isOpen) {
      setCurrentPage(currentPage + 1);
    }
  };

  const locate = (geometry: string) => {
    const coords = getCoordinatesFromGripDataGeometry(geometry);
    const center = getCenterOfPolygon(coords);

    mapService.navigate({ lng: center[0], lat: center[1] });
    // mapDrawService.showPin('locate-pin', center, 10);
  };

  useEffect(() => {
    if (!selectedLayer) return;

    const layerConfig = layers[selectedLayer];

    if (!layerConfig) return;

    if (!layerConfig.statisticSource) return;

    if (layerConfig.statisticSource.type === 'report' && !selectedReportId) return;

    loadData(currentPage);
  }, [currentPage, polygons, customPolygons, selectedLayer, selectedReportId, filteredData]);

  useEffect(() => {
    if (!polygons.length) return;

    const polygon = polygons[0];

    const polygonChildrenList = polygon.children.reduce((prev, curr) => {
      return `${prev.length ? `${prev}, ` : 0}${curr.title}`;
    }, '');

    setPolygonChildList(polygonChildrenList);
  }, [polygons, customPolygons]);

  useEffect(() => {
    if (!filteredPaginatedData.length && isOpen) {
      toggleIsOpen();
    }
  }, [filteredPaginatedData]);

  useEffect(() => {
    if (isOpen) toggleIsOpen();
  }, [selectedAppType]);

  useEffect(() => {
    setIsDataUpdated(false);
  }, [filteredData]);

  useEffect(() => {
    setIsDataUpdated(true);
  }, [filteredPaginatedData]);

  useEffect(() => {
    dispatch(resetSelectedSegments());

    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  }, [selectedMainSorting, selectedSecondSorting, selectedAppType]);

  useEffect(() => {
    dispatch(resetSorting());
  }, [selectedAppType]);

  useEffect(() => {
    if (!isOpen && selectedSegmentIds.length) {
      dispatch(resetSelectedSegments());
    }
  }, [isOpen]);

  return (
    <>
      <div className="controls-btn-container dash-controls">
        <Tooltip text="Statistics - tabular" side={TooltipSide.LEFT}>
          <button
            className={`controls-btn controls-clipboard ${isOpen ? 'active' : ''}`}
            data-testid="map-cursor-disabled"
            onClick={toggleIsOpen}
            disabled={isGlobalUser || !filteredPaginatedData.length}
          >
            <i className="controls-icon icon icon-clipboard" />
          </button>
        </Tooltip>
      </div>
      <div className={`list-view ${isOpen ? 'isVisible' : 'isHidden'}`}>
        <header className="list-head">
          <div className="list-head-breadcrumbs">
            <span className="list-head-breadcrumb">
              {polygons.map((polygon, index) => `${index ? ', ' : ''}${polygon.id}`)}
            </span>
            <span className="list-head-breadcrumb">
              {polygonsChildList.length > 15 ? (
                <>
                  {`${polygonsChildList.slice(0, 15)} ...`}
                  <span style={{ display: 'none', position: 'absolute' }}>{polygonsChildList}</span>
                </>
              ) : (
                polygonsChildList
              )}
            </span>
            {!isDataUpdated && <i className="list-refresh-icon icon icon-refresh" onClick={loadActualData} />}
          </div>
          {selectedAppType !== AppType.PAVEMENT && (
            <div className="list-toggler">
              <button className="list-toggler-btn active">Grip</button>
              <button className="list-toggler-btn">Alerts</button>
            </div>
          )}
        </header>
        <main className="list-main">
          <div className="list-table-pad ">
            {selectedAppType && (
              <ListTable
                appType={selectedAppType}
                list={filteredPaginatedData}
                listMode={ListMode.GRIP}
                loadMore={loadMore}
                onClickLocate={locate}
              />
            )}
          </div>
          {/*<DataView />*/}
        </main>
      </div>
    </>
  );
};

export default ListView;
