import React, { FC, useEffect, useRef, useState } from 'react';

interface Props {
  title: string;
  filterName: string;
  min: number;
  max: number;
  currentValue: number;
}

export const RangeFilter: FC<Props> = (props: Props) => {
  const { title, min, max, currentValue } = props;

  const [inputValue, setInputValue] = useState<string>(currentValue.toString());
  const filterRef = useRef<HTMLInputElement | null>(null);

  const onChangeValue = () => {
    if (filterRef.current?.value) setInputValue(filterRef.current?.value);
  };

  useEffect(() => {
    filterRef.current?.addEventListener('change', () => {
      if (filterRef.current?.value) {
        // setRangeFilterValue(filterName, Number(filterRef.current?.value));
      }
    });
  }, []);

  useEffect(() => {
    if (filterRef.current?.value !== currentValue.toString()) {
      setInputValue(currentValue.toString());
    }
  }, [currentValue]);

  const sliderValue = (parseInt(inputValue) * 100) / max;
  const newVal = ((parseInt(inputValue) - min) * 100) / (max - min);

  return (
    <div className="filter">
      <p className="filter-title">{title}</p>
      <div className="filter-slider-container">
        <input
          ref={filterRef}
          type="range"
          min={min}
          max={max}
          value={inputValue}
          onChange={onChangeValue}
          className="filter-slider"
        />
        <span className="filter-tooltip" style={{ left: `calc(${newVal}% + (${5 - newVal * 0.1}px))` }}>
          {inputValue} h
        </span>
        <span className="filter-slider-range" style={{ width: `${sliderValue}%` }} />
      </div>
    </div>
  );
};
