import { SearchingArea, Units } from '../app/modules/map/types';
import { FilterType, SelectedFilters } from '../app/modules/filter/types';
import { FilterConfig } from '../configs/layers';
import { Position } from '@turf/turf';

export const getCenterOfPolygon = (arr: number[][]): [number, number] => {
  let minX, maxX, minY, maxY;
  for (let i = 0; i < arr.length; i++) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    minX = arr[i][0] < minX || minX == null ? arr[i][0] : minX;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    maxX = arr[i][0] > maxX || maxX == null ? arr[i][0] : maxX;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    minY = arr[i][1] < minY || minY == null ? arr[i][1] : minY;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    maxY = arr[i][1] > maxY || maxY == null ? arr[i][1] : maxY;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return [(minX + maxX) / 2, (minY + maxY) / 2];
};

export const getCustomPolygonWKTString = (data: Position[][]): string => {
  let res = 'POLYGON((';
  const dataLength = data[0].length;

  data[0].forEach((item, index) => {
    res += `${item[0]} ${item[1]}`;

    if (index + 1 < dataLength) {
      res += ',';
    }
  });

  res += '))';

  return res;
};

export const makeFirstLatterUppercase = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const transformSnakeCaseToSentence = (string: string): string => {
  return makeFirstLatterUppercase(string.replaceAll('_', ' '));
};

export const convertBase64ToFile = (base64String: string, fileName: string): File => {
  const arr = base64String.split(',');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const uint8Array = new Uint8Array(n);
  while (n--) {
    uint8Array[n] = bstr.charCodeAt(n);
  }
  return new File([uint8Array], fileName, { type: mime });
};

export const getSearchingArea = (polygonCoordinates: number[][]): SearchingArea => {
  const searchingArea: SearchingArea = {
    minCoordinates: [],
    maxCoordinates: [],
  };

  polygonCoordinates.forEach((coordinate) => {
    if (!searchingArea.minCoordinates.length) {
      searchingArea.minCoordinates[0] = coordinate[0];
      searchingArea.minCoordinates[1] = coordinate[1];
      searchingArea.maxCoordinates[0] = coordinate[0];
      searchingArea.maxCoordinates[1] = coordinate[1];
    }

    if (searchingArea.minCoordinates[0] > coordinate[0]) {
      searchingArea.minCoordinates[0] = coordinate[0];
    }

    if (searchingArea.minCoordinates[1] > coordinate[1]) {
      searchingArea.minCoordinates[1] = coordinate[1];
    }

    if (searchingArea.maxCoordinates[0] < coordinate[0]) {
      searchingArea.maxCoordinates[0] = coordinate[0];
    }

    if (searchingArea.maxCoordinates[1] < coordinate[1]) {
      searchingArea.maxCoordinates[1] = coordinate[1];
    }
  });

  return searchingArea;
};

export const getPercent = (current: number, total: number, precision = 0): number => {
  if (total === 0) {
    return 0;
  }

  const result = getRoundedFloat((current * 100) / total, precision);

  if (isNaN(result)) {
    return 0;
  }

  return result;
};

export const getRoundedFloat = (float: number, precision = 0): number => {
  if (precision === 0) {
    return Math.round(float);
  } else {
    return Math.round(float * Math.pow(10, precision)) / Math.pow(10, precision);
  }
};

export const millisecondsToHours = (ms: number): number => {
  return ms / (1000 * 60 * 60);
};

export const millisecondsToDays = (ms: number): number => {
  return ms / (1000 * 60 * 60 * 24);
};

export const getTimeStringFromMs = (ms: number): string => {
  let seconds = Math.ceil(ms / 1000);

  if (seconds > 60) {
    seconds = seconds % 60;
  }

  ms = ms - seconds * 1000;

  let minutes = Math.ceil(ms / (1000 * 60));

  if (minutes > 60) {
    minutes = minutes % 60;
  }

  ms = ms - minutes * 1000 * 60;

  let hours = Math.ceil(ms / (1000 * 60 * 60));

  if (hours > 24) {
    hours = hours % 24;
  }

  ms = ms - hours * 1000 * 60 * 60;

  const days = Math.ceil(ms / (1000 * 60 * 60 * 24));

  if (days > 0) {
    return `${days} days, ${hours} hours`;
  }

  if (hours > 0) {
    return `${hours} hours, ${minutes} minutes`;
  }

  if (minutes > 0) {
    return `${minutes} minutes, ${seconds} seconds`;
  }

  if (seconds > 0) {
    return `${seconds} seconds`;
  }

  return '0';
};

export const getFlooredFloat = (float: number, precision = 0): number => {
  if (precision === 0) {
    return Math.floor(float);
  } else {
    return Math.floor(float * Math.pow(10, precision)) / Math.pow(10, precision);
  }
};

export const getDistance = (value: number, separator = ','): string => {
  const distance = value.toLocaleString('en-US');
  return distance.replace(/[.]/, separator);
};

export const makeUrl = (
  rawUrl: string,
  replacedValues: Record<string, any>,
  queryParams?: Record<string, any>,
): string => {
  let url = rawUrl;

  Object.entries(replacedValues).forEach(([key, value]) => {
    if (value !== undefined) {
      url = url.replace(`:${key}`, String(replacedValues[key]));
    }
  });

  const urlObject = new URL(url);

  if (queryParams) {
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value !== undefined) {
        urlObject.searchParams.set(key, value.toString());
      }
    });
  }

  return decodeURIComponent(urlObject.toString());
};

export const isPointInPolygon = (x: number, y: number, polygon: number[][]): boolean => {
  const polygonLength = polygon.length;
  let lastItem = polygonLength - 1;
  let result = false;

  polygon.forEach((point, index) => {
    if (x === point[0] && y === point[1]) {
      result = true;
    }

    if (point[1] > y !== polygon[lastItem][1] > y) {
      const slope =
        (x - point[0]) * (polygon[lastItem][1] - point[1]) - (polygon[lastItem][0] - point[0]) * (y - point[1]);

      if (slope === 0) {
        result = true;
      }

      if (slope < 0 !== polygon[lastItem][1] < point[1]) {
        result = !result;
      }
    }

    lastItem = index;
  });

  return result;
};

export const splitArrayOnParts = <T>(array: T[], count: number): T[][] => {
  const size = Math.ceil(array.length / count);

  return Array.from({ length: Math.ceil(array.length / size) }, (v, i) => array.slice(i * size, i * size + size));
};

export const getCurrentDate = (unix: number, units: Units): string => {
  const currentDate = new Date(unix);
  const currentMonth = (currentDate.getMonth() + 1).toString();
  let dateTime = '';

  if (units === Units.METRIC) {
    dateTime += currentDate.getDate().toString().length < 2 ? '0' + currentDate.getDate() : currentDate.getDate();
    dateTime += '-';
    dateTime += currentMonth.length < 2 ? '0' + currentMonth : currentMonth;
    dateTime += '-';
  } else if (units === Units.IMPERIAL) {
    dateTime += currentMonth.length < 2 ? '0' + currentMonth : currentMonth;
    dateTime += '-';
    dateTime += currentDate.getDate().toString().length < 2 ? '0' + currentDate.getDate() : currentDate.getDate();
    dateTime += '-';
  }

  dateTime += currentDate.getFullYear();
  dateTime += ` ${currentDate.getHours()}:`;
  dateTime += `${currentDate.getMinutes()}:`;
  dateTime += currentDate.getSeconds();

  return dateTime;
};

export const convertFiltersForMapbox = (
  selectedFilters: SelectedFilters,
  layerFilters: Record<string, FilterConfig>,
): any[] => {
  const filter: any[] = ['all'];

  Object.entries(selectedFilters).forEach(([name, options]) => {
    const currentFilter: any = ['any'];

    if (!layerFilters) {
      return;
    }

    Object.entries(options).forEach(([title, value]) => {
      const filterConfig = layerFilters[name];

      if (!filterConfig) {
        return;
      }

      if (filterConfig.filterType === FilterType.BUTTONS) {
        if ((value || value === 0) && filterConfig && filterConfig.options) {
          const filterValues = filterConfig.options;
          const filterableValue = filterConfig.filterableValue;

          if (!filterValues) return;

          if (filterConfig.filterType === FilterType.BUTTONS) {
            const currentOptions: any[] = ['all'];

            const filterOption = filterValues.find((option) => option.name === title);

            if (filterOption) {
              if (filterOption.values.length === 1) {
                currentOptions.push(['==', ['get', filterableValue], filterOption.values[0]]);
              } else {
                if (filterOption.values[0] === Number.POSITIVE_INFINITY) {
                  currentOptions.push(['==', ['get', filterableValue], filterOption.values[0]]);
                } else {
                  currentOptions.push(['>=', ['get', filterableValue], filterOption.values[0]]);
                }

                if (filterOption.values[1] === Number.POSITIVE_INFINITY) {
                  currentOptions.push(['==', ['get', filterableValue], filterOption.values[1]]);
                } else {
                  currentOptions.push(['<', ['get', filterableValue], filterOption.values[1]]);
                }
              }

              currentFilter.push(currentOptions);
            }
          }
        }
      }

      if (filterConfig.filterType === FilterType.RANGE) {
        const currentOptions: any[] = ['all'];

        const filterableValue = filterConfig.filterableValue;

        currentOptions.push(['>=', ['get', filterableValue], Date.now() - +value * (1000 * 60 * 60)]);
        currentFilter.push(currentOptions);
      }
    });

    if (layerFilters && layerFilters[name] && !layerFilters[name].isHidden) {
      filter.push(currentFilter);
    }
  });

  return filter;
};

export const hashString = (string: string): string => {
  let hash = '';

  for (let i = 0; i < 5; i++) {
    hash += string[Math.floor((i / 6) * string.length)];
  }

  return hash.replaceAll('.', String(0));
};

export const parseJwt = <T>(token: string): T => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export function getCoordinatesFromLineGeometry(geometry: string): number[][] {
  const arr = [];
  const coordsString = geometry.slice(11, geometry.length - 1);
  const dividedCoords = coordsString.split(',');
  arr.push(...dividedCoords.map((item: string) => item.split(' ').map((item: string) => parseFloat(item))));
  return arr;
}
