import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logout } from '../../auth/store/thunks';
import { RootState } from '../../app/store';
import { CustomPolygon, LayerName, User, UserPolygon } from '../types';
import { deleteCustomPolygonById, fetchCustomPolygons, fetchPolygons, fetchUser, saveCustomPolygon } from './thunks';
import { ALL_KEY } from '../layers';

export const allLayerNames = [
  LayerName.LATEST,
  LayerName.GROUND_TRUTH,
  LayerName.SLIPPERINESS,
  LayerName.COVERAGE,
  LayerName.PAVEMENT,
  LayerName.POTHOLE,
  LayerName.SURFACE_EVENTS,
];

export const simplifiedLayerNames = [
  LayerName.LATEST_SIMPLIFIED,
  LayerName.GROUND_TRUTH_SIMPLIFIED,
  LayerName.COVERAGE,
  LayerName.SLIPPERINESS_SIMPLIFIED,
  LayerName.SURFACE_EVENTS_SIMPLIFIED,
];

export interface UserState {
  isLoading: boolean;
  isError: boolean;
  isGlobal: boolean;
  user: User | null;
  polygons: UserPolygon[];
  customPolygons: CustomPolygon[];
}

export const initialState: UserState = {
  isLoading: false,
  isError: false,
  isGlobal: false,
  user: null,
  polygons: [],
  customPolygons: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetStore: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(logout.pending.type, (state) => {
        state.user = null;
      })
      .addCase(fetchUser.pending.type, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchUser.rejected.type, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(fetchUser.fulfilled.type, (state, action: PayloadAction<User>) => {
        state.isLoading = false;
        state.isError = false;

        const user = action.payload;

        state.isGlobal = user.isGlobal;

        state.user = {
          ...user,
          layers: defineUserLayers(user),
        };
      })
      .addCase(fetchPolygons.pending.type, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchPolygons.rejected.type, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(fetchPolygons.fulfilled.type, (state, action: PayloadAction<UserPolygon[]>) => {
        state.isLoading = false;
        state.isError = false;
        state.polygons = action.payload;
      })
      .addCase(fetchCustomPolygons.pending.type, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchCustomPolygons.rejected.type, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(fetchCustomPolygons.fulfilled.type, (state, action: PayloadAction<CustomPolygon[]>) => {
        state.isLoading = false;
        state.isError = false;
        state.customPolygons = action.payload;
      })
      .addCase(deleteCustomPolygonById.pending.type, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(deleteCustomPolygonById.rejected.type, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(deleteCustomPolygonById.fulfilled.type, (state, action: PayloadAction<number>) => {
        state.isLoading = false;
        state.isError = false;
        state.customPolygons = state.customPolygons.filter((polygon) => polygon.id !== action.payload);
      })
      .addCase(saveCustomPolygon.pending.type, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(saveCustomPolygon.rejected.type, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(saveCustomPolygon.fulfilled.type, (state, action: PayloadAction<CustomPolygon>) => {
        state.isLoading = false;
        state.isError = false;
        state.customPolygons = [...state.customPolygons, action.payload];
      });
  },
});

export const { resetStore } = userSlice.actions;

export function selectIsLoading(state: RootState): boolean {
  return state.user.isLoading;
}

export function selectIsError(state: RootState): boolean {
  return state.user.isError;
}

export function selectUser(state: RootState): User | null {
  return state.user.user;
}

export function selectPolygons(state: RootState): UserPolygon[] {
  return state.user.polygons;
}

export function selectCustomPolygons(state: RootState): CustomPolygon[] {
  return state.user.customPolygons;
}

export function selectIsGlobalUser(state: RootState): boolean {
  return state.user.isGlobal;
}

export default userSlice.reducer;

function defineUserLayers(user: User): LayerName[] {
  if (user.isGlobal) {
    return simplifiedLayerNames;
  }

  if (user.layers.includes(ALL_KEY)) {
    return allLayerNames;
  }

  return user.layers as LayerName[];
}
