import React, { useEffect, useState } from 'react';
import { Tooltip, TooltipSide } from 'shared';
import { DataClassFactory } from '../../../../../classes/DataClassFactory';
import PieGraph from './PieGraphs';
import BarGraphs from './BarGraphs';
import { DashboardMode, DataType } from '../../../../../classes/AbstractData';
import { FunctionalRoadClassRating, GripRatingOption, GripsRating } from '../../../map/types';
import { getPercent, getRoundedFloat } from '../../../../../helpers/common';
import { dataSymbology, FunctionalRoadClassNames } from '../../../../../constants/dataSymbology';
import { AppType } from '../../../selection/types';
import { Data } from '../../../layers/types';
import { StorableFilters } from '../../../filter/types';
import { useAppSelector } from '../../../app/hooks';
import {
  selectSelectedAppType,
  selectSelectedLayer,
  selectSelectedReport,
  selectSelectedSubLayer,
} from '../../../selection/store/store';
import { comparingDataSelector, filteredDataSelector } from '../../../layers/store/store';
import { selectIsGlobalUser } from '../../../user/store/store';
import { selectedFiltersSelector } from '../../../filter/store/store';
import 'react-circular-progressbar/dist/styles.css';
import './_dashboard.scss';
import { LayerName } from '../../../user/types';

interface Props {
  isOpen: boolean;
  toggleIsOpen: () => void;
}

export interface FunctionalRoadClassDashboardState {
  arterial: {
    title: string;
    state: FunctionalRoadClassRating;
  };
  collector: {
    title: string;
    state: FunctionalRoadClassRating;
  };
  local: {
    title: string;
    state: FunctionalRoadClassRating;
  };
}

export interface DashboardState extends GripsRating {
  title: string;
  mode: DashboardMode;
  highColor: string;
  mediumColor: string;
  lowColor: string;
  functionalRoadClassDashboardState: FunctionalRoadClassDashboardState;
}

type ComparedValues = {
  high: GripRatingOption;
  medium: GripRatingOption;
  low: GripRatingOption;
  arterial: FunctionalRoadClassRating;
  collector: FunctionalRoadClassRating;
  local: FunctionalRoadClassRating;
};

export const AnalyticsDashboard: React.FC<Props> = (props: Props) => {
  const { isOpen, toggleIsOpen } = props;

  const [dashboardState, setDashboardState] = useState<DashboardState>({
    title: '',
    mode: DashboardMode.MANY,
    high: {
      title: '',
      length: 0,
      value: 0,
    },
    medium: {
      title: '',
      length: 0,
      value: 0,
    },
    low: {
      title: '',
      length: 0,
      value: 0,
    },
    highColor: '',
    mediumColor: '',
    lowColor: '',
    functionalRoadClassDashboardState: {
      arterial: {
        title: '',
        state: {
          length: 0,
          high: 0,
          medium: 0,
          low: 0,
        },
      },
      collector: {
        title: '',
        state: {
          length: 0,
          high: 0,
          medium: 0,
          low: 0,
        },
      },
      local: {
        title: '',
        state: {
          length: 0,
          high: 0,
          medium: 0,
          low: 0,
        },
      },
    },
  });

  const selectedAppType = useAppSelector(selectSelectedAppType);
  const selectedLayer = useAppSelector(selectSelectedLayer);
  const selectedSubLayer = useAppSelector(selectSelectedSubLayer);
  const selectedReport = useAppSelector(selectSelectedReport);
  const comparedData = useAppSelector(comparingDataSelector);
  const filteredData = useAppSelector(filteredDataSelector);
  const isGlobalUser = useAppSelector(selectIsGlobalUser);
  const selectedFilters = useAppSelector(selectedFiltersSelector);

  const compareValues = (
    data: Data[],
    appType: AppType,
    dataType: DataType,
    filters: StorableFilters,
  ): ComparedValues => {
    const high = {
      title: '',
      length: 0,
      value: 0,
    };
    const medium = {
      title: '',
      length: 0,
      value: 0,
    };
    const low = {
      title: '',
      length: 0,
      value: 0,
    };
    const arterial: FunctionalRoadClassRating = {
      length: 0,
      high: 0,
      medium: 0,
      low: 0,
    };
    const collector: FunctionalRoadClassRating = {
      length: 0,
      high: 0,
      medium: 0,
      low: 0,
    };
    const local: FunctionalRoadClassRating = {
      length: 0,
      high: 0,
      medium: 0,
      low: 0,
    };

    const dataCollection = DataClassFactory.getDataClass(data, selectedLayer || LayerName.GRIP, dataType, filters);
    const sortedByRoadClass = dataCollection.sortDataByFunctionalRoadClass(data);
    const { gripRating, functionalRoadClassRating } = dataCollection.getSortedData();
    const allDataCount = data.length;

    const functionalDataLength = dataCollection.getDataLength(sortedByRoadClass);
    const gripRatingLength = dataCollection.getDataLength(gripRating);
    arterial.length = functionalDataLength.arterial;
    collector.length = functionalDataLength.collector;
    local.length = functionalDataLength.local;

    const progressBarTitles = dataSymbology[dataCollection.getType() as DataType].progressBarTitles;

    high.title = progressBarTitles.high;
    medium.title = progressBarTitles.medium;
    low.title = progressBarTitles.low;

    if (dataType === DataType.GROUND_TRUTH) {
      arterial.high = getPercent(
        functionalRoadClassRating.high.arterial.length +
          functionalRoadClassRating.medium.arterial.length +
          functionalRoadClassRating.low.arterial.length,
        comparedData.length,
        2,
      );
      collector.high = getPercent(
        functionalRoadClassRating.high.collector.length +
          functionalRoadClassRating.medium.collector.length +
          functionalRoadClassRating.low.collector.length,
        comparedData.length,
        2,
      );
      local.high = getPercent(
        functionalRoadClassRating.high.local.length +
          functionalRoadClassRating.medium.local.length +
          functionalRoadClassRating.low.local.length,
        comparedData.length,
        2,
      );
      high.length = gripRatingLength.high + gripRatingLength.medium + gripRatingLength.low;
      high.value = getRoundedFloat(arterial.high + collector.high + local.high, 2);
    } else {
      high.value = getPercent(gripRating.high.length, allDataCount);
      high.length = gripRatingLength.high;
      medium.value = getPercent(gripRating.medium.length, allDataCount);
      medium.length = gripRatingLength.medium;
      low.value = getPercent(gripRating.low.length, allDataCount);
      low.length = gripRatingLength.low;

      arterial.high = getPercent(functionalRoadClassRating.high.arterial.length, sortedByRoadClass.arterial.length);
      arterial.medium = getPercent(functionalRoadClassRating.medium.arterial.length, sortedByRoadClass.arterial.length);
      arterial.low = getPercent(functionalRoadClassRating.low.arterial.length, sortedByRoadClass.arterial.length);

      collector.high = getPercent(functionalRoadClassRating.high.collector.length, sortedByRoadClass.collector.length);
      collector.medium = getPercent(
        functionalRoadClassRating.medium.collector.length,
        sortedByRoadClass.collector.length,
      );
      collector.low = getPercent(functionalRoadClassRating.low.collector.length, sortedByRoadClass.collector.length);

      local.high = getPercent(functionalRoadClassRating.high.local.length, sortedByRoadClass.local.length);
      local.medium = getPercent(functionalRoadClassRating.medium.local.length, sortedByRoadClass.local.length);
      local.low = getPercent(functionalRoadClassRating.low.local.length, sortedByRoadClass.local.length);
    }

    return { high, medium, low, arterial, collector, local };
  };

  useEffect(() => {
    if (!selectedLayer || !selectedAppType || !filteredData) return;

    if (selectedSubLayer === DataType.GROUND_TRUTH && !comparedData) return;

    const generalLayerExists = [LayerName.GRIP, LayerName.PAVEMENT, LayerName.POTHOLE].includes(selectedLayer);

    if (!generalLayerExists) {
      return;
    }

    if (selectedLayer === LayerName.GRIP && !selectedSubLayer) {
      return;
    }

    const dataCollection = DataClassFactory.getDataClass([], selectedLayer, selectedSubLayer, {});

    const mode = dataCollection.getDashboardMode();
    const colorsConfig = dataSymbology[dataCollection.getType()].colors;
    const dashboardTitle = dataSymbology[dataCollection.getType()].dashboardTitle;

    const comparisons = compareValues(filteredData, selectedAppType, selectedSubLayer as DataType, selectedFilters);

    let reportName = '';

    if (selectedReport) {
      reportName = selectedReport.report_name;
    }

    const fullDashboardTitle = selectedAppType === AppType.GRIP ? dashboardTitle : `${dashboardTitle} - ${reportName}`;

    setDashboardState({
      title: fullDashboardTitle,
      high: comparisons.high,
      medium: comparisons.medium,
      low: comparisons.low,
      mode,
      highColor: colorsConfig.high,
      mediumColor: colorsConfig.medium,
      lowColor: colorsConfig.low,
      functionalRoadClassDashboardState: {
        arterial: {
          title: FunctionalRoadClassNames.ARTERIAL,
          state: comparisons.arterial,
        },
        collector: {
          title: FunctionalRoadClassNames.COLLECTORS,
          state: comparisons.collector,
        },
        local: {
          title: FunctionalRoadClassNames.LOCAL,
          state: comparisons.local,
        },
      },
    });
  }, [filteredData, comparedData, selectedAppType, selectedLayer]);

  if (
    !selectedLayer ||
    ![LayerName.GRIP, LayerName.PAVEMENT, LayerName.POTHOLE].includes(selectedLayer) ||
    selectedSubLayer === 'current-grip'
  ) {
    return null;
  }

  return (
    <>
      <div className="controls-btn-container statistics-controls">
        <Tooltip text="Statistics - visual" side={TooltipSide.LEFT}>
          <button
            className={`controls-btn ${isOpen ? 'active' : ''}`}
            data-testid="map-cursor"
            onClick={toggleIsOpen}
            disabled={isGlobalUser}
          >
            <i className="controls-icon icon icon-statistics" />
          </button>
        </Tooltip>
      </div>
      <div className={`dashboard ${isOpen ? 'isOpen' : 'isClosed'}`} data-testid="dashboard">
        <div className="dashboard-body">
          <h2 className="dashboard-body-title">{dashboardState.title}</h2>
          <i className="dashboard-arrow-icon icon icon-arrow" onClick={toggleIsOpen} />
          <PieGraph barsState={dashboardState} />
        </div>
        <div className="dashboard-body dashboard-road">
          <h2 className="dashboard-body-title">Breakdown by road class</h2>
          <BarGraphs barsState={dashboardState} />
        </div>
      </div>
    </>
  );
};

export default AnalyticsDashboard;
