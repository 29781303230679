import { Data } from '../layers/types';
import { PolygonPrototype } from '../selection/types';
import { DataToPolygonId } from '../layers/store/store';
import { CustomPick } from './types';

const workerFn: () => void = () => {
  self.onmessage = (e) => {
    const parsedData = JSON.parse(e.data);
    const data: Data[] = parsedData[0];
    const polygonData: CustomPick<PolygonPrototype, 'polygon' | 'id'>[] = parsedData[1];

    if (data) {
      const getCoordinatesFromGeometry = (geometry: string, num: number) => {
        const coordsString = geometry.slice(num, geometry.length - 1);
        const dividedCoords = coordsString.split(',');

        return dividedCoords.map((item) => item.split(' ').map((item) => parseFloat(item)));
      };

      const isPointInPolygon = (x: number, y: number, polygon: number[][]) => {
        const polygonLength = polygon.length;
        let lastItem = polygonLength - 1;
        let result = false;

        polygon.forEach((point, index) => {
          if (x === point[0] && y === point[1]) {
            result = true;
          }

          if (point[1] > y !== polygon[lastItem][1] > y) {
            const slope =
              (x - point[0]) * (polygon[lastItem][1] - point[1]) - (polygon[lastItem][0] - point[0]) * (y - point[1]);

            if (slope === 0) {
              result = true;
            }

            if (slope < 0 !== polygon[lastItem][1] < point[1]) {
              result = !result;
            }
          }

          lastItem = index;
        });

        return result;
      };

      const dataToPolygonId: DataToPolygonId = {};

      polygonData.forEach(({ id, polygon }) => {
        const polygonGeometry = getCoordinatesFromGeometry(polygon, 9);

        data.forEach((dataItem) => {
          const coords = getCoordinatesFromGeometry(dataItem.data.geometry, 11)[0];
          const lng = coords[0];
          const lat = coords[1];

          if (isPointInPolygon(lng, lat, polygonGeometry)) {
            if (!dataToPolygonId[id]) dataToPolygonId[id] = [];
            dataToPolygonId[id].push(dataItem);
          }
        });
      });

      self.postMessage(dataToPolygonId);
    } else {
      self.postMessage([]);
    }
  };
};

let code = workerFn.toString();
code = code.substring(code.indexOf('{') + 1, code.lastIndexOf('}'));

const blob = new Blob([code], { type: 'application/javascript' });
const worker = URL.createObjectURL(blob);

export default worker;
