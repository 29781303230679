import { useEffect, useRef, useState } from 'react';
import { intersection } from 'lodash';
import { CustomPick } from '../types';
import { selectedFiltersSelector } from '../store/store';
import { filterSelectionsToAppType } from '../../../../constants/filterSelectionsToAppType';
import { Data } from '../../layers/types';
import { useWorkers } from './useWorkers';
import { dataSelector, DataToPolygonId, setFilteredData } from '../../layers/store/store';
import { PolygonPrototype } from '../../selection/types';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  selectSelectedAppType,
  selectSelectedLayer,
  selectSelectedPolygonIds,
  selectSelectedSubLayer,
} from '../../selection/store/store';
import { selectCustomPolygons, selectPolygons } from '../../user/store/store';

export const useFilter = (): void => {
  const [dataToPolygonId, setDataToPolygonId] = useState<DataToPolygonId>({});

  const dataInProcess = useRef<DataToPolygonId>({});

  const selectedAppType = useAppSelector(selectSelectedAppType);
  const selectedLayer = useAppSelector(selectSelectedLayer);
  const selectedSubLayer = useAppSelector(selectSelectedSubLayer);
  const selectedFilters = useAppSelector(selectedFiltersSelector);
  const data = useAppSelector(dataSelector);
  const polygons = useAppSelector(selectPolygons);
  const customPolygons = useAppSelector(selectCustomPolygons);
  const selectedPolygonIds = useAppSelector(selectSelectedPolygonIds);

  const availableThreads = (navigator.hardwareConcurrency || 4) - 1;

  const { runWorkers } = useWorkers({ availableThreads: availableThreads });

  const dispatch = useAppDispatch();

  function* messageCountGenerator(count: number) {
    let counter = 1;

    for (let i = 0; count >= i; i++) {
      yield counter++;
    }
  }

  const messageCounter = messageCountGenerator(availableThreads);

  const workerFunction = ({ data }: MessageEvent<DataToPolygonId>) => {
    Object.entries(data).forEach(([id, data]) => {
      if (!dataInProcess.current[Number(id)]) dataInProcess.current[Number(id)] = [];

      dataInProcess.current[Number(id)].push(...data);
    });

    if (messageCounter.next().value === availableThreads) {
      setDataToPolygonId(dataInProcess.current);
    }
  };

  useEffect(() => {
    dispatch(setFilteredData([]));
    setDataToPolygonId({});

    if (data.length && polygons.length) {
      dataInProcess.current = {};
      const polygonData: CustomPick<PolygonPrototype, 'polygon' | 'id'>[] = [];

      polygons.forEach((polygon) => {
        polygonData.push({ id: polygon.id, polygon: polygon.polygon });

        polygon.children.forEach((childrenPolygon) => {
          polygonData.push({ id: childrenPolygon.id, polygon: childrenPolygon.polygon });
        });
      });

      customPolygons.forEach((polygon) => {
        polygonData.push({ id: polygon.id, polygon: polygon.polygon });
      });

      runWorkers(data, polygonData, workerFunction);
    }
  }, [data]);

  useEffect(() => {
    if (!selectedAppType || !selectedLayer || !Object.keys(dataToPolygonId).length) return;
    const data: Data[] = [];

    const basePolygon = polygons[0];

    if (selectedPolygonIds.includes(basePolygon.id)) {
      if (dataToPolygonId[basePolygon.id]) data.push(...dataToPolygonId[basePolygon.id]);
    }

    basePolygon.children.forEach((childrenPolygon) => {
      if (selectedPolygonIds.includes(childrenPolygon.id)) {
        if (dataToPolygonId[childrenPolygon.id]) data.push(...dataToPolygonId[childrenPolygon.id]);
      }
    });

    let filteredData = intersection(data);

    Object.entries(selectedFilters).forEach(([name, options]) => {
      const currentFilter = filterSelectionsToAppType[selectedAppType][name];

      if (currentFilter?.optionValues) {
        const values = Object.values(currentFilter?.optionValues);

        const highValues = values[0] as number[];
        const lowValues = values[2] as number[];

        if (lowValues && highValues && currentFilter.function) {
          filteredData = currentFilter.function(options, filteredData, lowValues[1], highValues[0]);
        }
      }
    });

    dispatch(setFilteredData(filteredData));
  }, [dataToPolygonId, polygons, customPolygons, selectedFilters, selectedSubLayer, selectedLayer, selectedAppType]);
};
