import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Viewport } from 'shared/src/components/Map/types';
import { DEFAULT_PITCH, DEFAULT_ZOOM } from '../constants';

export interface SelectionState {
  isLoading: boolean;
  isError: boolean;
  viewport: Viewport;
  bbox: number[][] | null;
}

export const initialState: SelectionState = {
  isLoading: false,
  isError: false,
  viewport: {
    lng: 0,
    lat: 0,
    zoom: DEFAULT_ZOOM,
    pitch: DEFAULT_PITCH,
  },
  bbox: null,
};

export const selectionSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setViewport: (state, action: PayloadAction<Viewport>) => {
      state.viewport = action.payload;
    },
    setBbox: (state, action: PayloadAction<number[][]>) => {
      state.bbox = action.payload;
    },
    resetStore: () => {
      return initialState;
    },
  },
});

export const { setBbox, setViewport, resetStore } = selectionSlice.actions;

export function isLoadingSelector(state: RootState): boolean {
  return state.map.isLoading;
}

export function isErrorSelector(state: RootState): boolean {
  return state.map.isError;
}

export function viewportSelector(state: RootState): Viewport {
  return state.map.viewport;
}

export function bboxSelector(state: RootState): number[][] | null {
  return state.map.bbox;
}

export default selectionSlice.reducer;
