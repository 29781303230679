export const API_AUTH_ERROR = 'Authorization required!';
export const API_POLYGON_ALREADY_EXISTS_ERROR = 'Custom Polygon with this title already exist';

export const DATA_FACTORY_INCORRECT_SUBTYPE = 'Not such subtype of grip data';
export const DATA_FACTORY_INCORRECT_TYPE = 'Not such data type';

export const DATA_SERVICE_FETCH_ERROR = 'Something went wrong while fetching data by polygon';

export const AUTH_LOGIN_ERROR = 'Something went wrong while logging in';

export const POLYGON_VALIDATION_TITLE_TO_LONG = 'Title to long';
export const POLYGON_SAVING_ERROR = 'Save of polygon :polygon failed';
export const POLYGON_NAME_ALREADY_USED = 'This name is already used';
