import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { ResponseCodes } from '../interfaces';
import { ILocalStorageService, LocalStorageService } from '../app/services/LocalStorageService';
import { REACT_APP_API_GW_ROUTE } from '../constants/api';

export const API_REFRESH_URL = `${REACT_APP_API_GW_ROUTE}/auth/refresh`;

const localStorageService: ILocalStorageService = new LocalStorageService();

export const request = axios.create({
  baseURL: REACT_APP_API_GW_ROUTE,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

request.interceptors.request.use((req: AxiosRequestConfig) => {
  const authToken = localStorageService.getItem<string>('Authorization');

  req.headers = {
    ...req.headers,
    Authorization: `Bearer ${authToken}`,
  };

  return req;
});

export const statusCodesToRefreshTheTokensFor = new Set([ResponseCodes.UNAUTHORIZED, ResponseCodes.FORBIDDEN]);

request.interceptors.response.use(
  async (response) => response,
  async (error: AxiosError) => {
    if (!error.response) {
      throw error;
    }

    if (statusCodesToRefreshTheTokensFor.has(error.response.status)) {
      await refreshTokenOrFireLogout();
      return;
    }

    throw error;
  },
);

const refreshTokenOrFireLogout = async () => {
  try {
    const { newAccessToken, newRefreshToken } = await refreshToken();
    localStorageService.setItem('Authorization', newAccessToken);
    localStorageService.setItem('refresh-token', newRefreshToken);
    window.location.reload();
  } catch (e) {
    localStorageService.removeItem('Authorization');
    localStorageService.removeItem('refresh-token');
    window.dispatchEvent(new CustomEvent('forceLogout'));
  }
};

async function refreshToken(): Promise<RefreshedToken> {
  const refreshToken = localStorageService.getItem('refresh-token');
  if (!refreshToken) {
    throw new Error('No refresh token');
  }

  const res = await axios.post<RefreshTokenResponse>(
    API_REFRESH_URL,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${refreshToken}`,
      },
    },
  );
  return {
    newAccessToken: res.data.access_token,
    newRefreshToken: res.data.refresh_token,
  };
}

interface RefreshTokenResponse {
  status: string;
  access_token: string;
  refresh_token: string;
}

interface RefreshedToken {
  newAccessToken: string;
  newRefreshToken: string;
}
