import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { login, logout } from './thunks';
import { RootState } from '../../app/store';
import { AuthResponse, UserAuthState } from '../types';

export interface AuthState {
  authState: UserAuthState;
  userId: number | null;
}

export const initialState: AuthState = {
  authState: 'LOGGED_OUT',
  userId: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuthError: (state) => {
      state.authState = 'LOGGED_OUT';
    },
    resetStore: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(login.pending.type, (state) => {
        state.authState = 'LOGGING_IN';
      })
      .addCase(login.rejected.type, (state) => {
        state.authState = 'FAILED_LOGGING_IN';
      })
      .addCase(login.fulfilled.type, (state, action: PayloadAction<AuthResponse>) => {
        state.authState = 'LOGGED_IN';
        state.userId = action.payload.user_id;
      })
      .addCase(logout.pending.type, (state) => {
        state.authState = 'LOGGED_OUT';
        state.userId = null;
      });
  },
});

export const { resetAuthError, resetStore } = authSlice.actions;

export function selectAuthState(state: RootState): UserAuthState {
  return state.auth.authState;
}

export function selectUserId(state: RootState): number | null {
  return state.auth.userId;
}

export default authSlice.reducer;
