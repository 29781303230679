import React from 'react';
import cn from 'classnames';

interface Props {
  title: string;
  isSelected: boolean;
  isSelectedAll: boolean;
}

export const SnapshotSelect: React.FC<Props> = (props) => {
  const { title, isSelected, isSelectedAll, children } = props;

  return (
    <>
      <div className="sidebar-select">
        <span className="sidebar-select-title">{title}</span>
        <div className="sidebar-select-btns">
          <button className={cn('sidebar-select-btn', { isActive: isSelectedAll })}>All Layers</button>
          <button className={cn('sidebar-select-btn', { isActive: isSelected && !isSelectedAll })}>Selected</button>
        </div>
      </div>
      {children}
    </>
  );
};
