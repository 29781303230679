import { StorableFilters } from '../app/modules/filter/types';
import { SortedByFunctionalRoadClass, SortedByGripRating, SortedData, SortedGrips } from '../app/modules/map/types';
import { ARTERIAL_ROAD_CLASS_VALUE, COLLECTOR_ROAD_CLASS_VALUE, LOCAL_ROAD_CLASS_VALUE } from '../constants/dataParms';
import { Data } from '../app/modules/layers/types';
import { getGripQuality } from '../constants/filterSelectionsToAppType';
import { parse } from 'wellknown';
import { lineDistance } from '@turf/turf';

export enum DataType {
  LATEST = 'current-grip',
  COVERAGE = 'coverage',
  SLIPPERINESS = 'slipperiness',
  BASELINE = 'baseline',
  GROUND_TRUTH = 'ground-truth',
  PAVEMENT = 'pavement',
  POTHOLE = 'pothole',
  SURFACE_EVENTS = 'surface-events',
  LATEST_SIMPLIFIED = 'latest-simplified',
  SLIPPERINESS_SIMPLIFIED = 'slipperiness-simplified',
  BASELINE_SIMPLIFIED = 'baseline-simplified',
  GROUND_TRUTH_SIMPLIFIED = 'ground-truth-simplified',
  PAVEMENT_SIMPLIFIED = 'pavement-simplified',
  POTHOLE_SIMPLIFIED = 'pothole-simplified',
  SURFACE_EVENTS_SIMPLIFIED = 'surface-events-simplified',
}

export enum DashboardMode {
  SINGLE = 'single',
  MANY = 'many',
}

export enum GripQuality {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export type DataLength = Record<string, number>;

export abstract class AbstractData {
  protected data: Data[];
  protected filters: StorableFilters;
  protected layerVisibility: boolean;

  protected abstract type: DataType;
  protected abstract dashboardMode: DashboardMode;

  protected abstract GROUPING_LOW_VALUE: number;
  protected abstract GROUPING_HIGH_VALUE: number;

  constructor(data: Data[], filters: StorableFilters, layerVisibility: boolean) {
    this.data = data;
    this.filters = filters;
    this.layerVisibility = layerVisibility;
  }

  public getType(): DataType {
    return this.type;
  }

  public getDashboardMode(): DashboardMode {
    return this.dashboardMode;
  }

  public setData(data: Data[]): void {
    this.data = data;
  }

  public getSortedData(): SortedGrips {
    const sortedByGripRating = this.sortData();
    const high = this.sortDataByFunctionalRoadClass(sortedByGripRating.high);
    const medium = this.sortDataByFunctionalRoadClass(sortedByGripRating.medium);
    const low = this.sortDataByFunctionalRoadClass(sortedByGripRating.low);
    return { gripRating: sortedByGripRating, functionalRoadClassRating: { high, medium, low } };
  }

  public getData(): Data[] {
    return this.data;
  }

  public getDataLength(data: SortedData): DataLength {
    return Object.entries(data).reduce(
      (prev, [name, value]) => ({
        ...prev,
        [name]: value.reduce((previousValue, currentValue) => {
          const geometry = parse(currentValue.data.geometry);

          if (geometry) {
            const distance = lineDistance(
              {
                type: 'Feature',
                properties: {},
                geometry,
              },
              { units: 'meters' },
            );

            return previousValue + distance;
          }

          return previousValue;
        }, 0),
      }),
      {},
    );
  }

  public sortData(): SortedByGripRating {
    const sortedGrips: SortedByGripRating = {
      low: [],
      medium: [],
      high: [],
    };

    this.data.forEach((dataItem) => {
      const { value, severity } = dataItem.data;
      if (this.type !== DataType.POTHOLE) {
        this.groupGrip(value, dataItem, sortedGrips);
      } else {
        this.groupGrip(severity, dataItem, sortedGrips);
      }
    });

    return sortedGrips;
  }

  public sortDataByFunctionalRoadClass(data: Data[]): SortedByFunctionalRoadClass {
    const sortedGrips: SortedByFunctionalRoadClass = { arterial: [], collector: [], local: [] };

    data.forEach((grip) => {
      if (grip.data.functional_road_class <= ARTERIAL_ROAD_CLASS_VALUE) {
        sortedGrips.arterial.push(grip);
      } else if (grip.data.functional_road_class === COLLECTOR_ROAD_CLASS_VALUE) {
        sortedGrips.collector.push(grip);
      } else if (grip.data.functional_road_class >= LOCAL_ROAD_CLASS_VALUE) {
        sortedGrips.local.push(grip);
      }
    });

    return sortedGrips;
  }

  private groupGrip(groupedValue: number, item: Data, object: { low: Data[]; medium: Data[]; high: Data[] }) {
    const gripQuality = getGripQuality(groupedValue, this.GROUPING_LOW_VALUE, this.GROUPING_HIGH_VALUE);

    if (gripQuality === GripQuality.LOW) {
      object.low.push(item);
    } else if (gripQuality === GripQuality.MEDIUM) {
      object.medium.push(item);
    } else if (gripQuality === GripQuality.HIGH) {
      object.high.push(item);
    }
  }
}
