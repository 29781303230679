import { useEffect } from 'react';
import { fetchData, fetchReportData } from '../store/thunks';
import { layers, LayerConfig } from '../../../../configs/layers';
import { DataType } from '../../../../classes/AbstractData';
import { fetchReports } from '../../selection/store/thunks';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectSelectedLayer, selectSelectedReportId, selectSelectedSubLayer } from '../../selection/store/store';
import { selectIsGlobalUser, selectPolygons } from '../../user/store/store';
import { LayerName } from '../../user/types';

export const useDataLoad = (): void => {
  const selectedLayer = useAppSelector(selectSelectedLayer);
  const selectedSubLayer = useAppSelector(selectSelectedSubLayer);
  const selectedReportId = useAppSelector(selectSelectedReportId);
  const polygons = useAppSelector(selectPolygons);
  const isGlobalUser = useAppSelector(selectIsGlobalUser);

  const dispatch = useAppDispatch();

  // load json based data or report based data
  useEffect(() => {
    if (!selectedLayer || !polygons.length) return;

    const layerConfig = layers[selectedLayer];

    if (!layerConfig) return;

    if (layerConfig.subLayers) {
      const subLayerConfig = layerConfig.subLayers.find((subLayer) => subLayer.layerName === selectedSubLayer);

      if (subLayerConfig) {
        loadDataOrReports(subLayerConfig);
        return;
      }
    }

    loadDataOrReports(layerConfig);
  }, [selectedLayer, selectedSubLayer, selectedReportId, polygons]);

  const loadDataOrReports = (layerConfig: LayerConfig): void => {
    if (isGlobalUser) return;
    if (!layerConfig.mapSource) return;

    if (layerConfig.mapSource.type === 'json' || layerConfig.statisticSource?.type === 'json') {
      dispatch(fetchData({ regionId: polygons[0].id, dataType: layerConfig.layerName as DataType }));
    }

    if (layerConfig.mapSource.type === 'report' || layerConfig.statisticSource?.type === 'report') {
      dispatch(fetchReports({ polygonId: polygons[0].id, layer: layerConfig.layerName as LayerName }));
    }

    if (selectedReportId) {
      dispatch(
        fetchReportData({
          polygonId: polygons[0].id,
          layer: layerConfig.layerName as DataType,
          reportId: selectedReportId,
        }),
      );
    }
  };
};
