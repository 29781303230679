import 'reflect-metadata';
import { Container } from 'inversify';
import MapService, { IMapService } from '../app/modules/map/MapService';
import { ILocalStorageService, LocalStorageService } from '../app/services/LocalStorageService';
import {
  DataSubscriptionService,
  IDataSubscriptionService,
} from '../app/modules/layers/services/DataSubscriptionService';

const container = new Container();
container.bind<IMapService>('MapService').to(MapService);
container.bind<IDataSubscriptionService>('DataSubscriptionService').to(DataSubscriptionService);
container.bind<ILocalStorageService>('LocalStorageService').to(LocalStorageService);

export { container };
