import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Dropdown, DropdownItem } from 'shared';
import { SelectedReports } from '../../types';
import { useSelector } from 'react-redux';
import { selectIsGlobalUser } from '../../../user/store/store';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectReports, selectSelectedReportId, setSelectedReportId } from '../../store/store';

export const Reports: React.FC = () => {
  const isGlobalUser = useSelector(selectIsGlobalUser);

  const reports = useAppSelector(selectReports);
  const selectedReportId = useAppSelector(selectSelectedReportId);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!selectedReportId && reports.latestReport) {
      selectReport(reports.latestReport.id);
    }
  }, [selectedReportId, reports]);

  const getDropdownTitle = () => {
    let reportName = 'Current';

    if (reports.latestReport?.id === selectedReportId) {
      reportName = 'Latest report';
    }

    if (reports.previousReport?.id === selectedReportId) {
      reportName = `Previous report (${reports.previousReport.report_name})`;
    }

    return reportName;
  };

  const selectReport = (reportId: string): void => {
    dispatch(setSelectedReportId(reportId));
  };

  const isDropdownDisabled = isGlobalUser || !reports.latestReport;

  return (
    <Dropdown
      name={getDropdownTitle()}
      className={`header-dropdown ${!isDropdownDisabled ? 'header-vehicles' : null}`}
      disabled={isDropdownDisabled}
    >
      <ReportsList reports={reports} onSelect={selectReport} />
    </Dropdown>
  );
};

interface ReportsListProps {
  reports: SelectedReports;
  onSelect: (reportId: string) => void;
}

const ReportsList: React.FC<ReportsListProps> = (props: ReportsListProps) => {
  const { reports, onSelect } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  const onToggleShowMore = (e: SyntheticEvent) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  if (!reports.latestReport) {
    return null;
  }

  return (
    <>
      <div className="header-vehicles-head">
        <DropdownItem onClick={onSelect.bind(this, reports.latestReport.id)} key={reports.latestReport.id}>
          Latest report
        </DropdownItem>
        {reports.previousReport ? (
          <DropdownItem onClick={onSelect.bind(this, reports.previousReport.id)} key={reports.previousReport.id}>
            Previous report ({reports.previousReport.report_name})
          </DropdownItem>
        ) : null}
      </div>
      {reports.restReports.length ? (
        <>
          {isExpanded && (
            <div className="header-vehicles-container">
              <span className="header-vehicles-title">Previous reports</span>
              <div className="header-vehicles-list">
                {reports.restReports.map((report) => {
                  return (
                    <DropdownItem onClick={onSelect.bind(this, report.id)} key={report.id}>
                      {report.report_name}
                    </DropdownItem>
                  );
                })}
              </div>
            </div>
          )}
          <div onClick={onToggleShowMore} className="header-vehicles-btn" data-testid="reports-more-btn">
            {isExpanded ? (
              <>
                <span>Hide</span>
                <i className="header-vehicles-icon icon icon-arrow active" />
              </>
            ) : (
              <>
                <span>Show more</span> <i className="header-vehicles-icon icon icon-arrow" />
              </>
            )}
          </div>
        </>
      ) : null}
    </>
  );
};

export default Reports;
