import React, { useState } from 'react';
import { ConfirmModal, Dropdown } from 'shared';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectIsGlobalUser, selectPolygons } from '../../../user/store/store';
import { deleteCustomPolygonById } from '../../../user/store/thunks';
import { PolygonsList } from './Dropdown/PolygonsList';
import { UserPolygon } from '../../../user/types';

export const NONE_SELECTED_DROPDOWN_TEXT = 'None selected';

const PolygonSelector: React.FC = () => {
  const [polygonIdForDelete, setPolygonIdForDelete] = useState<number | null>(null);

  const polygons = useAppSelector(selectPolygons);
  const isGlobalUser = useAppSelector(selectIsGlobalUser);

  const dispatch = useAppDispatch();

  const savePolygonId = (id: number) => {
    setPolygonIdForDelete(id);
  };

  const confirmPolygonDeleting = async () => {
    if (!polygonIdForDelete) return;

    dispatch(deleteCustomPolygonById(polygonIdForDelete));
    setPolygonIdForDelete(null);
  };

  const cancelPolygonDeleting = () => {
    setPolygonIdForDelete(null);
  };

  return (
    <>
      <Dropdown
        name={dropdownTitleText(polygons, isGlobalUser)}
        className="header-dropdown"
        closeOnClick={false}
        disabled={isGlobalUser}
      >
        <PolygonsList onClickDeletePolygon={savePolygonId} />
      </Dropdown>
      <ConfirmModal
        isOpen={polygonIdForDelete !== null}
        onConfirm={confirmPolygonDeleting}
        onCancel={cancelPolygonDeleting}
        title="Are you sure?"
        cancelBtnText="Cancel"
        confirmBtnText="OK"
      />
    </>
  );
};

function dropdownTitleText(polygons: UserPolygon[], isGlobalUser: boolean): string {
  if (isGlobalUser) {
    return NONE_SELECTED_DROPDOWN_TEXT;
  }

  return polygons.length ? polygons[0].title : NONE_SELECTED_DROPDOWN_TEXT;
}

export default PolygonSelector;
