import { LayerConfig } from './index';
import { getCoordinatesFromLineGeometry, getRoundedFloat, makeUrl } from '../../helpers/common';
import { API_POLYGON_DATA_URL } from '../../constants/api';
import { Data } from '../../app/modules/layers/types';
import { DEFAULT_MAX_ZOOM, DEFAULT_MIN_ZOOM, DEFAULT_WW_MIN_ZOOM } from '../../constants/map';
import {
  ARTERIAL_ROAD_CLASS_VALUE,
  COLLECTOR_ROAD_CLASS_VALUE,
  LOCAL_ROAD_CLASS_VALUE,
  PAVEMENT_DATA_HIGH_VALUE,
  PAVEMENT_DATA_LOW_VALUE,
} from '../../constants/dataParms';
import { FilterType } from '../../app/modules/filter/types';
import { filterByPaser, filterByRoadClass } from '../../app/modules/filter/filters';

export const pavement: LayerConfig = {
  layerName: 'pavement',
  isLiveLayer: false,
  mapSource: {
    type: 'report',
    source: () => ({
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    }),
    mapper: (data: Data[]) => ({
      type: 'FeatureCollection',
      features: data.map((item) => {
        return {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: getCoordinatesFromLineGeometry(item.data.geometry),
          },
          properties: {
            subSegmentId: item.id,
            title: 'Pothole',
            error: item.data.error,
            functional_road_class: item.data.functional_road_class,
            geometry: item.data.geometry,
            quality: item.data.quality,
            timestamp: item.data.timestamp,
            value: item.data.value,
            coords: getCoordinatesFromLineGeometry(item.data.geometry),
          },
        };
      }),
    }),
  },
  mapLayers: [
    {
      id: 'pavement',
      source: 'pavement',
      type: 'line',
      minzoom: DEFAULT_WW_MIN_ZOOM,
      paint: {
        'line-width': [
          'let',
          'width',
          [
            'case',
            ['<', ['get', 'functional_road_class'], 4],
            7,
            ['==', ['get', 'functional_road_class'], 4],
            4,
            ['==', ['get', 'functional_road_class'], 5],
            1,
            1,
          ],
          [
            'step',
            ['zoom'],
            1,
            DEFAULT_MIN_ZOOM,
            ['case', ['>', ['-', ['var', 'width'], 5], 0], ['-', ['var', 'width'], 5], 1],
            DEFAULT_MAX_ZOOM - 4,
            ['case', ['>', ['-', ['var', 'width'], 3], 0], ['-', ['var', 'width'], 3], 1],
            DEFAULT_MAX_ZOOM - 3,
            ['+', ['var', 'width'], 0],
            DEFAULT_MAX_ZOOM - 1,
            ['+', ['var', 'width'], 4],
          ],
        ],
        'line-color': [
          'string',
          [
            'let',
            'quality',
            ['get', 'value'],
            [
              'case',
              ['all', ['>=', ['var', 'quality'], 0], ['<', ['var', 'quality'], PAVEMENT_DATA_LOW_VALUE]],
              '#eb3754',
              [
                'all',
                ['>=', ['var', 'quality'], PAVEMENT_DATA_LOW_VALUE],
                ['<', ['var', 'quality'], PAVEMENT_DATA_HIGH_VALUE],
              ],
              '#ffba54',
              ['all', ['>=', ['var', 'quality'], PAVEMENT_DATA_HIGH_VALUE]],
              '#00bf9e',
              'blue',
            ],
          ],
        ],
      },
    },
  ],
  statisticSource: {
    type: 'report',
    url: (regionId: string) => makeUrl(API_POLYGON_DATA_URL, { layer: 'pavement', regionId }),
  },
  symbology: {
    title: 'Pavement rating',
    iconClass: 'icon-wheel',
    tooltip: {
      icon: 'icon-tooltip-pavement',
      heading: 'Pavement rating',
      fieldsCreator: (data) => [
        { title: 'PASER', value: data.value.toString() },
        { title: 'Quality[%]', value: getRoundedFloat(Number(data.quality), 2).toString() },
        { title: 'Road class', value: data.functional_road_class.toString() },
        {
          title: 'Segment start',
          value: `${getRoundedFloat(Number(data.coords[0][1]), 5)}°N,${getRoundedFloat(
            Number(data.coords[0][0]),
            5,
          )}°E`,
        },
        {
          title: 'Segment end',
          value: `${getRoundedFloat(Number(data.coords[data.coords.length - 1][1]), 5)}°N,${getRoundedFloat(
            Number(data.coords[data.coords.length - 1][0]),
            5,
          )}°E`,
        },
      ],
    },
  },
  filters: {
    paserFilter: {
      title: 'PASER rating',
      filterName: 'paserFilter',
      filterableValue: 'value',
      filterType: FilterType.BUTTONS,
      defaultState: {
        good: true,
        fair: true,
        poor: true,
      },
      options: [
        {
          title: 'Good',
          name: 'good',
          values: [PAVEMENT_DATA_HIGH_VALUE, 999],
        },
        {
          title: 'Fair',
          name: 'fair',
          values: [PAVEMENT_DATA_LOW_VALUE, PAVEMENT_DATA_HIGH_VALUE],
        },
        {
          title: 'Poor',
          name: 'poor',
          values: [0, PAVEMENT_DATA_LOW_VALUE],
        },
      ],
      dataFilterFunction: filterByPaser,
    },
    functionalRoadClassFilter: {
      title: 'Functional road class',
      filterName: 'functionalRoadClassFilter',
      filterableValue: 'functional_road_class',
      filterType: FilterType.BUTTONS,
      defaultState: {
        arterial: true,
        collector: true,
        local: true,
      },
      options: [
        {
          title: 'Arterial',
          name: 'arterial',
          values: [0, ARTERIAL_ROAD_CLASS_VALUE + 1],
        },
        {
          title: 'Collector',
          name: 'collector',
          values: [COLLECTOR_ROAD_CLASS_VALUE, COLLECTOR_ROAD_CLASS_VALUE + 1],
        },
        {
          title: 'Local',
          name: 'local',
          values: [LOCAL_ROAD_CLASS_VALUE, LOCAL_ROAD_CLASS_VALUE + 1],
        },
      ],
      dataFilterFunction: filterByRoadClass,
    },
    geometryFilter: {
      title: '',
      filterName: 'geometryFilter',
      filterableValue: 'geometry',
      filterType: FilterType.GEOMETRY,
      defaultState: {
        geometryFilter: true,
      },
      isHidden: true,
    },
  },
};
