import { RoadType } from '../app/modules/map/types';

export const GRIP_DATA_LOW_VALUE = 0.4;
export const GRIP_DATA_HIGH_VALUE = 0.7;

export const BASELINE_GRIP_DATA_LOW_VALUE = 0.2;
export const BASELINE_GRIP_DATA_HIGH_VALUE = 1.2;

export const PAVEMENT_DATA_LOW_VALUE = 4;
export const PAVEMENT_DATA_HIGH_VALUE = 7;

export const ARTERIAL_ROAD_CLASS_VALUE = 3;
export const COLLECTOR_ROAD_CLASS_VALUE = 4;
export const LOCAL_ROAD_CLASS_VALUE = 5;

export const POTHOLE_DATA_LOW_VALUE = 3.5;
export const POTHOLE_DATA_HIGH_VALUE = 7;

export const TREATMENT_PRIORITY_LOW_VALUE = 3.5;
export const TREATMENT_PRIORITY_HIGH_VALUE = 7;

export const lineWidthToRoadType = {
  [RoadType.ARTERIAL]: 7,
  [RoadType.COLLECTOR]: 4,
  [RoadType.LOCAL]: 1,
};

export const DATA_SEGMENTS_PER_PAGE = 10000;
