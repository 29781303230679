import React from 'react';
import { Login as LoginComponent, StatusBar } from 'shared';
import { useLogin } from '../../hooks/useLogin';
import './styles.scss';

const LoginPage: React.FC = () => {
  const { form, errors, onChangeForm, onSubmit, onCancelError, loginState } = useLogin();

  return (
    <>
      <StatusBar
        isError={false}
        isLoading={loginState === 'LOGGING_IN'}
        onTryAgain={onSubmit}
        onClickCancel={onCancelError}
        progressSpeed={1}
        dependencies={[loginState]}
      />
      <LoginComponent errors={errors} fields={form} onChange={onChangeForm} onSubmit={onSubmit} />
    </>
  );
};

export default LoginPage;
