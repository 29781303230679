import React from 'react';
import { getDistance, getFlooredFloat } from '../../../helpers/common';
import { unitName, Units } from '../../modules/map/types';
import { useAppSelector } from '../../modules/app/hooks';
import { selectUser } from '../../modules/user/store/store';

interface Props {
  value: number;
  showUnit?: boolean;
  round?: boolean;
  digitsAfterPoint?: number;
}

export const FormattedUnits: React.FC<Props> = (props: Props) => {
  const { value, showUnit = false, round = false, digitsAfterPoint = 0 } = props;

  const user = useAppSelector(selectUser);

  const userUnits = user ? user.units : Units.METRIC;

  let valueToShow = value;
  let unitToShow = '';

  if (userUnits === Units.IMPERIAL) {
    valueToShow = round ? getFlooredFloat(value * 0.000621, digitsAfterPoint) : value * 0.000621;
  } else if (userUnits === Units.METRIC) {
    valueToShow = round ? getFlooredFloat(value / 1000, digitsAfterPoint) : value / 1000;
  }

  if (showUnit) {
    unitToShow = unitName[userUnits as Units];
  }

  return (
    <>
      {getDistance(valueToShow, '.')}
      <span className="dashboard-line-distance">{unitToShow}</span>
    </>
  );
};
