import { Position } from '@turf/turf';
import { parse } from 'wkt';

export const getCenterOfPolygon = (arr: number[][]): [number, number] => {
  let minX, maxX, minY, maxY;
  for (let i = 0; i < arr.length; i++) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    minX = arr[i][0] < minX || minX == null ? arr[i][0] : minX;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    maxX = arr[i][0] > maxX || maxX == null ? arr[i][0] : maxX;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    minY = arr[i][1] < minY || minY == null ? arr[i][1] : minY;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    maxY = arr[i][1] > maxY || maxY == null ? arr[i][1] : maxY;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return [(minX + maxX) / 2, (minY + maxY) / 2];
};

export function getCoordinatesFromLineGeometry(geometry: string): number[][] {
  const arr = [];
  const coordsString = geometry.slice(11, geometry.length - 1);
  const dividedCoords = coordsString.split(',');
  arr.push(...dividedCoords.map((item: string) => item.split(' ').map((item: string) => parseFloat(item))));
  return arr;
}

export const getCustomPolygonWKTString = (data: Position[][]): string => {
  let res = 'POLYGON((';
  const dataLength = data[0].length;

  data[0].forEach((item, index) => {
    res += `${item[0]} ${item[1]}`;

    if (index + 1 < dataLength) {
      res += ',';
    }
  });

  res += '))';

  return res;
};

export function getCoordinatesFromGripDataGeometry(geometry: string): number[][] {
  const arr = [];
  const coordsString = geometry.slice(11, geometry.length - 1);
  const dividedCoords = coordsString.split(',');
  arr.push(...dividedCoords.map((item: string) => item.split(' ').map((item: string) => parseFloat(item))));
  return arr;
}

export function getCoordinatesFromPolygonGeometry(geometry: string): number[][] {
  const parsed = parse(geometry);
  return parsed.coordinates[0];
}
