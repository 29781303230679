import { LayerConfig } from './index';
import { makeUrl } from '../../helpers/common';
import { API_TILES } from '../../constants/api';

export const DEFAULT_WW_MAX_ZOOM = 18;
export const DEFAULT_WW_MIN_ZOOM = 1;
export const FILL_EXTRUSION_STYLE_WW_MAX_ZOOM = 8;
export const LINE_STYLE_WW_MIN_ZOOM = 8;

export const coverage: LayerConfig = {
  layerName: 'coverage',
  isLiveLayer: false,
  mapSource: {
    type: 'tiles',
    source: () => ({
      type: 'vector',
      tiles: [makeUrl(API_TILES, { layer: 'coverage' })],
      minzoom: DEFAULT_WW_MIN_ZOOM,
      maxzoom: DEFAULT_WW_MAX_ZOOM,
    }),
  },
  mapLayers: [
    {
      id: 'coverage-fill-extrusion',
      type: 'fill',
      minzoom: DEFAULT_WW_MIN_ZOOM,
      maxzoom: FILL_EXTRUSION_STYLE_WW_MAX_ZOOM,
      source: 'coverage',
      'source-layer': 'samples-availability',
      layout: {
        visibility: 'visible',
      },
      paint: {
        'fill-color': [
          'let',
          'total',
          ['round', ['get', 'summary.total']],
          ['case', ['>', ['var', 'total'], 15], '#4169E1', ['>', ['var', 'total'], 5], '#87CEEB', '#ADD8E6'],
        ],
        'fill-outline-color': 'rgba(0,0,0,0)',
        'fill-opacity': 0.75,
      },
    },
    {
      id: 'coverage-line',
      type: 'line',
      minzoom: LINE_STYLE_WW_MIN_ZOOM,
      maxzoom: DEFAULT_WW_MAX_ZOOM,
      source: 'coverage',
      'source-layer': 'samples-availability',
      layout: {
        visibility: 'visible',
      },
      paint: {
        'line-color': [
          'let',
          'total',
          ['round', ['get', 'summary.total']],
          ['case', ['>', ['var', 'total'], 15], '#4169E1', ['>', ['var', 'total'], 5], '#87CEEB', '#ADD8E6'],
        ],
      },
    },
  ],
  symbology: {
    title: 'Coverage',
    iconClass: 'icon-wheel',
  },
};
