import React, { useCallback, useEffect, useRef, useState } from 'react';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mapboxgl from 'mapbox-gl';
import { IMapService } from '../../../MapService';
import { SearchingArea } from '../../../types';
import { getSearchingArea } from '../../../../../../helpers/common';
import { SEARCH_RESULTS_LIMIT } from '../../../../../../constants/map';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import './_search.scss';
import { Tooltip, TooltipSide } from 'shared';
import { useAppSelector } from '../../../../app/hooks';
import { selectIsGlobalUser, selectPolygons } from '../../../../user/store/store';
import { getCoordinatesFromPolygonGeometry } from '../../../helpers';

interface Props {
  disable: boolean;
  mapService: IMapService;
}

export const MapSearch: React.FC<Props> = (props: Props) => {
  const { disable, mapService } = props;

  const [isActive, setActive] = useState(false);
  const [mapboxGeocoder, setMapboxGeocoder] = useState<MapboxGeocoder>();
  const wrapper = useRef<HTMLDivElement>(null);
  const geocoderRef = useRef<HTMLInputElement>(null);
  const button = useRef<HTMLButtonElement>(null);
  const map = mapService.getMap();

  const isGlobalUser = useAppSelector(selectIsGlobalUser);
  const polygons = useAppSelector(selectPolygons);

  const handleClick = useCallback(() => setActive(!isActive), [isActive]);
  const clickHandler = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      const { target } = e;
      if (
        target instanceof Element &&
        !button.current?.contains(target) &&
        (!geocoderRef.current?.contains(target) || geocoderRef.current?.querySelector('ul')?.contains(target))
      ) {
        handleClick();
      }
    },
    [isActive],
  );

  useEffect(() => {
    mapboxGeocoder?.setInput('');

    if (isActive) {
      const inputNode = geocoderRef.current?.querySelector('input');

      if (inputNode) {
        inputNode.focus();
      }

      document.addEventListener('mouseup', clickHandler);
      return () => document.removeEventListener('mouseup', clickHandler);
    }
  }, [isActive]);

  useEffect(() => {
    if (!geocoderRef.current || !map) {
      return;
    }

    if (mapboxGeocoder) {
      mapboxGeocoder.onRemove();
    }

    let mapboxGeocoderOptions: MapboxGeocoder.GeocoderOptions = {
      accessToken: mapboxgl.accessToken,
      mapboxgl: map,
    };

    if (isGlobalUser) {
      mapboxGeocoderOptions = {
        ...mapboxGeocoderOptions,
        types: 'country, region, place, district',
      };
    }

    const geocoder: MapboxGeocoder = new MapboxGeocoder(mapboxGeocoderOptions);

    const polygonCoordinates: number[][] = [];
    polygons.forEach((polygon) => {
      polygonCoordinates.push(...getCoordinatesFromPolygonGeometry(polygon.polygon));
    });
    const { minCoordinates, maxCoordinates }: SearchingArea = getSearchingArea(polygonCoordinates);

    setMapboxGeocoder(geocoder);
    geocoder.setLimit(SEARCH_RESULTS_LIMIT);
    geocoder.setBbox([minCoordinates[0], minCoordinates[1], maxCoordinates[0], maxCoordinates[1]]);
    geocoder.addTo('#geocoder');
    geocoder.on('result', ({ result }: { result: MapboxGeocoder.Result }) => {
      map.setCenter([result.center[0], result.center[1]]);
      geocoder.clear(new Event(''));

      mapService.showPin('search-pin', result.center, 30);
    });
  }, [geocoderRef, map]);

  return (
    <div className="controls-group search" ref={wrapper}>
      <Tooltip text="Search" side={TooltipSide.RIGHT}>
        <button
          ref={button}
          className={`controls-btn ${isActive ? 'active' : ''}`}
          onClick={handleClick}
          data-testid="button"
          {...(disable ? { disabled: true } : '')}
        >
          <i className="controls-icon icon icon-search" />
        </button>
      </Tooltip>
      <div className={`controls-container ${isActive ? 'isVisible' : 'isHidden'}`} data-testid="controls-container">
        <div id="geocoder" ref={geocoderRef} className="search-container" />
      </div>
    </div>
  );
};
