import { AppType } from '../selection/types';
import { Data } from '../layers/types';

export enum FilterType {
  BUTTONS = 'buttons',
  RANGE = 'range',
  HIDDEN = 'hidden',
  GEOMETRY = 'geometry',
}

export interface Filter {
  state: FilterOption;
  supportedLayers?: string[];
  extra?: boolean;
  title?: string;
  filterName?: string;
  filterType: FilterType;
  filterableValue?: string;
  optionValues?: Record<string, number[]>;
  function?: FilterFunction;
  isHidden?: boolean;
}

export type StorableFilters = Record<string, FilterOption>;

export interface SelectedFilters {
  [filterName: string]: FilterOption;
}

export type FilterOption = Record<string, boolean | number>;

export interface UIFilterOption {
  title: string;
  name: string;
  isSelected: boolean;
}

export type FilterObject = Record<string, Filter>;

export type AvailableFilter = Record<AppType, FilterObject>;

export type FilterFunction = (filter: FilterOption, grip: Data[], lowValue: number, highValue: number) => Data[];

export type CustomPick<T, K extends keyof T> = {
  [Key in K]: T[Key];
};
