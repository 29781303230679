import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AuthenticatedRoute from '../../../auth/components/AuthenticatedRoute';
import LoginPage from '../../../auth/components/LoginPage';
import MapPage from '../../../map/components/MapPage';

export const LOGIN_PAGE_PATH = '/login';
export const MAP_PAGE_PATH = '/';

const Routing: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route exact path={LOGIN_PAGE_PATH} component={LoginPage} />
        <AuthenticatedRoute exact path={MAP_PAGE_PATH} component={MapPage} />
      </Switch>
    </Router>
  );
};

export default Routing;
