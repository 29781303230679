import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { selectAuthState } from '../../store/store';
import { LOGIN_PAGE_PATH } from '../../../app/components/Routing';

const AuthenticatedRoute: React.FC<RouteProps> = (props: RouteProps) => {
  const userState = useAppSelector(selectAuthState);

  return userState === 'LOGGED_IN' ? <Route {...props} /> : <Redirect to={LOGIN_PAGE_PATH} />;
};

export default AuthenticatedRoute;
