import { saveAs } from 'file-saver';
import { REACT_APP_API_GW_ROUTE } from '../../../../constants/api';
import { request } from '../../../../helpers/request';
import { APIFileResponse, AppType, FetchReportsParams, FetchReportsResponse, Report } from '../types';
import { StorableFilters } from '../../filter/types';
import { convertBase64ToFile } from '../../../../helpers/common';
import { DataType } from '../../../../classes/AbstractData';
import { filterSelectionsToAppType } from '../../../../constants/filterSelectionsToAppType';

export const API_REPORTS_SERVICE_URL = `${REACT_APP_API_GW_ROUTE}/reports`;
export const API_EXPORT_SERVICE_URL = `${REACT_APP_API_GW_ROUTE}/export`;

export const API_EXPORT_REPORT_AS_CSV_URL = `${API_EXPORT_SERVICE_URL}/report`;
export const API_EXPORT_DATA_AS_CSV_URL = `${API_EXPORT_SERVICE_URL}`;

export async function fetchReports(params: FetchReportsParams): Promise<Report[]> {
  const res = await request.get<FetchReportsResponse>(`${API_REPORTS_SERVICE_URL}/${params.layer}/${params.polygonId}`);

  if (res.data.status !== 'SUCCESS') {
    throw new Error('Unable to fetch reports');
  }

  return res.data.result;
}

export async function exportReportAsCsv(
  accountName: string,
  appType: AppType,
  polygonId: number,
  polygonTitle: string,
  reportId: string,
  filters: StorableFilters,
): Promise<void> {
  const postData = transformFiltersToPostData(appType, filters);
  const url = `${API_EXPORT_REPORT_AS_CSV_URL}/${appType}/${polygonId}/${reportId}/?account=${accountName}&polygon=${polygonTitle}`;

  const data = await request.post<APIFileResponse>(url, postData);

  if (data.data.status !== 'SUCCESS') {
    throw new Error('Error while downloading file');
  }

  const file = convertBase64ToFile(`data:text/csv;base64,${data.data.file}`, data.data.file_name);
  saveAs(file, data.data.file_name);
}

export async function exportDataAsCsv(
  accountName: string,
  appType: AppType,
  polygonId: number,
  polygonTitle: string,
  layer: DataType,
  filters: StorableFilters,
): Promise<void> {
  const postData = transformFiltersToPostData(appType, filters);
  const url = `${API_EXPORT_DATA_AS_CSV_URL}/${appType}/${layer}/${polygonId}/?account=${accountName}&polygon=${polygonTitle}`;

  const data = await request.post<APIFileResponse>(url, postData);

  if (data.data.status !== 'SUCCESS') {
    throw new Error('Error while downloading file');
  }

  const file = convertBase64ToFile(`data:text/csv;base64,${data.data.file}`, data.data.file_name);
  saveAs(file, data.data.file_name);
}

function transformFiltersToPostData(appType: AppType, filters: StorableFilters): string {
  const filter: Record<string, number[][]> = {};

  Object.entries(filters).forEach(([name, options]) => {
    const values: number[][] = [];

    Object.entries(options).forEach(([title, value]) => {
      if (value) {
        const filterValues = filterSelectionsToAppType[appType][name].optionValues;
        if (filterValues) {
          values.push(filterValues[title]);
        }
      }
    });

    if (values.length) {
      const valueName = filterSelectionsToAppType[appType][name].filterName;

      if (valueName) filter[valueName] = values;
    }
  });

  return JSON.stringify(filter);
}
