import React, { useEffect, MouseEvent } from 'react';
import { ControlExpander, Filter } from 'shared';
import { intersection } from 'lodash';
import { useAllowedLayers } from '../../hooks/useAllowedLayers';
import { LayerConfig, layers, layersToAppType } from '../../../../../configs/layers';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  resetLayersState,
  selectSelectedAppType,
  selectSelectedLayer,
  selectSelectedSubLayer,
  setSelectedLayer,
  setSelectedSubLayer,
} from '../../store/store';
import './styles.scss';
import { LayerName } from '../../../user/types';

const LayerSelector: React.FC = () => {
  const selectedAppType = useAppSelector(selectSelectedAppType);
  const selectedLayer = useAppSelector(selectSelectedLayer);
  const selectedSubLayer = useAppSelector(selectSelectedSubLayer);

  const { allowedLayers } = useAllowedLayers();

  const dispatch = useAppDispatch();

  const selectLayer = (layerName: string) => {
    dispatch(setSelectedLayer(layerName as LayerName));
  };

  const selectSublayer = (subLayerName: string) => {
    dispatch(setSelectedSubLayer(subLayerName));
  };

  const onResetHandler = (e: MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(resetLayersState());
  };

  // Auto select of first layer during start up
  useEffect(() => {
    if (!selectedAppType || selectedLayer) {
      return;
    }

    const firstAppTypeLayer = intersection(layersToAppType[selectedAppType], allowedLayers)[0];

    selectLayer(firstAppTypeLayer);
  }, [selectedAppType]);

  useEffect(() => {
    if (!selectedLayer || selectedSubLayer) {
      return;
    }

    const layerConfig = layers[selectedLayer];

    if (!layerConfig) return;

    if (layerConfig.subLayers && layerConfig.subLayers.length) {
      selectSublayer(layerConfig.subLayers[0].layerName);
    }
  }, [selectedLayer, selectedSubLayer]);

  return (
    <ControlExpander iconClass="icon-layers">
      {allowedLayers.map((layer) => {
        const layerConfig = layers[layer] as LayerConfig;

        const isActive = selectedLayer === layerConfig.layerName;
        const subLayers: {
          name: string;
          value: string;
          selected?: boolean;
          disabled?: boolean;
        }[] = [];

        if (layerConfig.subLayers) {
          layerConfig.subLayers.forEach((subLayer) => {
            subLayers.push({
              name: subLayer.symbology.title,
              value: subLayer.layerName,
              selected: subLayer.layerName === selectedSubLayer,
              disabled: !isActive,
            });
          });
        }

        return (
          <Filter
            key={layerConfig.layerName}
            name={layerConfig.layerName}
            title={layerConfig.symbology.title}
            value={layerConfig.layerName}
            isActive={isActive}
            options={subLayers}
            onToggle={selectLayer}
            onSelectOption={selectSublayer}
          />
        );
      })}
      <div className="controls-footer">
        {/*<button className="controls-footer-btn" disabled>*/}
        {/*  Save preset*/}
        {/*</button>*/}
        <button className="controls-footer-btn" onClick={onResetHandler}>
          Reset
        </button>
      </div>
    </ControlExpander>
  );
};

export default React.memo(LayerSelector);
