import React from 'react';
import { MapFilter } from '../../../filter/components/MapFilter';
import { MapSearch } from './MapSearch';
import { IMapService } from '../../MapService';
import IconButton from '../../../../components/IconButton';
import Layers from '../../../selection/components/LayerSelector';
import { CoordinatesChecker } from './CoordinatesChecker';
import { Tooltip, TooltipSide } from 'shared';
import { useAppSelector } from '../../../app/hooks';
import { selectIsGlobalUser, selectPolygons } from '../../../user/store/store';
import { getCoordinatesFromPolygonGeometry } from '../../helpers';
import { GLOBAL_USER_INITIAL_COORDS } from '../../constants';

interface Props {
  mapService: IMapService;
}

export const GlobalControls: React.FC<Props> = (props: Props) => {
  const { mapService } = props;

  const isLocal = process.env.REACT_APP_ENV !== 'staging' && process.env.REACT_APP_ENV !== 'production';

  const polygons = useAppSelector(selectPolygons);
  const isGlobalUser = useAppSelector(selectIsGlobalUser);

  const resetMap = (): void => {
    const coords = isGlobalUser
      ? [[GLOBAL_USER_INITIAL_COORDS.lng, GLOBAL_USER_INITIAL_COORDS.lat]]
      : getCoordinatesFromPolygonGeometry(polygons[0].polygon);
    mapService.restoreMapDefaults(coords);
  };

  return (
    <div className="controls-btn-container global-controls">
      <Tooltip text="Re-Center" side={TooltipSide.RIGHT}>
        <IconButton
          buttonClassList="controls-btn controls-group"
          iconClassList="controls-icon icon icon-home"
          testId="map-reset"
          onClickEvent={resetMap}
        />
      </Tooltip>
      <MapSearch disable={false} mapService={mapService} />
      <Tooltip text="Filter" side={TooltipSide.RIGHT}>
        <MapFilter disabled={false} />
      </Tooltip>
      <Tooltip text="Layers" side={TooltipSide.RIGHT}>
        <Layers />
      </Tooltip>
      {isLocal && (
        <Tooltip text="Coordinate check" side={TooltipSide.RIGHT}>
          <CoordinatesChecker mapService={mapService} />
        </Tooltip>
      )}
    </div>
  );
};
